import createModule from '@/store/createModule'
import featuresApi from '@/api/features'

export default (store) => {
  createModule(
    store,
    'feature',
    {
      flags: {},
    },
    {
      getters: ({ get }) => ({
        isRushShippingEnabled() {
          return get('flags')['rush-shipping'] ?? false
        },
      }),

      mutations: ({ markAsLoaded }) => ({
        flags(state, flags) {
          state.flags = flags
          markAsLoaded(state)
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async flagsAsync({ dispatch }) {
          await dispatch('ensureFresh')
          return get('flags')
        },
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const flags = await featuresApi.getFlags()
            set('flags', flags)
          } finally {
            set('stopLoading')
          }
        },
        async observeFlag(_context, key) {
          // back-end answer is irrelevant
          featuresApi.observeFlag(key)
        },
        async track(_context, { event, data }) {
          return featuresApi.track({
            event,
            data,
          })
        },
      }),
    }
  )
}
