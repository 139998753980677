/**
 * Formats date to month, date, year (e.g., January 12, 2020).
 */

export default (date) => {
  if (!date) {
    return ''
  }
  const newDate = new Date(date)
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(newDate)
}
