export default (store, products) => {
  if (!store.get('account/loggedIn')) {
    // Don't filter any products when the user is not logged in.
    return products
  }

  const restrictedRegions = store.get('account/restrictedRegions')

  return products.filter((product) => {
    const regionIntersection = product.skipRegions.filter((region) =>
      restrictedRegions.includes(region)
    )

    // If both userRestrictedRegions and a products skipRegions arrays contain
    // the same value, then this product is restricted for the current user.
    return regionIntersection.length === 0
  })
}
