import clone from 'clone-deep'
import algoliasearch from 'algoliasearch/lite'
import createModule from '@/store/createModule'
import storeApi from '@/api/store'
import filterProducts from '@/helpers/filterProducts'
import keyBy from '@/helpers/keyBy'
import env from '@/helpers/env'

export default (store) => {
  createModule(
    store,
    'essentials',
    {
      tags: [],
      categories: [],
      products: [],
      favorites: [],
      sortedProducts: null,
      query: '',
      searchProducts: [],
      sortOptions: [
        {
          label: 'Bestsellers',
          value: 'default',
        },
        {
          label: 'Lowest Price',
          value: 'price ascending',
        },
        {
          label: 'Highest Price',
          value: 'price descending',
        },
        {
          label: 'Product Name (A-Z)',
          value: 'title ascending',
        },
        {
          label: 'Product Name (Z-A)',
          value: 'title descending',
        },
        {
          label: 'Brand (A-Z)',
          value: 'brand ascending',
        },
        {
          label: 'Brand (Z-A)',
          value: 'brand descending',
        },
      ],
      discoverProductSkus: [
        'CB002788-AUF-SU21',
        'CB000208-FCH-SU20',
        'CB000201-FCH-FA20',
        'CB001684-FOR-SP21',
        'CB001661-CGD-SP21',
        'CB001661-CGD-SP21',
        'CB002810-HBR-SU21',
        'CB002811-HBR-SU21',
        'CB003082-OBC-SU21',
        'CB002798-GEO-SU21',
        'CB002816-JMO-SU21',
        'CB002817-JMO-SU21',
        'CB002791-CEL-SU21',
        'CB002864-WGC-SU21',
        'CB002938-BAM-SU21',
        'CB000769-WYS-FA19',
        'CB001673-DAV-SP21',
        'CB002959-NEZ-SU21',
        'CB000433-NAT-FA20',
        'CB002979-PRT-SU21',
        'CB002980-PRT-SU21',
        'CB002993-MIH-SU21',
        'CB002994-MIH-SU21',
        'CB002854-BEL-SU21',
        'CB002966-UPB-SU21',
        'CB003003-DRB-SU21',
      ],
    },
    {
      getters: ({ get, set }) => ({
        productsById() {
          return keyBy(get('products'), ({ id }) => id)
        },
        variantsById() {
          const variants = get('products').reduce((variants, product) => {
            variants.push(...product.variants)
            return variants
          }, [])
          return keyBy(variants, ({ id }) => id)
        },
        productsByVariantId() {
          return get('products').reduce((productsByVariantId, product) => {
            product.variants.forEach((variant) => {
              productsByVariantId[variant.id] = product
            })
            return productsByVariantId
          }, {})
        },
        discoverProducts() {
          return get('discoverProductSkus').reduce((discoverProducts, sku) => {
            const matchedProduct = get('products').find(
              (product) => product.defaultVariant.sku === sku && product.defaultVariant.stock > 0
            )
            if (matchedProduct) {
              discoverProducts.push(matchedProduct)
            }
            return discoverProducts
          }, [])
        },
        firstTenProducts() {
          return get('products')
            .filter(({ variants }) => variants[0].stock > 0)
            .slice(0, 10)
        },
        // PLP is either L1 or L2 (omitting L3 for now)
        plpIsL2() {
          return Boolean(store.get('route')?.params.categorySlugL2)
        },
        plpIsL1() {
          return Boolean(
            !store.get('route')?.params.categorySlugL2 && store.get('route')?.params.categorySlugL1
          )
        },
        l1Category() {
          return get('categories')?.find(
            ({ slug }) => slug === store.get('route')?.params.categorySlugL1
          )
        },
        l1CategorySlug() {
          return get('l1Category')?.slug
        },
        l1CategoryTitle() {
          return get('l1Category')?.title
        },
        // computed category (can be l1 or l2 category depending on which view you're in)
        currentCategory() {
          if (get('plpIsL2')) {
            return get('l1Category')?.children.find(
              ({ slug }) => slug === store.get('route')?.params.categorySlugL2
            )
          }
          return get('l1Category')
        },
        currentCategorySlug() {
          return get('currentCategory')?.slug
        },
        currentCategoryTitle() {
          if (store.get('route').name === 'EssentialsHome') {
            return 'Discover'
          } else {
            return get('currentCategory')?.title
          }
        },
        filteredProducts() {
          return get('products').filter((product) =>
            product.categories.includes(get('currentCategorySlug'))
          )
        },
        sortProducts() {
          return (value) => {
            if (value === 'default') {
              set('setSortedProducts', get('filteredProducts'))
              return
            }
            const isAscending = value.includes('ascending')
            const property = isAscending
              ? value.substring(0, value.length - 10)
              : value.substring(0, value.length - 11)
            const sortedProducts = [...get('filteredProducts')].sort((a, b) => {
              if (property === 'price') {
                if (isAscending) {
                  return a.defaultVariant[property] - b.defaultVariant[property]
                }
                return b.defaultVariant[property] - a.defaultVariant[property]
              } else {
                const fa = isAscending ? a[property].toLowerCase() : b[property].toLowerCase()
                const fb = isAscending ? b[property].toLowerCase() : a[property].toLowerCase()

                if (fa < fb) {
                  return -1
                }
                if (fa > fb) {
                  return 1
                }
              }
              return 0
            })
            set('setSortedProducts', sortedProducts)
          }
        },
      }),

      mutations: ({ get, markAsLoaded }) => ({
        init(state, response) {
          state.tags = response.tags
          state.categories = response.categories
          state.brands = response.brands
          markAsLoaded(state)
        },
        products(state, products) {
          state.products = products.map((product) => {
            if (product.defaultVariant.stock <= 0) {
              product.tags.push('sold-out')
            } else if (product.defaultVariant.stock < 175) {
              product.tags.push('almost-out')
            }
            return product
          })
          markAsLoaded(state)
        },
        updateProducts(_state, products) {
          const stateProductsById = get('productsById')
          products.forEach((product) => {
            const stateProduct = stateProductsById[product.id]
            if (!stateProduct) {
              return
            }
            for (const key of Object.keys(product)) {
              stateProduct[key] = clone(product[key])
            }
          })
        },
        setSortedProducts(state, products) {
          state.sortedProducts = products
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const response = await storeApi.init('essentials')
            set('init', { ...response, products: filterProducts(store, response.products) })
            const products = await storeApi.products('essentials')
            set('products', filterProducts(store, products.data))
          } finally {
            set('stopLoading')
          }
        },
        async refreshInventory(_context, { productIds }) {
          const response = await storeApi.inventory('essentials', { productIds })
          const products = filterProducts(store, response)
          set('updateProducts', products)
        },
        async search(_context, { query, sort }) {
          if (!store.get('feature/flags')['essentials-search']) {
            return null
          }

          if (get('loading')) {
            await waitForValue(() => get('loading'), false)
          }

          const client = algoliasearch(env.get('ALGOLIA_APP_ID'), env.get('ALGOLIA_SEARCH_KEY'))
          const index = client.initIndex(env.get('ALGOLIA_INDEX'))

          set('searchProducts', [])
          set('query', query)

          // TODO: this is where I split sort and add that to the api call
          // console.log(`query ${query}`)
          // console.log(`sort ${sort}`)

          index
            .search(query, {
              attributesToRetrieve: ['objectID'],
              filters: 'salesChannels:essentials',
              // hitsPerPage: 50,
            })
            .then(({ hits }) => {
              // this store is the source of truth, ignore everything else algolia returns
              const productsById = get('productsById')
              // array of { objectID } objects, where objectID is a string
              const filteredHits = hits.reduce((finalResults, { objectID }) => {
                if (productsById[parseInt(objectID)]) {
                  finalResults.push(productsById[parseInt(objectID)])
                }
                return finalResults
              }, [])
              set('searchProducts', filteredHits)
            })
        },
      }),
    }
  )
}
