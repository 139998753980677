// TODO: refactor with lowdash, https://lodash.com/docs/4.17.15#debounce

export default (originalFunction, delay) => {
  let timeout = null

  const clear = () => clearTimeout(timeout)
  const execute = (...args) => {
    clear()
    return originalFunction(...args)
  }

  const debounceFunction = async (...args) => {
    clear()
    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(execute(...args)), delay)
    })
  }

  debounceFunction.clear = clear
  debounceFunction.execute = execute

  return debounceFunction
}
