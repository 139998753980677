import clone from 'clone-deep'
import createModule from '@/store/createModule'
import { ApiError } from '@/api'
import accountApi from '@/api/account'
import billingInfoApi from '@/api/billingInfo'
import addressesApi from '@/api/addresses'
import subscriptionsApi from '@/api/subscriptions'
import keyBy from '@/helpers/keyBy'
import filterProducts from '@/helpers/filterProducts'

export const Survey = Object.freeze({
  FORM_TIER_1: 10,
  FORM_TIER_2: 11,
  UNSUBSCRIBE_ME: 20,
  UNSUBSCRIBE_OFFER: 21,
  UNSUBSCRIBE_OFFER_CONFIRMED: 22,
  CONFIRM: 30,
  CONFIRMED: 40,
  NEVERMIND: 50,
})

export default (store) => {
  createModule(
    store,
    'account',
    {
      id: null,
      username: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      addresses: [],
      restrictedRegions: [],
      avatar: null,
      billingInfo: null,
      favorites: [],
      baseShipping: null,
      minTotalForFreeShipping: null,
      isActiveSubscriber: null,
      hasMarketAccess: null,
      isAdmin: null,
      dateCreated: null,
      rememberedCredentials: {
        username: null,
        password: null,
      },
      accountCredit: null,
      subscriptions: null,
      orders: null,
      shipments: null,
      achievements: null,
      loadingProfile: false,
      profileLoaded: false,
      redemptions: null,
      loadingRedemptions: false,
      redemptionsLoaded: false,
      recentlyViewedProducts: [],
    },
    {
      getters: ({ get }) => ({
        loggedIn() {
          if (!get('loaded')) {
            return null
          }
          return get('username') !== null
        },
        fullName() {
          if (!get('loggedIn')) {
            return null
          }
          return [get('firstName'), get('lastName')].filter(Boolean).join(' ')
        },
        addressesById() {
          return keyBy(get('addresses'), ({ id }) => id)
        },
        getAvatarUrl() {
          return (filename) => `/static/avatars/${filename}`
        },
        avatarUrl() {
          return get('getAvatarUrl', get('avatar') ?? 'Avatar_01.png')
        },
        formattedCreatedDate() {
          const date = new Date(get('dateCreated'))
          return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }).format(date)
        },
        findSubscriptionById() {
          return (selectedSubscriptionId) =>
            get('subscriptions')?.find((subscription) => subscription.id === selectedSubscriptionId)
        },
        activeSubscriptions() {
          // https://docs.recurly.com/docs/subscriptions
          // "canceled" / isCanceled means the subscription WILL expire at the term renewal date, i.e., canceled auto-renew
          // "expired" / isExpired subscriptions are gone forever, i.e., cannot be reactivated because canceled or unpaid
          return (get('subscriptions') ?? []).filter(({ isExpired }) => !isExpired)
        },
        activeStatusSubscriptions() {
          return (get('subscriptions') ?? []).filter(({ status }) => status === 'active')
        },
        hasOnlyExpiredSubscriptions() {
          return get('activeSubscriptions').length <= 0
        },
        canceledSubscriptions() {
          return (get('activeSubscriptions') ?? []).filter(({ isCanceled }) => isCanceled)
        },
        allActiveSubsAreCanceled() {
          return (get('activeSubscriptions') ?? []).every(({ isCanceled }) => isCanceled)
        },
        isAnnualSubscriber() {
          // a single annual subscription makes you an annual subscriber
          return (
            get('activeSubscriptions').length &&
            get('activeSubscriptions').some(({ type }) => type === 'annually')
          )
        },
        isQuarterlySubscriber() {
          // only when every one of your subscriptions is quarterly are you considered a quarterly subscriber
          return (
            get('activeSubscriptions').length &&
            get('activeSubscriptions').every(({ type }) => type === 'quarterly')
          )
        },
        isAlltruePlusSubscriber() {
          // only one Alltrue+ subscription allowed per account
          return get('activeSubscriptions')?.some((subscription) => subscription?.isAlltruePlus)
        },
        isSubscriptionWaitlist() {
          const activeSubscriptions = get('activeSubscriptions')
          if (!activeSubscriptions.length) {
            return () => false
          }

          const latestSubscription = activeSubscriptions[0]
          return (subscription = latestSubscription) => {
            const isBoxWaitlist = store
              .get('customize/findBoxBySubscription', subscription)
              ?.title?.toLowerCase()
              .includes('waitlist')
            const isSubscriptionQuarterly = subscription.type === 'quarterly'
            return activeSubscriptions.length === 1 && isBoxWaitlist && isSubscriptionQuarterly
          }
        },
        isSubscriptionOffWaitlist() {
          return (
            get('activeSubscriptions').length === 1 &&
            store
              .get('customize/findBoxBySubscription', get('activeSubscriptions')[0])
              ?.title?.toLowerCase()
              .includes('off list')
          )
        },
        isSubscriptionCanceledWaitlist() {
          return (
            get('isSubscriptionWaitlist') && get('activeSubscriptions')[0]?.status === 'canceled'
          )
        },
        activeSubsWithoutMagazine() {
          return get('activeSubscriptions')?.filter(({ addOns }) => !addOns.includes('magazine'))
        },
        allActiveSubsHaveMagazine() {
          return get('activeSubscriptions')?.every(({ addOns }) => addOns.includes('magazine'))
        },
        // whether subscription has at least 1 bonus they can still add to their account
        subscriptionCanAddBonus() {
          return (subscriptionId) => {
            const subscription = get('activeSubscriptions')?.find(
              (subscription) => subscription.id === subscriptionId
            )
            return (
              !subscription?.addOns.includes('magazine') || // can add bonus (true) if addOn is not in subscription
              !subscription?.addOns.includes('eco-upgrade')
            )
          }
        },
        defaultAddressId() {
          const addresses = get('addresses') ?? []
          const address = addresses.find((address) => address.isShippingAddress) ?? addresses[0]
          return address?.id ?? null
        },
        findAddressIdBySubscriptionId() {
          return (subscriptionId) => {
            return get('subscriptions')?.find(({ id }) => id === subscriptionId)?.address?.id
          }
        },
        findShipmentByOrderId() {
          return (orderId) => {
            return get('shipments')?.find(({ orderId: id }) => id === parseInt(orderId))
          }
        },
        getFetchRequestOptions() {
          return (params) => {
            const myHeaders = new Headers()
            myHeaders.append('x-api-key', 'live_pk_r1f57W7kcfGP5veG1osvbt')

            const url = new URL('https://api.postgrid.com/v1/addver/completions')
            url.search = new URLSearchParams(params).toString()

            return { header: myHeaders, url }
          }
        },
        latestShipmentBoxId() {
          let highestBoxId = 0
          const shipments = get('shipments')
          if (shipments) {
            for (const shipment of shipments) {
              if (shipment.boxId > highestBoxId) {
                highestBoxId = shipment.boxId
              }
            }
          }
          return highestBoxId
        },
      }),

      mutations: ({ defaultState, markAsLoaded }) => ({
        accountInfo(state, accountInfo) {
          const keys = [
            'addresses',
            'restrictedRegions',
            'avatar',
            'baseShipping',
            'billingInfo',
            'dateCreated',
            'favorites',
            'firstName',
            'id',
            'isActiveSubscriber',
            'hasMarketAccess',
            'isAdmin',
            'lastName',
            'minTotalForFreeShipping',
            'phoneNumber',
            'username',
          ]
          for (const key of keys) {
            state[key] = accountInfo ? accountInfo[key] : clone(defaultState[key])

            // selectedAddressId is overwritted to the first address in the list
            // setting it only if it's null preserves the wrong state, so cypress tests fail
            if (key === 'addresses' && accountInfo && accountInfo[key].length) {
              state.selectedAddressId = accountInfo[key][0].id
            }
          }
          markAsLoaded(state)
        },
        profile(state, profile) {
          const keys = ['accountCredit', 'achievements', 'orders', 'shipments', 'subscriptions']

          for (const key of keys) {
            state[key] = profile ? profile[key] : clone(defaultState[key])
          }
        },
        redemptions(state, redemptions) {
          state.redemptions = redemptions ?? clone(defaultState.redemptions)
        },
        rememberedCredentials(state, rememberedCredentials) {
          state.rememberedCredentials =
            rememberedCredentials === null
              ? clone(defaultState.rememberedCredentials)
              : rememberedCredentials
        },
        subscriptionAddress(state, updatedAddress) {
          state.subscriptions.forEach((subscription) => {
            if (subscription.address?.id === updatedAddress.id) {
              subscription.address = updatedAddress
            }
          })
        },
        addRecentlyViewedProduct(state, product) {
          // only push if it's not already in array + in stock
          if (
            product.defaultVariant.stock > 0 &&
            !state.recentlyViewedProducts.some(({ id }) => id === product.id)
          ) {
            state.recentlyViewedProducts.unshift(product)
          }
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async logout() {
          const response = await accountApi.logout()
          if (response.success) {
            // reset all Vuex stores
            for (const vuexStore in store.state) {
              if (vuexStore !== 'route') {
                await store.set(`${vuexStore}/reset`)
              }
            }
          }
        },
        async loggedInAsync({ dispatch }) {
          await dispatch('ensureFresh')
          return get('loggedIn')
        },
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const accountInfo = await accountApi.fetchAccountInfo()
            set('accountInfo', accountInfo)

            // Filter products based on user's restricted regions
            const products = store.get('products/products')
            if (products.length) {
              store.set('products/products', filterProducts(store, products))
            }
          } catch (error) {
            if (error instanceof ApiError && error.statusCode === 401) {
              set('accountInfo', null)
              return
            }
            return Promise.reject(error)
          } finally {
            set('stopLoading')
          }
        },
        // TODO: Move this to its own module, or find a way for a module to have multiple sets of "data fetching" properties (`ensureFresh`, `load`, `stale`, etc.)
        async fetchProfile() {
          set('loadingProfile', true)
          try {
            const profile = await accountApi.profile()
            set('profile', profile)
            set('profileLoaded', true)
          } catch (error) {
            if (error instanceof ApiError) {
              set('profile', null)
              set('profileLoaded', false)
              return
            }
            return Promise.reject(error)
          } finally {
            set('loadingProfile', false)
          }
        },
        async fetchRedemptions() {
          set('loadingRedemptions', true)
          try {
            const redemptions = await accountApi.redemptions()
            set('redemptions', redemptions)
            set('redemptionsLoaded', true)
          } catch (error) {
            if (error instanceof ApiError) {
              set('redemptions', null)
              set('redemptionsLoaded', false)
              return
            }
            return Promise.reject(error)
          } finally {
            set('loadingRedemptions', false)
          }
        },
        async login(_context, { username, password }) {
          set('startLoading')
          try {
            const accountInfo = await accountApi.login({ username, password })
            set('accountInfo', accountInfo)
            store.set('feature/markAsStale') // once logged in, feature flags may change
          } finally {
            set('stopLoading')
          }
        },
        async signup(_context, { username, password }) {
          set('startLoading')
          try {
            const accountInfo = await accountApi.signup({ username, password })
            set('accountInfo', accountInfo)
          } finally {
            set('stopLoading')
          }
        },
        async updateAccountInfo(_context, accountInfo) {
          set('startLoading')
          try {
            const updatedAccountInfo = await accountApi.updateAccountInfo(accountInfo)
            set('accountInfo', updatedAccountInfo)
          } finally {
            set('stopLoading')
          }
        },
        async changePassword(_context, payload) {
          set('startLoading')
          try {
            await accountApi.changePassword(payload)
          } finally {
            set('stopLoading')
          }
        },
        async addAddress(_context, address) {
          set('startLoading')
          try {
            const response = await addressesApi.createAddress(address)
            set('addresses', [...get('addresses'), response])
            return response.id
          } finally {
            set('stopLoading')
          }
        },
        async updateAddress(_context, address) {
          set('startLoading')
          try {
            const response = await addressesApi.updateAddress(address)
            // response object replaces a matching one here
            set(
              'addresses',
              get('addresses').map((address) => (address.id === response.id ? response : address))
            )
            // if the user has subscriptions, update related subscription addresses
            if (get('subscriptions')) {
              set('subscriptionAddress', response)
            }
          } finally {
            set('stopLoading')
          }
        },
        async updateSubscriptionAddress(_context, { addressId, address, subscriptionId }) {
          set('startLoading')
          try {
            const response = await subscriptionsApi.updateAddress({
              addressId,
              address,
              subscriptionId,
            })
            set(
              'subscriptions',
              get('subscriptions').map((subscription) =>
                subscription.id === response.id ? response : subscription
              )
            )
          } finally {
            set('stopLoading')
          }
        },
        async removeAddress(_context, addressId) {
          set('startLoading')
          try {
            await addressesApi.deleteAddressById(addressId)
            set(
              'addresses',
              get('addresses').filter((address) => address.id !== addressId)
            )
          } finally {
            set('stopLoading')
          }
        },
        async updateBillingInfo(_context, token) {
          set('startLoading')
          try {
            const response = await billingInfoApi.updateBillingInfo({ token })
            set('billingInfo', response)
          } finally {
            set('stopLoading')
          }
        },
        async updateAvatar(_context, avatar) {
          set('startLoading')
          try {
            const response = await accountApi.setAvatar({ avatar })
            set('avatar', response.avatar)
          } finally {
            set('stopLoading')
          }
        },
        addToRecentlyViewed(_context, product) {
          set('addRecentlyViewedProduct', product)
        },
        async fetchAutocompleteAddresses(_context, address) {
          const { header, url } = get('getFetchRequestOptions', { partialStreet: address })

          const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow',
          }

          return await fetch(url, requestOptions).then((response) => response.json())
        },
        async selectAutocompleteAddress(_context, { address, index }) {
          const { header, url } = get('getFetchRequestOptions', { index })

          const urlencoded = new URLSearchParams()
          urlencoded.append('partialStreet', address)

          const requestOptions = {
            method: 'POST',
            headers: header,
            body: urlencoded,
            redirect: 'follow',
          }

          return await fetch(url, requestOptions).then((response) => response.json())
        },
      }),
    }
  )
}
