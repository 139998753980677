// Polyfills
// http://cli.vuejs.org/guide/browser-compatibility.html
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener'
import 'intersection-observer'

import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import { datadogRum } from '@datadog/browser-rum'
import store from '@/store'
import router from '@/router'
import { loadGTM } from '@/helpers/gtm'
import configureVue from '@/helpers/configureVue'
import env from '@/helpers/env'
import App from '@/App'
import PrismicVue from '@prismicio/vue'
import linkResolver from '@/link-resolver'
import '@/app.css'

const accessToken = env.get('PRISMIC_ACCESS_TOKEN')
const endpoint = 'https://alltrue.prismic.io/api/v2'

if (!env.isDev() && !env.get('CI')) {
  loadGTM() // creates modals that block buttons and break integration tests

  // Init seen errors
  const seenErrors = {}

  // TODO: id, token, and service name should come from env
  datadogRum.init({
    applicationId: 'b45e7e74-58f8-47fe-bef0-e2aa0e60e960',
    clientToken: 'pubeadcbbba4557906fe9bcfa3023bcbb90',
    site: 'datadoghq.com',
    service: 'spa',
    env: env.get('ENVIRONMENT'),
    version: env.get('SPA_VERSION'),
    sampleRate: env.get('ENVIRONMENT') === env.ENVIRONMENT_STAGING ? 100 : 10,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    beforeSend: (event) => {
      const ignoreDomains = [
        'pinterest',
        'googletagmanager',
        'doubleclick',
        'sumo.com',
        'iubenda',
        'attn.tv',
        'hotjar',
        'google-analytics',
        'tiktok',
      ]

      const noise = ['Script error.', 'User is not authenticated']

      const ignoreDomainsRegex = new RegExp(ignoreDomains.map((d) => `\\b${d}\\b`).join('|'), 'i')
      if (event.type === 'error' && event.error) {
        // Check if this error has already been logged
        const dupeError = seenErrors[event.error.message]
        if (dupeError && dupeError < new Date().getTime()) {
          return false
        }

        // Store message with ttl of 5 minutes
        seenErrors[event.error.message] = new Date().getTime() + 300000

        if (
          // TypeError: Cancelled
          /\bcancelled\b/i.test(event.error.message) ||
          // Check error message for presence of ignored domains
          ignoreDomainsRegex.test(event.error.message) ||
          ignoreDomainsRegex.test(event.error.stack) ||
          // XHR errors pointing to ignored domains
          ignoreDomainsRegex.test(event.error.resource?.url_host) ||
          // remove noise
          noise.includes(event.error.message)
        ) {
          return false
        }
      }
    },
  })

  datadogRum.startSessionReplayRecording()
}

configureVue()

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
})

// Sync the current route with the Vuex store
sync(store, router)

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  components: {
    App,
  },
  render: (createElement) => createElement(App),
  store,
  router,
})
