import createModule from '@/store/createModule'
import screen from '@/helpers/screen'
import storeApi from '@/api/store'
import { ApiError } from '@/api'
import keyBy from '@/helpers/keyBy'
import { events } from '@/helpers/gtm'

export default (
  store,
  name,
  state,
  {
    getters = () => ({}),
    mutations = () => ({}),
    actions = () => ({}),
    freshnessDuration = 1000 * 60 * 60,
  } = {}
) => {
  const defaultState = {
    lineItems: [], // { variantId, quantity, removed }
    selectedShippingAddressId: null,
    couponCode: null,
    giftCardCode: null,
    maxQuantity: undefined,
    cartPreview: null,
    errors: null, // TODO: rename this to errorResponse
    calculatingLineItems: false,
    placingOrder: false,
    successfulOrder: false,
    addedToCart: false,
    ecoUpgrade: false,
    rushShipping: false,
    reviewRetries: 0,

    // TODO: this should come from config api
    rushShippingPrices: [1000, 1500], // regular + extra in pennies, available before preview
    ...state,
  }

  return createModule(
    store,
    name,
    { ...defaultState, ...state },
    {
      getters: (bag) => ({
        variantCanAddRushShipping() {
          return (variant) =>
            // feature flag is on
            store.get('feature/isRushShippingEnabled') &&
            // variant is eligible for rush shipping
            variant.rushShippingStock > 0
        },
        cartCanAddRushShipping() {
          return bag
            .get('finalLineItems')
            ?.some(({ variant }) => bag.get('variantCanAddRushShipping', variant))
        },
        catalogStore() {
          return bag.get('storeType') === 'marketplace' ? 'products' : bag.get('storeType')
        },
        productsById() {
          const catalogStore = bag.get('catalogStore')
          return store.get(`${catalogStore}/productsById`) ?? []
        },
        productsByVariantId() {
          const products = Object.values(bag.get('productsById'))
          return products.reduce((productsByVariantId, product) => {
            product.variants.forEach((variant) => {
              productsByVariantId[variant.id] = product
            })
            return productsByVariantId
          }, {})
        },
        variants() {
          return Object.values(bag.get('productsById')).flatMap(({ variants }) => variants)
        },
        variantsById() {
          return keyBy(bag.get('variants'), ({ id }) => id)
        },
        lineItems(state) {
          const productsByVariantId = bag.get('productsByVariantId')
          const variantsById = bag.get('variantsById')

          // address it differently to avoid infinite recursion
          if (!state.lineItems.length) {
            return []
          }

          return state.lineItems.map(({ variantId, ...rest }) => {
            const product = productsByVariantId[variantId]
            const variant = variantsById[variantId]

            return {
              ...rest,
              productId: product?.id,
              product,
              variantId: variant?.id,
              variant,
            }
          })
        },
        mobile() {
          return !screen().md
        },
        cartPreviewLoaded() {
          return bag.get('cartPreview') !== null // initial value => not loaded
        },
        nonRemovedLineItems() {
          return bag.get('lineItems').filter(({ removed }) => !removed)
        },
        soldOutLineItems() {
          return bag
            .get('lineItems')
            .filter(({ variant }) => variant?.stock <= 0 && !variant?.hasUnlimitedStock)
        },
        nonSoldOutLineItems() {
          // used for cart listing, must include removed line items
          return bag
            .get('lineItems')
            .filter(({ variant }) => variant?.stock > 0 || variant?.hasUnlimitedStock)
        },
        // Intersection of nonRemovedLineItems and nonSoldOutLineItems
        finalLineItems() {
          return bag
            .get('nonRemovedLineItems')
            .filter((item) => bag.get('nonSoldOutLineItems').includes(item))
        },
        productsInCart() {
          return [...new Set(bag.get('nonRemovedLineItems').map(({ productId }) => productId))]
        },
        isProductInCart() {
          return (productId) => bag.get('productsInCart').includes(productId)
        },
        isVariantInCart() {
          return (id) => bag.get('nonRemovedLineItems').some(({ variantId }) => variantId === id)
        },
        isMaxInCart() {
          return (variantId) => {
            const cartItem = bag
              .get('lineItems')
              .find((lineItem) => lineItem.variantId === variantId)
            if (!cartItem?.variant.hasUnlimitedStock) {
              const maxQuantity = cartItem?.variant.maxQuantity ?? 10
              return cartItem?.quantity >= Math.min(cartItem?.variant.stock, maxQuantity)
            }
            return false
          }
        },
        selectedShippingAddress() {
          const addressId =
            bag.get('selectedShippingAddressId') ?? store.get('account/defaultAddressId')
          return store.get('account/addresses').find(({ id }) => id === addressId)
        },
        rushShippingPrice() {
          // look up estimated price based on selected address before order preview
          const isExtraRushShipping =
            bag.get('selectedShippingAddress')?.isExtraRushShipping ?? false
          return bag.get('rushShippingPrices')[isExtraRushShipping ? 1 : 0] / 100 // pennies to dollars
        },
        isCartEmpty() {
          return bag.get('finalLineItems').length === 0
        },
        cartQuantity() {
          return bag
            .get('finalLineItems')
            .reduce((subtotal, { quantity }) => subtotal + quantity, 0)
        },
        retailTotal() {
          return (
            bag
              .get('finalLineItems')
              .reduce(
                (subtotal, { variant, quantity }) => subtotal + variant.retailValue * quantity,
                0
              ) ?? 0
          )
        },
        subtotal() {
          return (
            bag
              .get('finalLineItems')
              .reduce(
                (subtotal, { variant, quantity }) => subtotal + variant.price * quantity,
                0
              ) ?? 0
          )
        },
        ecoUpgradeAmount() {
          return (bag.get('cartPreview')?.ecoUpgrade ?? 0) / 100 // pennies to dollars
        },
        rushShippingAmount() {
          return (bag.get('cartPreview')?.rushShipping ?? 0) / 100 // pennies to dollars
        },
        cartSavings() {
          return Math.max(0, bag.get('retailTotal') - bag.get('subtotal'))
        },
        shippingAmount() {
          if (!bag.get('cartPreviewLoaded')) {
            return undefined
          }
          return bag.get('cartQuantity') ? bag.get('cartPreview').shipping : 0
        },
        discount() {
          return bag.get('cartPreview')?.discount ?? 0 // safe to assume 0
        },
        credit() {
          return (bag.get('cartPreview')?.credit ?? 0) / 100 // pennies to dollars
        },
        taxAmount() {
          return (bag.get('cartPreview')?.tax ?? 0) / 100 // pennies to dollars
        },
        cartTotal() {
          return bag.get('cartPreview')?.total
        },
        canAddOrderAddOn() {
          return Boolean(
            bag.get('selectedShippingAddress') &&
              !bag.get('isCartEmpty') &&
              !bag.get('calculatingLineItems') &&
              !bag.get('placingOrder') &&
              !bag.get('errors')
          )
        },
        canPlaceOrder() {
          return Boolean(bag.get('selectedShippingAddressId') && bag.get('canAddOrderAddOn'))
        },
        apiPayload() {
          return {
            lineItems: bag.get('finalLineItems').map(({ variantId, quantity, variant }) => ({
              variantId,
              quantity,
              hasRushShipping: bag.get('rushShipping')
                ? bag.get('variantCanAddRushShipping', variant)
                : false,
            })),
            shippingAddressId: bag.get('selectedShippingAddress').id,
            ecoUpgrade: bag.get('ecoUpgrade'),
            rushShipping: bag.get('rushShipping'),
            couponCode: bag.get('couponCode'),
            giftCardCode: bag.get('giftCardCode'),
          }
        },
        ...getters(bag),
      }),
      mutations: (bag) => ({
        doUpdateQuantity(state, { variantId, quantity }) {
          const lineItem = state.lineItems.find((lineItem) => lineItem.variantId === variantId)
          if (lineItem) {
            lineItem.quantity = quantity
          }
        },
        doToggleRemoveItem(state, { variantId }) {
          const lineItem = state.lineItems.find((lineItem) => lineItem.variantId === variantId)
          if (lineItem) {
            lineItem.removed = !lineItem.removed
          }
          // TODO: this needs to be cancelled with each doToggleRemoveItem + separate timers for each item
          /*
          setTimeout(() => {
            bag.set('doRemoveCartItem', { variantId })
          }, 2000)
          */
        },
        doRemoveCartItem(state, { variantId }) {
          state.lineItems = state.lineItems.filter((lineItem) => lineItem.variantId !== variantId)
        },
        doClearRemovedLineItems(state) {
          state.lineItems = state.lineItems.filter((lineItem) => !lineItem.removed)
        },
        addToCart(state, { variantId, quantity }) {
          const lineItem = state.lineItems.find((lineItem) => lineItem.variantId === variantId)
          if (lineItem) {
            lineItem.quantity = lineItem.quantity + parseInt(quantity) // sometimes it's a string
          } else {
            state.lineItems.push({
              variantId,
              quantity,
              removed: false,
            })
          }
        },
        doClearCart(state) {
          state.lineItems = []
          state.errors = null
          state.cartPreview = null
          state.ecoUpgrade = false
          state.rushShipping = false
          state.couponCode = null
          state.giftCardCode = null
        },
        setRushShippingStockToZero(state, { variantIds }) {
          const index = state.lineItems.findIndex((lineItem) =>
            variantIds.includes(lineItem.variantId)
          )
          const updatedLineItems = [...bag.get('lineItems')][index]
          updatedLineItems.variant.rushShippingStock = 0
          state.lineItems.splice(index, 1, updatedLineItems)
        },
        ...mutations(bag),
      }),
      actions: (bag) => ({
        clearCart(_context) {
          bag.set('doClearCart')
        },
        clearSoldOutItemsFromCart() {
          bag.set(
            'lineItems',
            bag
              .get('lineItems')
              .filter(({ variant }) => variant?.stock > 0 || variant?.hasUnlimitedStock)
          )
        },
        updateQuantity(_context, { variantId, quantity }) {
          if (quantity <= 0) {
            bag.set('doToggleRemoveItem', { variantId })
          } else {
            bag.set('doUpdateQuantity', { variantId, quantity })
          }
        },
        toggleCartItem({ dispatch }, { variantId }) {
          if (bag.get('isVariantInCart')(variantId)) {
            dispatch('removeCartItem', { variantId })
          } else {
            dispatch('addToCart', { variantId })
          }
        },
        toggleRemoveItem(_context, { variantId }) {
          bag.set('doToggleRemoveItem', { variantId })
        },
        removeCartItem(_context, { variantId }) {
          bag.set('doRemoveCartItem', { variantId })
        },
        clearRemovedLineItems(_context) {
          bag.set('doClearRemovedLineItems')
        },
        addToCart(_context, { variantId, quantity = 1 }) {
          bag.set('addedToCart', true) // gets reset to false after timeout in AppHeader
          bag.set('addToCart', { variantId, quantity })

          events.addToCart({
            storeType: bag.get('storeType'),
            product: bag.get('productsByVariantId')[variantId],
            variant: bag.get('variantsById')[variantId],
            quantity,
          })
        },
        async addToWaitlist(_state, { productId }) {
          events.log({
            name: 'product added to waitlist',
            data: {
              storeType: bag.get('storeType'),
              sku: bag.get('productsById')[Number(productId)]?.slug,
            },
          })
        },
        // updatedVariantStock is an object with the variant id as the key and updated stock as the value
        async adjustQtys(_context, updatedVariantStock = {}) {
          const catalogStore = bag.get('catalogStore')
          if (catalogStore === 'choicePlus') {
            return
          }
          // get fresh inventory first
          if (['essentials', 'products'].includes(catalogStore)) {
            await store.dispatch(`${catalogStore}/refreshInventory`, {
              productIds: bag.get('productsInCart'),
            })
          }

          // then reduce the quantities based on variant stock, but only if we track stock
          await bag.set(
            'lineItems',
            bag.get('lineItems').map(({ variant, quantity, ...rest }) => {
              const finalStock = updatedVariantStock[variant.id]
                ? updatedVariantStock[variant.id]
                : variant.stock
              // unlimited stock keeps the quantity, limited stock gets reduced to what's available
              quantity = variant.hasUnlimitedStock ? quantity : Math.min(quantity, finalStock)
              return { variant, quantity, ...rest }
            })
          )
        },
        adjustRushShippingStock({ dispatch }, variantIds) {
          bag.set('setRushShippingStockToZero', variantIds)
          if (!bag.get('cartCanAddRushShipping')) {
            bag.set('rushShipping', false)
          }
          dispatch('reviewOrder')
        },
        clearErrors(_context) {
          bag.set('errors', null)
        },
        async handleError({ dispatch }, error) {
          bag.set('errors', error.data)

          if (error instanceof ApiError) {
            if (error.type === 'out_of_stock') {
              await dispatch('adjustQtys')
            }
          }
        },
        // stubs for carts that don't need these
        afterFailedReviewOrder(_context) {
          const errors = bag.get('errors')

          if (errors?.errors?.couponCode) {
            bag.set('couponCode', null)
          }

          if (errors?.errors?.giftCardCode) {
            bag.set('giftCardCode', null)
          }
        },
        afterSuccessfulPlaceOrder(_context) {
          bag.set('couponCode', null)
          bag.set('giftCardCode', null)
        },
        afterFailedPlaceOrder({ dispatch }) {},
        initCart({ dispatch }) {
          if (!bag.get('calculatingLineItems')) {
            dispatch('clearErrors')
            dispatch('reviewOrder')
          }

          events.log({
            name: 'viewed cart',
            data: { storeType: bag.get('storeType') },
          })
        },
        async reviewOrder({ dispatch }) {
          try {
            // do nothing for empty cart or in-progress review (one api call at a time)
            if (!bag.get('productsInCart')?.length > 0 || bag.get('calculatingLineItems')) {
              return
            }

            bag.set('cartPreview', null)
            bag.set('calculatingLineItems', true)
            dispatch('clearErrors')

            const response = await storeApi.reviewOrder(bag.get('storeType'), bag.get('apiPayload'))
            bag.set('calculatingLineItems', false)

            const isTotalSame = response.subtotal === bag.get('subtotal')
            const isRushShippingSame = Boolean(response.rushShipping) === bag.get('rushShipping')

            // if the cart has not changed since the review was initiated
            if (isTotalSame && isRushShippingSame) {
              bag.set('cartPreview', response)
              bag.set('reviewRetries', 0)
            } else {
              bag.set('reviewRetries', bag.get('reviewRetries') + 1)
              if (bag.get('reviewRetries') < 5) {
                dispatch('reviewOrder') // try again with updated payload, but only a few times
              } else {
                // eslint-disable-next-line no-console
                console.error('Review order retried too many times')
              }
            }
          } catch (error) {
            dispatch('handleError', error)
            dispatch('afterFailedReviewOrder')

            bag.set('calculatingLineItems', false)
          }
          // do not use finally, it executes even with an early return
        },
        async placeOrder({ dispatch }, extraData) {
          try {
            bag.set('placingOrder', true)
            dispatch('clearErrors')

            // Add the displayed cart total to the payload
            const apiPayload = {
              ...bag.get('apiPayload'),
              reviewTotal: bag.get('cartTotal'),
              ...extraData,
            }
            const response = await storeApi.placeOrder(bag.get('storeType'), apiPayload)

            bag.set('successfulOrder', response.success)
            dispatch('afterSuccessfulPlaceOrder')

            events.marketOrder({
              storeType: bag.get('storeType'),
              total: bag.get('cartTotal'),
              lineItems: bag.get('finalLineItems'),
            })

            bag.set('placingOrder', false)
            // do NOT clearCart here, CheckoutConfirmation will do that

            return response
          } catch (error) {
            dispatch('handleError', error)
            dispatch('afterFailedPlaceOrder')
          } finally {
            // fires no matter what, even with return and exceptions above
            bag.set('placingOrder', false)
          }
        },
        ...actions(bag),
      }),
    }
  )
}
