import createModule from '@/store/createModule'
import subscriptionsApi from '@/api/subscriptions'
import accountApi from '@/api/account'
import { PLAN_PRICE } from '@/helpers/constants'
import formatPrice from '@/helpers/formatPrice'
import keyBy from '@/helpers/keyBy'
import pick from '@/helpers/pick'
import { ApiError } from '@/api'
import { events } from '@/helpers/gtm'
import screen from '@/helpers/screen'

export const ChoiceAutoSelectModalTypes = Object.freeze({
  MYSTERY: 10,
  CHOOSE_FOR_ME: 20,
})

export default (store) => {
  createModule(
    store,
    'customize',
    {
      subscriptions: null, // TODO: this looks deprecated
      boxCarts: null,
      mockRoute: null,
      videoTimestamp: null,
      selectedPurchasedChoicePlusVariants: null,
      shippingTimelineModalVisible: false,
      choiceAutoSelectModalVisible: false,
      choiceAutoSelectModalType: null,
      myBoxShowBoxChoicesTab: true,
      myBoxShowChoicePlusTab: false,
      mysteryChoiceSkuBase: '-MYS-',
      mysteryChoiceImage: '/static/customize/mystery-choice.png',
    },
    {
      getters: ({ get }) => ({
        findBoxBySubscription() {
          return (subscription) =>
            store.get('config/openBoxes')?.find((openBox) => openBox?.id === subscription?.boxId)
        },
        isCustomizable() {
          // true when the box allows customization and this subscription's latest boxcart has no order
          return (subscription) => {
            const box = get('findBoxBySubscription', subscription)
            const boxCart = get('latestBoxCartBySubscriptionId')[subscription.id]
            const isCustomizableForType =
              subscription.type === 'annually'
                ? box?.choiceAnnualEnabled
                : box?.choiceQuarterlyEnabled && box?.hasQuarterlyCustomization

            return boxCart?.status === 'open' && !boxCart?.orderId && isCustomizableForType
          }
        },
        isSelectedSubscriptionCustomizable() {
          const subscription = get('selectedSubscription')
          if (subscription) {
            return get('isCustomizable', subscription)
          }
          return undefined
        },
        isComingSoon() {
          return (subscription) => {
            const box = get('findBoxBySubscription', subscription) ?? store.get('config/currentBox')
            const boxCart = get('latestBoxCartBySubscriptionId')[subscription.id]
            return boxCart?.status === 'open' && !boxCart?.orderId && !box?.choiceAnnualEnabled
          }
        },
        hasOnlyComingSoonSubscriptions() {
          return store
            .get('account/activeSubscriptions')
            ?.every((subscription) => get('isComingSoon', subscription))
        },
        customizableProductsbyType() {
          return (subscription) => {
            const isQuarterly = subscription.type === 'quarterly'
            const box = get('findBoxBySubscription', subscription)
            if (box) {
              return isQuarterly
                ? box.products?.filter((product) => product.isCustomizableForQuarterlies)
                : box.products
            }
            return null
          }
        },
        customizationIsComplete() {
          return (subscription) => {
            const boxCart = get('latestBoxCartBySubscriptionId')[subscription.id]
            const products = get('customizableProductsbyType', subscription)
            return products?.length === boxCart?.lineItems?.length
          }
        },
        isAwaitingShipment() {
          // true when the associated box cart's status is completed, there's an orderId and the status of the shipment is 'Awaiting Shipment'
          return (subscription) => {
            const shipment = store
              .get('account/shipments')
              ?.find(({ subscriptionId }) => subscriptionId === subscription.id)

            return shipment?.status === 'Awaiting Shipment'
          }
        },
        awaitingShipmentSubscriptions() {
          return store
            .get('account/subscriptions')
            ?.filter((subscription) => get('isAwaitingShipment', subscription))
        },
        hasAwaitingShipmentSubscriptions() {
          return Boolean(get('awaitingShipmentSubscriptions')?.length)
        },
        customizableSubscriptions() {
          return store
            .get('account/activeSubscriptions')
            ?.filter((subscription) => get('isCustomizable', subscription))
        },
        hasCustomizableSubscriptions() {
          return Boolean(get('customizableSubscriptions')?.length)
        },
        firstCustomizableSubscriptionId() {
          return get('customizableSubscriptions')[0]?.id
        },
        allBoxCartsBySubscriptionId() {
          return get('boxCarts').reduce((allBoxCarts, boxCart) => {
            const subId = boxCart.subscriptionId.toString()
            if (!Object.keys(allBoxCarts).includes(subId)) {
              allBoxCarts[subId] = [boxCart]
            } else {
              allBoxCarts[subId].push(boxCart)
            }
            return allBoxCarts
          }, {})
        },
        latestBoxCartBySubscriptionId() {
          /* get('boxCarts') returns box carts in descending order (recent first)
            and keyBy will overwrite any entries with duplicate keys,
            so it needs boxCarts in ascending order (recent last) if we want to return the latest boxCart */
          return keyBy([...get('boxCarts')]?.reverse(), ({ subscriptionId }) => subscriptionId)
        },
        openBoxCarts() {
          return get('boxCarts')?.filter((boxCart) => boxCart.status === 'open') ?? []
        },
        hasOpenBoxCarts() {
          return Boolean(get('openBoxCarts')?.length)
        },
        openBoxCartsWithAnnualChoiceEnabled() {
          const openBoxIds = get('openBoxCarts')?.map((boxCart) => boxCart.boxId)
          return openBoxIds?.reduce((boxes, id) => {
            const box = store.get('config/boxesById')[id]
            if (box?.choiceAnnualEnabled) {
              boxes.push(box)
            }
            return boxes
          }, [])
        },
        customizationIsLive() {
          return Boolean(get('openBoxCartsWithAnnualChoiceEnabled')?.length)
        },
        selectedSubscription() {
          const route = get('mockRoute') ?? store.get('route')
          const selectedSubscriptionId = Number(route?.params?.subscriptionId)
          const activeSubscriptions = store.get('account/activeSubscriptions')
          return (
            activeSubscriptions?.find(
              (subscription) => subscription.id === selectedSubscriptionId
            ) ?? activeSubscriptions?.[0]
          )
        },
        isSelectedSubscriptionQuarterly() {
          return get('selectedSubscription')?.type === 'quarterly'
        },
        isSelectedSubscriptionAnnual() {
          return get('selectedSubscription')?.type === 'annually'
        },
        isWaitlistAndIsQuarterly() {
          return get('isSelectedSubscriptionQuarterly') && store.get('config/isCurrentBoxWaitlist')
        },
        selectedBoxCart() {
          const selectedSubscriptionId = get('selectedSubscription')?.id
          const selectedBoxId = get('selectedSubscription')?.boxId
          if (!selectedBoxId || !selectedSubscriptionId) {
            return undefined
          }
          return get('boxCarts')?.find(
            (boxCart) =>
              boxCart.boxId === selectedBoxId && boxCart.subscriptionId === selectedSubscriptionId
          )
        },
        // getting the open box that matches the selected subscription
        selectedBox() {
          const selectedBoxId = get('selectedSubscription')?.boxId
          if (!selectedBoxId) {
            return undefined
          }
          return get('findBoxBySubscription', get('selectedSubscription'))
        },
        // getting all possible variants vs below which will depend on the type of subscription
        // (used to show possible customizable products if user upgrades from quartertly to annual)
        selectedBoxAllVariants() {
          return get('selectedBox')?.products.flatMap((product) => product.variants)
        },
        // filtering products from `selectedBox` depending on type of subscription (quarterly vs. annual)
        filteredSelectedBoxProducts() {
          // remove products with a single variant
          return get('selectedBox')?.products.filter((product) =>
            get('isSelectedSubscriptionQuarterly')
              ? product.isCustomizableForQuarterlies && product.variants.length > 1
              : product.variants.length > 1
          )
        },
        // variants can be quarterly or annual, depending on subscription
        filteredSelectedBoxVariants() {
          return get('filteredSelectedBoxProducts')
            ?.flatMap((product) => product.variants)
            ?.filter(
              (variant) =>
                variant.selectionStatus === 'active' && !get('isMysteryVariant', variant)
            )
        },
        filteredSelectedBoxVariantsById() {
          const filteredSelectedBoxVariants = get('filteredSelectedBoxVariants')

          if (!filteredSelectedBoxVariants) {
            return []
          }

          return keyBy(filteredSelectedBoxVariants, ({ id }) => id)
        },
        // Variants that quarterly members don't have access to but annual members do
        filteredSelectedBoxUpsellVariants() {
          return get('selectedBox')
            ?.products.filter(
              (product) => !product.isCustomizableForQuarterlies && product.variants.length > 1
            )
            .flatMap((product) => product.variants)
            .filter((variant) => variant.selectionStatus !== 'inactive')
        },
        currentChoice() {
          const route = get('mockRoute') ?? store.get('route')
          if (route.name === 'CustomizeChoice' || route.name === 'CustomizeChoiceDetails') {
            return Number(route.params.choice)
          }
          return undefined
        },
        currentChoiceVariants() {
          const selectedBoxCart = get('selectedBoxCart')
          const currentChoice = get('currentChoice')
          if (!selectedBoxCart || !currentChoice) {
            return []
          }
          return get('filteredSelectedBoxProducts')
            ?.[currentChoice - 1]?.variants.filter(
              (variant) => variant.selectionStatus !== 'inactive'
            )
            .map((variant) => ({
              ...variant,
              chosen: selectedBoxCart.lineItems.some(
                (lineItem) => lineItem.variant.id === variant.id
              ),
            }))
        },
        currentChoiceVariantIds() {
          return get('currentChoiceVariants').map((variant) => variant.id)
        },
        choiceChooseForMeVariantBaseId() {
          const currentBox = store.get('config/currentBox')
          const generatedBaseId = `${currentBox?.year}-${currentBox?.season?.toLowerCase()}-${
            currentBox?.number
          }-${get('selectedSubscription')?.id}`
          return generatedBaseId
        },
        choiceChooseForMeVariantId() {
          return (choiceNumber = get('currentChoice')) => {
            if (!choiceNumber) {
              return () => undefined
            }
            const generatedId = `${get('choiceChooseForMeVariantBaseId')}-${choiceNumber}`
            return generatedId
          }
        },
        choiceChooseForMeVariant() {
          return (choiceNumber = get('currentChoice')) => {
            const chooseForMeVariant = {
              id: get('choiceChooseForMeVariantId', choiceNumber),
              type: 'boxProducts',
              isDefault: true,
              sku: '',
              price: null,
              retailValue: null,
              stock: 0,
              rushShippingStock: null,
              hasUnlimitedStock: false, // disable Choice+
              maxQuantity: null,
              brand: 'Choose for me',
              productId: null,
              productTitle: `Can’t decide? ${
                screen().xs ? 'We’ll pick one of these for you!' : 'Let us pick for you!'
              }`,
              slug: null,
              title: null,
              description: '',
              materials: null,
              careInstructions: null,
              isSuperchoice: false,
              superchoicePrice: 0,
              msrp: 0,
              selectionStatus: 'active',
              salesChannels: null,
              previewCardImages: ['/static/customize/choose-for-me.png'],
              essentialsTags: [],
              chosen: false,
            }
            return chooseForMeVariant
          }
        },
        isMysteryVariant() {
          return (variant) => {
            const mysterySkuBase = get('mysteryChoiceSkuBase').toLowerCase()
            return variant?.sku?.toLowerCase().includes(mysterySkuBase)
          }
        },
        savedChooseForMeVariantIds() {
          try {
            const savedVariantIds = localStorage.getItem('chooseForMeVariantIds')
            return savedVariantIds ? JSON.parse(savedVariantIds) : []
          } catch (e) {
            localStorage.removeItem('chooseForMeVariantIds')
          }
        },
        chosenVariantIds() {
          const selectedBoxCart = get('selectedBoxCart')

          if (!selectedBoxCart) {
            return []
          }

          return selectedBoxCart?.lineItems.map((lineItem) => lineItem.variant.id)
        },
        chosenVariants() {
          const chosenVariantIds = get('chosenVariantIds')
          if (!chosenVariantIds) {
            return undefined
          }
          return get('filteredSelectedBoxProducts')?.map((product) =>
            product.variants.find((variant) => chosenVariantIds.includes(variant.id))
          )
        },
        firstNonCompletedChoiceIndex() {
          return get('chosenVariants')?.findIndex((variantId) => variantId === undefined)
        },
        // difference between `allPossibleChoiceCounts` and `choiceCount` is that if a user is customizing for quarterly, their total choice count could be lower than that of all possible choice counts
        allPossibleChoiceCounts() {
          return get('selectedBox')?.products.length ?? 0
        },
        choiceCount() {
          return get('filteredSelectedBoxProducts')?.length ?? 0
        },
        isLastChoice() {
          return get('currentChoice') === get('choiceCount')
        },
        hasMoreChoicesComing() {
          return Boolean(
            get('selectedSubscription')?.type === 'annually' &&
              get('selectedBox')?.hasImminentProducts
          )
        },
        skipFirstScreen() {
          return (
            store.get('account/activeSubscriptions')?.length === 1 &&
            get('chosenVariantIds').length > 0
          )
        },
        upgradeSeasons() {
          return (subscriptionId) => {
            const id = subscriptionId ?? Number(store.get('route')?.params.subscriptionId)
            const seasonsRemaining = store
              .get('account/subscriptions')
              ?.find((subscription) => subscription.id === id)?.upgradeSeasons
            return seasonsRemaining ?? 0
          }
        },
        upgradeCost() {
          return (subscriptionId) => {
            const id = subscriptionId ?? Number(store.get('route')?.params.subscriptionId)
            return get('upgradeSeasons', id) === 3
              ? formatPrice(PLAN_PRICE.annually - PLAN_PRICE.quarterly)
              : formatPrice(PLAN_PRICE.annually)
          }
        },
        filteredSelectedPurchasedChoicePlusVariants() {
          // only grabbing the purchased variants for this season
          const purchasedVariantIds = get('selectedPurchasedChoicePlusVariants')?.map(
            ({ id }) => id
          )
          return get('filteredSelectedBoxVariants')?.filter(({ id }) =>
            purchasedVariantIds?.includes(id)
          )
        },
        filteredSelectedPurchasedChoicePlusVariantIds() {
          return get('filteredSelectedPurchasedChoicePlusVariants')?.map(({ id }) => id)
        },
        isVariantPurchased() {
          return (variantId) => {
            return (
              get('filteredSelectedPurchasedChoicePlusVariantIds')?.includes(variantId) ?? false
            )
          }
        },
        currentSubscriptions() {
          return get('customizableSubscriptions').length
            ? get('customizableSubscriptions')
            : store.get('account/activeSubscriptions')
        },
        customizableBoxProducts() {
         // Only show active variants...
         const boxProducts = get('findBoxBySubscription', get('currentSubscriptions')?.[0])?.products
         const activeProducts = boxProducts?.map(product => {
           const activeVariants = product.variants.filter(variant => variant.selectionStatus === 'active')
           return activeVariants.length > 0 ? { ...product, variants: activeVariants } : null
         }).filter(product => product !== null)
         return activeProducts
        },
        getProductIndex() {
          return (productId) =>
            get('customizableBoxProducts')?.findIndex((product) => product.id === productId)
        },
        getChoiceStepColor() {
          return (index) => {
            const choiceStepColors = ['winter', 'fall', 'spring', 'primary', 'sunshine', 'summer']

            if (index < 6) {
              return choiceStepColors[index]
            }
            return 'dawn'
          }
        },
        // get customization dates for the subscription with the latest boxId
        latestBoxCustomizationDates() {
          const activeSubscriptions = store.get('account/activeSubscriptions')
          let latestActiveSubscription = null
          if (activeSubscriptions?.length === 1) {
            latestActiveSubscription = activeSubscriptions[0]
          } else if (activeSubscriptions?.length > 1) {
            // getting sub with latest boxId
            latestActiveSubscription = activeSubscriptions?.reduce((latestSub, currentSub) => {
              if (currentSub.boxId > latestSub.boxId) {
                latestSub = currentSub
              }
              return latestSub
            })
          }
          const selectedBox = latestActiveSubscription
            ? store.get('config/boxesById')?.[latestActiveSubscription?.boxId]
            : store.get('config/currentBox')
          const startDate = selectedBox.choiceAnnualStartDate
            ? new Date(selectedBox.choiceAnnualStartDate.replace(/-/g, '/'))
            : null
          const endDate = selectedBox.choiceAnnualEndDate
            ? new Date(selectedBox.choiceAnnualEndDate.replace(/-/g, '/'))
            : null
          if (startDate && endDate) {
            return {
              startMonth: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate),
              startDay: new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(startDate),
              endMonth: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(endDate),
              endDay: new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(endDate),
            }
          }
          return null
        },
        customizationStartDate() {
          return `${get('latestBoxCustomizationDates')?.startMonth} ${
            get('latestBoxCustomizationDates')?.startDay
          }`
        },
      }),

      mutations: ({ markAsLoaded }) => ({
        init(state, { subscriptions, boxCarts }) {
          state.subscriptions = subscriptions
          state.boxCarts = boxCarts
          markAsLoaded(state)
        },
        refreshBoxCarts(state, boxCarts) {
          state.boxCarts = boxCarts
        },
        doSetVideoTimestamp(state, { time }) {
          state.videoTimestamp = time
        },
        doSetShippingTimelineModalVisible(state, modalVisible) {
          state.shippingTimelineModalVisible = modalVisible
        },
        doSetChoiceAutoSelectModalVisible(state, { modalVisible, choiceAutoSelectModalType }) {
          state.choiceAutoSelectModalVisible = modalVisible
          state.choiceAutoSelectModalType = choiceAutoSelectModalType
        },
        mockRoute(state, mockRoute) {
          state.mockRoute = mockRoute ? pick(mockRoute, ['path', 'name', 'params']) : null
        },
        doToggleMyBoxSelectedChoicesTab(state, { tab }) {
          if (tab === 'boxChoice') {
            state.myBoxShowBoxChoicesTab = true
            state.myBoxShowChoicePlusTab = false
          } else {
            state.myBoxShowBoxChoicesTab = false
            state.myBoxShowChoicePlusTab = true
          }
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const fetchingSubscriptions = subscriptionsApi.fetchSubscriptions()
            const fetchingBoxCarts = accountApi.fetchBoxCarts()
            const [subscriptions, boxCarts] = await Promise.all([
              fetchingSubscriptions,
              fetchingBoxCarts,
            ])
            set('init', {
              subscriptions,
              boxCarts,
            })
          } finally {
            set('stopLoading')
          }
        },
        async refreshBoxCarts() {
          const boxCarts = await accountApi.fetchBoxCarts()
          set('refreshBoxCarts', boxCarts)
        },
        async updateChoice(_context, payload) {
          const response = await accountApi.setChoice(payload)
          set(
            'boxCarts',
            get('boxCarts').map((boxCart) => (boxCart.id === response.id ? response : boxCart))
          )
        },
        setVideoTimestamp(_context, time) {
          set('doSetVideoTimestamp', time)
        },
        setShippingTimelineModalVisible(_context, modalVisible) {
          set('doSetShippingTimelineModalVisible', modalVisible)
        },
        setChoiceAutoSelectModalVisible(_context, { modalVisible, choiceAutoSelectModalType }) {
          set('doSetChoiceAutoSelectModalVisible', { modalVisible, choiceAutoSelectModalType })
        },
        async fetchPurchasedChoicePlusVariants(_context, { subscriptionReference }) {
          try {
            const variants = await subscriptionsApi.getPurchasedChoicePlusVariants({
              subscriptionReference,
            })
            set('selectedPurchasedChoicePlusVariants', variants)
          } catch (error) {
            if (error instanceof ApiError) {
              return
            }
            return Promise.reject(error)
          }
        },
        toggleMyBoxSelectedChoicesTab(_context, tab) {
          set('doToggleMyBoxSelectedChoicesTab', tab)
        },
        saveChooseForMeVariantId(_context, variantId) {
          const savedVariantIds = get('savedChooseForMeVariantIds')
          if (!savedVariantIds.includes(variantId)) {
            savedVariantIds.push(variantId)
          }
          const parsed = JSON.stringify(savedVariantIds)
          localStorage.setItem('chooseForMeVariantIds', parsed)

          events.log({
            name: 'selected choose for me',
            data: { choiceNumber: get('currentChoice') },
          })
        },
      }),
    }
  )
}
