import api, { METHOD_GET, METHOD_POST, METHOD_PUT } from '@/api'

export default api.module('account', {
  fetchAccountInfo: {
    method: METHOD_GET,
    path: '',
  },
  updateAccountInfo: {
    method: METHOD_PUT,
    path: '',
  },
  profile: {
    method: METHOD_GET,
    path: 'profile',
  },
  redemptions: {
    method: METHOD_GET,
    path: 'redemptions',
  },
  fetchBoxCarts: {
    method: METHOD_GET,
    path: 'box-carts',
  },
  login: {
    method: METHOD_POST,
    path: 'login',
  },
  signup: {
    method: METHOD_POST,
    path: 'signup',
  },
  logout: {
    method: METHOD_GET,
    path: 'logout',
  },
  resetPassword: {
    method: METHOD_POST,
    path: 'reset-password',
  },
  setPassword: {
    method: METHOD_POST,
    path: 'set-password',
  },
  changePassword: {
    method: METHOD_POST,
    path: 'change-password',
  },
  setAvatar: {
    method: METHOD_POST,
    path: 'set-avatar',
  },
  setChoice: {
    method: METHOD_POST,
    path: 'set-choice',
  },
  joinList: {
    method: METHOD_POST,
    path: 'join-list',
  },
  setSurveyAnswer: {
    method: METHOD_POST,
    path: 'set-survey-answer',
  },
  getAllAnswersForUser: {
    method: METHOD_GET,
    path: 'get-all-answers-for-user',
  },
})
