/**
 * Formats a price for display with the currency symbol and 2 decimals (or no decimals if the `price` is a round number and `stripZeros` is `true`, which is the default).
 */

export default (price, stripZeros = true) => {
  let priceString = Math.abs(price).toFixed(2)
  if (stripZeros && priceString.slice(-2) === '00') {
    priceString = `${Number(priceString)}`
  }
  return `${price < 0 ? '-' : ''}$${priceString}`
}
