/**
 * Converts an array to a plain object that acts as a hash map, where each item from the
 * array is stored under a key that is computed from the `indexFunction` being passed the
 * item. It is used to “index” an array of objects by a particular property that is meant
 * to be unique, e.g. `id` (`indexFunction` would be `(item) => item.id`).
 * The name of the function is taken from Lodash: https://lodash.com/docs/4.17.15#keyBy
 */

export default (array, indexFunction) => {
  return array && array.map
    ? Object.fromEntries(array.map((item) => [indexFunction(item), item]))
    : {}
}
