import { make } from 'vuex-pathify'
import clone from 'clone-deep'
import timeElapsed from '@/helpers/timeElapsed'
import waitForValueWithFunction from '@/helpers/waitForValue'

/**
 * `store` - The Vuex store
 * `name` - The name of the module to create
 * `state` - An object representing the default state of the module
 * `getters`, `mutations`, and `actions` - Functions that get passed a bag of properties (data and helper functions) and should return an object (the getters/mutations/actions to add to the module)
 */
export default (
  store,
  name,
  state,
  {
    getters = () => ({}),
    mutations = () => ({}),
    actions = () => ({}),
    freshnessDuration = 1000 * 60 * 60, // 1 hour
  } = {}
) => {
  const get = (path, ...args) => store.get(`${name}/${path}`, ...args)
  const set = (path, value) => store.set(`${name}/${path}`, value)
  const waitForValue = async (watchedExpression, targetValueOrTest) =>
    waitForValueWithFunction(store.watch.bind(store), watchedExpression, targetValueOrTest)
  const markAsLoaded = (state) => {
    state.lastLoaded = Date.now()
    state.markedAsStale = false
  }

  const defaultState = {
    lastLoaded: null,
    loadRequests: 0,
    loadRequestsProcessed: 0,
    markedAsStale: false,
    ...state,
  }

  const bag = {
    get,
    set,
    waitForValue,
    markAsLoaded,
    defaultState,
  }

  store.registerModule(name, {
    namespaced: true,

    state: clone(defaultState),

    getters: {
      loaded() {
        return get('lastLoaded') !== null
      },
      stale() {
        if (!get('loaded') || get('markedAsStale')) {
          return true
        }
        return timeElapsed.currentTime > (get('lastLoaded') ?? 0) + freshnessDuration
      },
      loadRequestsActive() {
        return get('loadRequests') - get('loadRequestsProcessed')
      },
      loading() {
        return get('loadRequestsActive') > 0
      },
      ...getters(bag),
    },

    mutations: {
      ...make.mutations(defaultState),
      startLoading(state) {
        state.loadRequests++
      },
      stopLoading(state) {
        state.loadRequestsProcessed++
      },
      markAsLoaded,
      markAsStale(state) {
        state.markedAsStale = true
      },
      reset(state, { keys, exclude } = {}) {
        keys = keys ?? Object.keys(state)
        exclude = exclude ?? []
        keys
          .filter((key) => exclude.includes(key) === false)
          .forEach((key) => {
            state[key] = clone(defaultState[key])
          })
      },
      ...mutations(bag),
    },

    actions: {
      async ensureFresh({ dispatch }) {
        if (get('stale')) {
          await dispatch('load')
        }
      },
      ...actions(bag),
    },
  })
}
