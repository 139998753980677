import clone from 'clone-deep'
import createModule from '@/store/createModule'
import storeApi from '@/api/store'
import filterProducts from '@/helpers/filterProducts'
import { events } from '@/helpers/gtm'

export default (store) => {
  createModule(
    store,
    'marketplace',
    {
      topTenProducts: [],
      tags: [],
      categories: [],
      brands: [],
      showFavoritesOnly: false,
      filters: {
        tags: [],
        categories: [],
        brands: [],
      },
      tagColors: {
        'cb-picks': 'summer',
        'daily-deals': 'sunshine',
        'cb-featured': 'fall',
        'almost-out': 'spring',
        'sold-out': 'gray-opacity68',
        'holiday-delivery': 'dawn-lt2',
      },
      tagTextColors: {
        'cb-picks': 'white',
        'daily-deals': 'black',
        'cb-featured': 'black',
        'almost-out': 'black',
        'sold-out': 'white',
        'holiday-delivery': 'black',
      },
    },
    {
      getters: ({ get }) => ({
        allTags() {
          const tags = [
            ...get('tags'),
            // these tags should appear last
            {
              slug: 'almost-out',
              title: 'Almost Out',
              children: [],
            },
            {
              slug: 'sold-out',
              title: 'Sold Out',
              children: [],
            },
          ]
          if (store.get('feature/isRushShippingEnabled')) {
            tags.push({
              slug: 'holiday-delivery',
              title: 'Holiday Delivery',
              children: [],
            })
          }
          return tags
        },
        products() {
          return store.get('products/products')
        },
        trendingProducts() {
          const skus = [
            'CB005426-FRE-SP22',
            'CB005486-HNY-SP22',
            'CB005420-FAN-SP22',
            'CB004550-MAG-WI21',
            'CB005472-GUU-SP22',
            'CB005231-MOD-SP22',
            'CB005645-OEA-SP22',
            'CB004718-HOB-WI21',
            'CB004734-FCH-WI21',
            'CB005655-PLM-SP22',
            'CB005382-EKB-SP22',
            'CB004699-FSW-WI21',
            'CB005617-MTR-SP22',
            'CB005335-BYB-SP22',
            'CB004213-SHS-FA21',
            'CB005287-ANN-SP22',
            'CB005330-BPC-SP22',
            'CB005448-GFC-SP22',
            'CB005768-STF-SP22',
            'CB005814-SUP-SP22',
            'CB005808-SUP-SP22',
            'CB000974-FGO-FA20',
            'CB005395-ESC-SP22',
            'CB005672-PPJ-SP22',
            'CB002527-PRP-SU21',
            'CB005272-ABY-SP22',
            'CB005273-ABY-SP22',
            'CB005274-ABY-SP22',
            'CB005439-FRE-SP22',
            'CB005350-CCF-SP22',
            'CB005348-CCF-SP22',
          ]
          return get('products').filter(({ variants }) => skus.includes(variants[0].sku))
        },
        rushShippingProducts() {
          return get('products')?.filter(({ defaultVariant }) =>
            store.get('marketplaceCart/variantCanAddRushShipping', defaultVariant)
          )
        },
        favoritedProducts() {
          const favorites = store.get('account/favorites')
          return get('products').filter((product) => favorites.includes(product.id))
        },
        filteredProducts() {
          // determine the base collection of products for the remaining filters
          const products = get('showFavoritesOnly') ? get('favoritedProducts') : get('products')
          const filters = get('filters')

          return products.filter((product) => {
            // true when there are no tags selected or when all selected tags match
            let result = filters.tags.every((slug) => {
              if (slug === 'holiday-delivery') {
                return true
              }
              return product.tags.includes(slug)
            })

            if (result) {
              if (filters.tags.includes('holiday-delivery')) {
                result =
                  result &&
                  store.get('marketplaceCart/variantCanAddRushShipping', product.defaultVariant)
              }

              if (filters.categories.length > 0) {
                result =
                  result && filters.categories.some((slug) => product.categories.includes(slug))
              }

              if (filters.brands.length) {
                const checkedBrands = get('brands').filter((_brand, index) =>
                  filters.brands.includes(String(index))
                )
                result = result && checkedBrands.includes(product.brand)
              }
            }

            return result
          })
        },
        getFiltersCount() {
          return Object.values(get('filters')).reduce((acc, curr) => {
            acc += curr.length
            return acc
          }, 0)
        },
        serializedFilters() {
          const enabledFilters = Object.entries(get('filters')).filter(
            ([_filterName, filterValues]) => filterValues.length > 0
          )
          if (enabledFilters.length === 0) {
            return undefined
          }
          return enabledFilters
            .map(([filterName, filterValues]) => `${filterName}=${filterValues.join(',')}`)
            .join('&')
        },
        pathWithFilters() {
          const serializedFilters = get('serializedFilters')
          return `/market${serializedFilters ? `/${serializedFilters}` : ''}`
        },
        isPDPView() {
          const routeName = store.get('route').name
          return routeName?.includes('MarketProduct') && !routeName.includes('Subscribe')
        },
      }),

      mutations: ({ defaultState, markAsLoaded }) => ({
        init(state, response) {
          state.topTenProducts = response.topTenProducts
          state.tags = response.tags
          state.categories = response.categories
          state.brands = response.brands
          markAsLoaded(state)
        },
        filters(state, filters) {
          state.filters = clone(defaultState.filters)
          // Handle serialized filters (when coming from the URL)
          if (typeof filters === 'string') {
            filters.split('&').forEach((serializedFilter) => {
              const [filterName, serializedFilterValues] = serializedFilter.split('=')
              if (state.filters[filterName]) {
                const filterValues = serializedFilterValues.split(',')
                if (filterValues.length > 1 || filterValues[0])
                  state.filters[filterName].push(...filterValues)
              }
            })
            return
          }
          state.filters = {
            ...state.filters,
            ...filters,
          }
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const response = await storeApi.init('marketplace')
            set('init', { ...response, products: filterProducts(store, response.products) })
          } finally {
            set('stopLoading')
          }
        },
        async addToCart(_context, { variantId, quantity = 1 }) {
          store.dispatch('marketplaceCart/addToCart', {
            variantId,
            quantity,
          })

          if (events.marketAddToCart) {
            events.marketAddToCart({
              email: store.get('account/username'),
              product: store.get('products/productsByVariantId')[variantId],
              variant: store.get('products/variantsById')[variantId],
              quantity,
            })
          }
        },
        async setFavorited(_context, { productId, isFavorited }) {
          const favorites = [...store.get('account/favorites')]
          let promise
          if (isFavorited) {
            favorites.push(productId)
            promise = storeApi.addFavorite(productId)
          } else {
            favorites.splice(favorites.indexOf(productId), 1)
            promise = storeApi.removeFavorite(productId)
          }
          store.set('account/favorites', favorites)
          await promise
        },
      }),
    }
  )
}
