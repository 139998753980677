import clone from 'clone-deep'
import createModule from '@/store/createModule'
import storeApi from '@/api/store'
import keyBy from '@/helpers/keyBy'
import filterProducts from '@/helpers/filterProducts'

export default (store) => {
  createModule(
    store,
    'products',
    {
      products: [],
    },
    {
      getters: ({ get }) => ({
        productsById() {
          return keyBy(get('products'), ({ id }) => id)
        },
        productsByVariantId() {
          return get('products').reduce((productsByVariantId, product) => {
            product.variants.forEach((variant) => {
              productsByVariantId[variant.id] = product
            })
            return productsByVariantId
          }, {})
        },
        variantsById() {
          const variants = get('products').reduce((variants, product) => {
            variants.push(...product.variants)
            return variants
          }, [])
          return keyBy(variants, ({ id }) => id)
        },
      }),

      mutations: ({ get, markAsLoaded }) => ({
        products(state, products) {
          state.products = products.map((product) => {
            if (!product.defaultVariant.hasUnlimitedStock) {
              if (product.defaultVariant.stock <= 0) {
                product.tags.push('sold-out')
              } else if (product.defaultVariant.stock < 175) {
                product.tags.push('almost-out')
              }
            }
            return product
          })
          markAsLoaded(state)
        },
        updateProducts(_state, products) {
          const stateProductsById = get('productsById')
          products.forEach((product) => {
            const stateProduct = stateProductsById[product.id]
            if (!stateProduct) {
              return
            }
            for (const key of Object.keys(product)) {
              stateProduct[key] = clone(product[key])
            }
          })
        },
        updateVariants(_state, variants) {
          const stateVariantsById = get('variantsById')
          variants.forEach((variant) => {
            const stateVariant = stateVariantsById[variant.id]
            if (!stateVariant) {
              return
            }
            for (const key of Object.keys(variant)) {
              stateVariant[key] = clone(variant[key])
            }
          })
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const response = await storeApi.products('marketplace')
            const products = filterProducts(store, response.data)
            set('products', products)
          } finally {
            set('stopLoading')
          }
        },
        async refreshInventory(_context, { productIds }) {
          const response = await storeApi.inventory('marketplace', { productIds })
          const products = filterProducts(store, response)
          set('updateProducts', products)
        },
      }),
    }
  )
}
