module.exports = {
  screens: {
    '3xs': '320px',
    '2xs': '375px',
    xs: '512px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1440px',
  },

  fontFamily: {
    heading: ['recoleta', 'serif'],
    body: ['silka', 'sans-serif'],
    numeric: ['silka', 'sans-serif'],
  },

  fontWeight: {
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
  },

  colors: {
    current: 'currentColor',
    transparent: 'transparent',
    white: '#FEFDFB',
    black: '#002823', // aka Dusk
    'true-black': '#000000',
    gray: {
      opacity70: '#002823B3',
      opacity68: '#002823AD',
      opacity42: '#0028236B',
      opacity20: '#00282333',
      opacity12: '#0028231F',
      opacity10: '#0028231A',
      opacity5: '#3030300D',
      opacity4: '#0028230A',
      active: '#00282326', // 15% opacity
      hover: '#0028230D', // 5% opacity
      lightest: '#f4f4f4',
      lighter: '#e5e5e5',
      light: '#c4c4c4',
      default: '#303030AD', // 68% opacity
      dark: '#838383', // TODO Benoit to Grace: this color is actually lighter than the `default` gray (#303030AD) on a white background, which results in #737373.
      darker: '#6a6a6a',
      darkest: '#333333', // TODO Benoit to Grace: isn't this too close to `black`? I'm not sure the difference is noticeable by anyone.
    },
    primary: {
      highlight: '#6CACAA',
      bright: '#236958', // hover
      light: '#627A73', // focus
      default: '#325A50', // aka earth
      dark: '#2B443D', // active
    },
    'primary-alt': {
      bright: '#00784D', // hover
      default: '#306D57',
      dark: '#006641', // active
      darkest: '#003326', // focus border
    },
    secondary: {
      active: '#647C1D26', // 15% opacity
      hover: '#647C1D0D', // 5% opacity
      lightest: '#f1f5e1',
      lighter: '#c6d68b',
      light: '#D0D9B4',
      default: '#889C4E',
      dark: '#647C1D',
      darker: '#526614',
      darkest: '#475912',
    },
    warning: {
      light: '#facc82',
      default: '#f4b857',
      darker: '#bd8e44',
    },
    red: '#C94F3B',
    green: {
      active: '#24544D26', // 15% opacity
      hover: '#306F660D', // 5% opacity
      highlight: '#389e9e',
      light: '#9EC7C7',
      default: '#306F66',
      dark: '#24544D',
      darker: '#1e463d',
      darkest: '#0f2621',
    },
    checkbox: {
      light: '#4c4c4c',
      dark: '#252525',
      darker: '#191919',
    },
    dawn: {
      default: '#F4EBD6',
      opacity42: '#F4EBD66B',
      lt1: '#F7EFDF',
      lt2: '#F9F3E7',
      lt3: '#FBF7EF',
      lt4: '#FDFBF7',
      dk1: '#E7DECC',
      dk2: '#DDD5C4',
      dk3: '#D3CCBC',
      dk4: '#C9C3B4',
    },
    sunshine: {
      bright: '#FFFC52',
      light: '#FDFD3B',
      default: '#FFFF7D',
      dark: '#FFF652',
    },
    spring: {
      default: '#CDAACD',
    },
    summer: {
      bright: '#E16944', // hover
      default: '#E18264',
      dark: '#D66440', // active
      darkest: '#66301F', // focus border
    },
    fall: {
      default: '#EAB96D',
    },
    winter: {
      default: '#96A0CD',
    },
    paypal: {
      gold: '#FFC439',
      gray: '#2C23E2F',
    },
  },
}
