import api, { METHOD_GET, METHOD_POST, METHOD_PUT } from '@/api'

export default api.module('billing-info', {
  createBillingInfo: {
    method: METHOD_POST,
    path: '',
  },
  updateBillingInfo: {
    method: METHOD_PUT,
    path: '',
  },
  fetchBillingInfo: {
    method: METHOD_GET,
    path: '',
  },
})
