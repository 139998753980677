import api, { METHOD_GET, METHOD_POST, METHOD_PUT, METHOD_DELETE } from '@/api'

export default api.module('addresses', {
  createAddress: {
    method: METHOD_POST,
    path: '',
  },
  updateAddress: {
    method: METHOD_PUT,
    path: '',
  },
  fetchAllAddresses: {
    method: METHOD_GET,
    path: '',
  },
  fetchAddressById: {
    method: METHOD_GET,
    path: (addressId) => addressId,
  },
  deleteAddressById: {
    method: METHOD_DELETE,
    path: (addressId) => addressId,
  },
})
