import { datadogRum } from '@datadog/browser-rum'
import env from '@/helpers/env'

export const METHOD_GET = 'GET'
export const METHOD_POST = 'POST'
export const METHOD_PUT = 'PUT'
export const METHOD_DELETE = 'DELETE'

const api = {}

export class ApiError extends Error {
  constructor(statusCode, data) {
    super(data?.message)
    this.name = 'ApiError'
    this.statusCode = statusCode
    this.data = data

    // Send a custom error with context
    datadogRum.addError(this, {
      message: data?.message,
      statusCode: statusCode,
      data: data,
    })
  }
}

api.send = async (method, path, data) => {
  let url = `${env.get('API_URL').replace(/\/$/, '')}/${path}`
  const options = {
    method: method,
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  }
  if (data) {
    if (method === METHOD_GET) {
      url += '?' + new URLSearchParams(data).toString()
    } else {
      Object.assign(options.headers, {
        'Content-Type': 'application/json',
      })
      options.body = JSON.stringify(data)
    }
  }
  const response = await window.fetch(url, options)
  const responseData = await response.json()
  if (response.status !== 200) {
    return Promise.reject(new ApiError(response.status, responseData))
  }
  return responseData
}

const createMethodFunction = (method) => {
  return (path, data) => {
    return api.send(method, path, data)
  }
}

api.get = createMethodFunction(METHOD_GET)
api.post = createMethodFunction(METHOD_POST)
api.put = createMethodFunction(METHOD_PUT)
api.delete = createMethodFunction(METHOD_DELETE)

api.module = (path, endpoints) => {
  const modulePath = path
  return Object.fromEntries(
    Object.entries(endpoints).map(([functionName, endpoint]) => {
      return [
        functionName,
        function () {
          const pathIsClosure = typeof endpoint.path === 'function'
          const pathParamCount = pathIsClosure ? endpoint.path.length : 0
          const passedArguments = [...arguments]
          const pathParams = pathIsClosure ? passedArguments.slice(0, pathParamCount) : []
          const path = pathIsClosure ? endpoint.path(...pathParams) : endpoint.path
          const completePath = modulePath + (path ? '/' + path : '')
          const data =
            passedArguments.length > pathParams.length
              ? passedArguments[pathParams.length]
              : undefined
          return api.send(endpoint.method, completePath, data)
        },
      ]
    })
  )
}

export default api
