import createModule from '@/store/createModule'
import { DIRECTION_AUTO } from '@/router'

export const AREA_UNDEFINED = undefined
export const AREA_SUBSCRIBE = 'subscribe'
export const AREA_ACCOUNT = 'account'
export const AREA_ACCOUNT_PROFILE = 'accountProfile'
export const AREA_MARKETPLACE = 'marketplace'
export const AREA_ESSENTIALS = 'essentials'
export const AREA_CHECKOUT = 'checkout'
export const AREA_CART = 'cart'
export const AREA_HOME = 'home'
export const AREA_CUSTOMIZE = 'customize'

export default (store) => {
  createModule(
    store,
    'general',
    {
      navigating: false,
      navigationRoute: null,
      navigationDirection: DIRECTION_AUTO,
      navigationLoading: false,
      preventNavigation: false,
      openModalCount: 0,
    },
    {
      getters: ({ get }) => ({
        area() {
          const routeName = store.get('route')?.name ?? ''
          if (routeName.startsWith('Subscribe')) {
            return AREA_SUBSCRIBE
          }
          if (routeName.startsWith('AccountProfile')) {
            return AREA_ACCOUNT_PROFILE
          }
          if (routeName.startsWith('Account')) {
            return AREA_ACCOUNT
          }
          if (routeName.startsWith('Market')) {
            return AREA_MARKETPLACE
          }
          if (routeName.startsWith('Essentials')) {
            return AREA_ESSENTIALS
          }
          // marketplaceCart, choicePlusCart, essentialsCart
          if (routeName.endsWith('Cart')) {
            return AREA_CART
          }
          if (routeName.startsWith('Checkout')) {
            return AREA_CHECKOUT
          }
          if (routeName.startsWith('Home')) {
            return AREA_HOME
          }
          if (routeName.startsWith('Customize')) {
            return AREA_CUSTOMIZE
          }
          return AREA_UNDEFINED
        },
        scrollLocked() {
          return get('openModalCount') > 0
        },
        isUnsubscribeAction() {
          const isUnsubscribeAction = store.get('route').params.clickedAction === 'unsubscribe'
          return store.get('route').name === 'AccountSubscriptionEdit' && isUnsubscribeAction
        },
        isReactivateAction() {
          const isReactivateAction = store.get('route').params.clickedAction === 'reactivate'
          return store.get('route').name === 'AccountSubscriptionEdit' && isReactivateAction
        },
      }),

      mutations: () => ({
        increaseOpenModalCount(state) {
          state.openModalCount++
        },
        decreaseOpenModalCount(state) {
          state.openModalCount--
        },
      }),

      actions: ({ get, set }) => ({}),
    }
  )
}
