/**
 * Creates an object composed of the picked object properties.
 * The name of the function is taken from Lodash: https://lodash.com/docs/4.17.15#pick
 */

export default (object, keys) => {
  return keys.reduce((reduced, key) => {
    reduced[key] = object[key]
    return reduced
  }, {})
}
