import createCartModule from '@/store/createCartModule'

export default (store) => {
  createCartModule(
    store,
    'marketplaceCart',
    {
      storeType: 'marketplace',
    },
    {
      getters: ({ get }) => ({
        remainingAmountForFreeShipping() {
          const minTotalForFreeShipping = store.get('account/minTotalForFreeShipping')
          if (!minTotalForFreeShipping) {
            return undefined
          }

          if (get('isCartEmpty')) {
            return minTotalForFreeShipping
          }

          if (get('cartPreview')?.shipping === 0) {
            return 0
          }

          return Math.max(
            0,
            Math.min(minTotalForFreeShipping, minTotalForFreeShipping - get('subtotal'))
          )
        },
        remainingForFreeShippingCalculated() {
          return get('remainingAmountForFreeShipping') !== undefined
        },
        hasFreeShipping() {
          return get('remainingAmountForFreeShipping') === 0
        },
        shippingAmount() {
          return get('hasFreeShipping') ? 0 : store.get('account/baseShipping')
        },
      }),
      actions: (bag) => ({
        afterSuccessfulReviewOrder(_context) {
          const { baseShipping, minTotalForFreeShipping } = bag.get('cartPreview')
          store.set('account/baseShipping', baseShipping)
          store.set('account/minTotalForFreeShipping', minTotalForFreeShipping)
        },
      }),
    }
  )
}
