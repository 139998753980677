/**
 * Exports a reactive object with the number of ellapsed `seconds`, `minutes`, and `hours` since the page load. Also includes the properties `initialTime` and `currentTime` (in miliseconds, but updates every second).
 */

import Vue from 'vue'

const initialTime = Date.now()

const reactiveTimeElapsed = Vue.observable({
  initialTime: initialTime,
  currentTime: initialTime,
  seconds: 0,
  minutes: 0,
  hours: 0,
})

setInterval(() => {
  reactiveTimeElapsed.currentTime = Date.now()
  const milisecondsElapsed = reactiveTimeElapsed.currentTime - initialTime
  const secondsElapsed = Math.floor(milisecondsElapsed / 1000)
  const minutesElapsed = Math.floor(secondsElapsed / 60)
  const hoursElapsed = Math.floor(minutesElapsed / 60)
  if (reactiveTimeElapsed.seconds < secondsElapsed) {
    reactiveTimeElapsed.seconds = secondsElapsed
  }
  if (reactiveTimeElapsed.minutes < minutesElapsed) {
    reactiveTimeElapsed.minutes = minutesElapsed
  }
  if (reactiveTimeElapsed.hours < hoursElapsed) {
    reactiveTimeElapsed.hours = hoursElapsed
  }
}, 1000)

export default reactiveTimeElapsed
