<script>
  import { get, sync, call } from 'vuex-pathify'
  import Headroom from 'headroom.js'
  import dayjs from 'dayjs'
  import {
    AREA_SUBSCRIBE,
    AREA_ESSENTIALS,
    AREA_CART,
    AREA_CHECKOUT,
    AREA_CUSTOMIZE,
    AREA_MARKETPLACE,
    AREA_HOME,
  } from '@/store/general'
  import screen from '@/helpers/screen'
  import LogoAlltrue from '@/images/logos/LogoAlltrue.svg'

  /**
   * Global header used throughout the application.
   *
   */
  export default {
    components: {
      LogoAlltrue,
    },
    data() {
      return {
        mobileNavOpen: false,
        avatarMenuOpen: false,
        addedToCartTimeout: null,
        closeAvatarMenuTimeout: null,
        headroom: null,
        headerRef: null,
        headerHeight: 0,
        headerContentRef: null,
        htmlSerializer: null,
      }
    },
    computed: {
      showPromoBarMessage() {
        return this.$route.path.includes('/subscribe')
      },
      headerHeightClass() {
        return `height: ${this.headerHeight}px`
      },
      ...get('prismic', ['promoBannerData']),
      promoBannerContent() {
        return this.promoBannerData || ''
      },
      ...get('general', ['area', 'scrollLocked', 'isUnsubscribeAction', 'isReactivateAction']),
      ...get('account', [
        'avatarUrl',
        'formattedCreatedDate',
        'fullName',
        'loggedIn',
        'minTotalForFreeShipping',
      ]),
      ...get('config', ['marketplaceEnabled', 'isCurrentBoxWaitlist', 'marketplaceEndDate']),
      ...get('feature', ['flags']),
      ...get('marketplaceCart', ['subtotal', 'remainingAmountForFreeShipping', 'cartQuantity']),
      ...sync('marketplaceCart', ['addedToCart']),
      ...get('essentialsCart', { essentialsCartQuantity: 'cartQuantity' }),
      ...sync('essentialsCart', { essentialsAddedToCart: 'addedToCart' }),
      ...get('marketplace', ['isPDPView']),
      ...get('prismic', ['getNavigationItemText']),
      screen,
      marketLabel() {
        return this.getNavigationItemText('seasonal-market-title') ?? 'Add-On Market'
      },
      marketStatusLabel() {
        return this.getNavigationItemText('seasonal-market-status') ?? 'Live'
      },
      isCustomizationEnabled() {
        return !(this.flags['kill-customization'] ?? false)
      },
      isEssentialsShopEnabled() {
        return !this.marketplaceEnabled && (this.flags['essentials-shop-enabled'] ?? false)
      },
      isInSubscribeFlow() {
        return [AREA_SUBSCRIBE].includes(this.area) || this.$route.params.subscribe
      },
      logoIsClickable() {
        return this.loggedIn && !this.isInSubscribeFlow
      },
      logoIsCentered() {
        return this.isInSubscribeFlow && !this.screen.lg
      },
      isMinimal() {
        // Show a minimal header in certain areas to dissuade users from navigating away
        return [AREA_CART, AREA_CHECKOUT].includes(this.area) || this.isInSubscribeFlow
      },
      marketCart() {
        return this.marketplaceEnabled ? { name: 'MarketplaceCart' } : { name: 'EssentialsCart' }
      },
      marketCartQuantity() {
        return this.marketplaceEnabled ? this.cartQuantity : this.essentialsCartQuantity
      },
      marketAddedToCart: {
        get() {
          return this.marketplaceEnabled ? this.addedToCart : this.essentialsAddedToCart
        },
        set(value) {
          if (this.marketplaceEnabled) {
            this.addedToCart = value
          } else {
            this.essentialsAddedToCart = value
          }
        },
      },
      navItems() {
        if (this.isMinimal) {
          return []
        }

        // all of these props are bound to BaseLink
        if (!this.loggedIn) {
          return [
            {
              label: 'About',
              href: 'https://alltrue.com/about',
            },
            {
              label: 'Login',
              to: { name: 'AccountLogin' },
            },
            {
              label: 'Get Started',
              to: { name: 'SubscribePlan' },
              style: 'button',
            },
          ]
        }

        const navItems = []

        if (this.isCustomizationEnabled) {
          navItems.push({
            label: 'My Box',
            to: { name: 'CustomizeStart' },
          })
        }

        if (this.isEssentialsShopEnabled) {
          navItems.push({
            label: 'Essentials Shop',
            to: { name: 'EssentialsHome' },
          })
        }

        if (this.marketplaceEnabled) {
          navItems.push({
            label: `${this.marketLabel}`,
            to: { name: 'Market' },
          })
        }

        return navItems
      },
      avatarDropdownItems() {
        if (this.isMinimal || !this.loggedIn) {
          return []
        }
        const navItems = [
          {
            label: 'Account details',
            to: { name: 'AccountProfileSettings' },
            showOnDesktop: false,
          },
          {
            label: 'Settings',
            to: { name: 'AccountProfileSettings' },
            showOnMobile: false,
          },
          {
            label: 'Home',
            to: { name: 'Home' },
            showOnDesktop: false,
          },
          {
            label: 'Memberships',
            to: { name: 'AccountProfileSubscriptions' },
          },
          {
            label: 'Orders',
            to: { name: 'AccountProfileOrders' },
          },
          {
            label: 'Give a Gift Card',
            href: 'https://alltrue.com/gift',
          },
          {
            label: 'Credits',
            to: { name: 'AccountProfileSettings', params: { section: 'credits' } },
          },
          {
            label: 'Support',
            href: 'https://alltrue.com/contact-us',
          },
          {
            label: 'Log Out',
          },
        ]

        if (this.isCustomizationEnabled) {
          navItems.splice(3, 0, {
            label: 'My Box',
            to: { name: 'CustomizeStart' },
            showOnDesktop: false,
          })
        }

        if (this.marketplaceEnabled) {
          navItems.splice(4, 0, {
            label: `${this.marketLabel}`,
            to: { name: 'Market' },
            showOnDesktop: false,
          })
        }

        if (this.isEssentialsShopEnabled) {
          navItems.splice(4, 0, {
            label: 'Essentials Shop',
            to: { name: 'EssentialsHome' },
            showOnDesktop: false,
          })
        }
        return navItems
      },
      desktopNavItems() {
        return this.navItems.filter((navItem) => navItem.showOnDesktop ?? true)
      },
      desktopNavItemsAreCentered() {
        return this.showCart || this.showAvatar
      },
      desktopAvatarDropdownItems() {
        return this.avatarDropdownItems.filter((item) => item.showOnDesktop ?? true)
      },
      mobileNavItems() {
        const navItems = this.loggedIn ? this.avatarDropdownItems : this.navItems
        return navItems.filter((item) => item.showOnMobile ?? true)
      },
      hasMobileNav() {
        return this.mobileNavItems.length > 0
      },
      isCustomizeAreaExcludingStart() {
        return this.area === AREA_CUSTOMIZE && this.$route.name !== 'CustomizeStart'
      },
      showCart() {
        return (
          !this.isMinimal &&
          this.loggedIn &&
          (this.isEssentialsShopEnabled || this.marketplaceEnabled) &&
          !this.isCustomizeAreaExcludingStart // hide cart in customize screens except 'My Box'/start screen
        )
      },
      hasFixedNav() {
        return (
          this.area === AREA_HOME ||
          (this.area === AREA_ESSENTIALS && !this.screen.md) ||
          this.$route.name?.includes('Product') ||
          this.$route.name?.includes('Details') ||
          this.area === AREA_MARKETPLACE ||
          this.scrollLocked
        )
      },
      showAddedToCart() {
        const currentArea = this.marketplaceEnabled ? AREA_MARKETPLACE : AREA_ESSENTIALS
        return this.marketAddedToCart && this.area === currentArea
      },
      showAvatar() {
        return this.avatarDropdownItems.length > 0
      },
      avatarIconMenuListeners() {
        return this.screen.sm
          ? {
            mouseenter: this.openAvatarMenu,
            mouseleave: this.delayedCloseAvatarMenu,
            click: this.openProfileSettings,
            blur: this.delayedCloseAvatarMenu,
            focus: this.openAvatarMenu,
            keyup: this.handleAvatarKeyUp,
          }
          : {}
      },
      isInWaitlistSubscribeFlow() {
        return (
          this.isCurrentBoxWaitlist &&
          (this.$route.name === 'SubscribePlan' || this.$route.name === 'SubscribeSignup')
        )
      },
      waitlistHeaderCopy() {
        if (this.$route.name === 'SubscribePlan') {
          return `<span>GET ON THE LIST &#8729; CLAIM YOUR BOX</span>`
        }
        return `<span>JOIN TODAY &#8729; LIMITED BOXES AVAILABLE</span>`
      },
      showMarketLiveInSubscribeFlow() {
        const routes = [
          'SubscribeInsiders',
          'SubscribeBonuses',
          'SubscribeWelcome',
          'CustomizeChoice',
          'CustomizeSummary',
        ]
        return (
          this.marketplaceEnabled &&
          routes.includes(this.$route.name) &&
          this.$route.path.includes('subscribe') &&
          this.screen.lg
        )
      },
      formattedMarketplaceEndDate() {
        return dayjs(this.marketplaceEndDate).format('M/DD')
      },
    },
    watch: {
      '$route.path'() {
        // Close both the mobile nav and the avatar menu when changing route
        this.mobileNavOpen = false
        this.avatarMenuOpen = false
        this.dismissAddedToCart()
      },
      hasFixedNav() {
        if (this.hasFixedNav) {
          this.headroom.pin()
          this.headroom.freeze()
        } else {
          this.headroom.unfreeze()
        }
      },
      'screen.sm'() {
        // Close the mobile nav if it's open when resizing to desktop view
        if (this.screen.sm && this.mobileNavOpen) {
          this.mobileNavOpen = false
        }
        // Close the avatar menu if it's open when resizing to mobile view
        if (!this.screen.sm && this.avatarMenuOpen) {
          this.avatarMenuOpen = false
        }
      },
      mobileNavOpen() {
        this.refreshHeadroom()
      },
      avatarMenuOpen() {
        this.refreshHeadroom()
      },
      marketAddedToCart: {
        immediate: true,
        handler(value, oldValue) {
          if (this.marketAddedToCart) {
            clearTimeout(this.addedToCartTimeout)
            this.addedToCartTimeout = setTimeout(() => {
              this.marketAddedToCart = false
            }, 5000)
          }
          // This will only run when the value changes, not on the "immediate" call
          if (oldValue !== undefined) {
            this.refreshHeadroom()
          }
        },
      },
      isPDPView() {
        this.refreshHeadroom()
      },
    },
    mounted() {
      this.headerRefWrapper = this.$refs.headerContentRef
      this.updateHeaderHeight()
      this.resizeObserver = new ResizeObserver(() => {
        this.updateHeaderHeight()
      })
      this.resizeObserver.observe(this.headerContentRef)
      this.headroom = new Headroom(this.headerRef, {
        tolerance: {
          down: 10,
          up: 20,
        },
      })
      this.headroom.init()
      // Close the mobile nav on navigation failure (this happens when clicking on a nav item that is already active)
      const closeMobileNav = () => {
        this.mobileNavOpen = false
      }
      this.$root.$on('navigationFailure', closeMobileNav)
      this.$once('hook:beforeDestroy', () => {
        this.$root.$off('navigationFailure', closeMobileNav)
      })
    },
    beforeDestroy() {
      this.headroom.destroy()
      this.resizeObserver.disconnect()
    },
    created() {
      this.loadNavigationData()
      this.loadPromoBannerData()
    },
    methods: {
      updateHeaderHeight() {
        this.headerHeight = this.headerContentRef.offsetHeight
      },
      ...call('prismic', ['loadPromoBannerData']),
      ...call('prismic', ['loadNavigationData']),
      toggleAvatarMenu() {
        this.avatarMenuOpen = !this.avatarMenuOpen
        this.marketAddedToCart = false
      },
      openAvatarMenu() {
        if (this.avatarMenuOpen) {
          this.keepAvatarMenuOpen()
        } else {
          this.toggleAvatarMenu()
        }
      },
      closeAvatarMenu() {
        this.avatarMenuOpen = false
        this.marketAddedToCart = false
      },
      delayedCloseAvatarMenu() {
        this.closeAvatarMenuTimeout = window.setTimeout(() => {
          this.avatarMenuOpen = false
        }, 500)

        this.marketAddedToCart = false
      },
      keepAvatarMenuOpen() {
        clearTimeout(this.closeAvatarMenuTimeout)
      },
      handleAvatarKeyUp() {
        this.$refs.avatarMenuRef.focus()
        this.keepAvatarMenuOpen()
      },
      handleAvatarMenuItemBlur() {
        if (this.$refs.homeButtonRef) {
          this.$refs.homeButtonRef.$el.focus()
        }
        this.closeAvatarMenu()
      },
      dismissAddedToCart() {
        this.marketAddedToCart = false
      },
      closePDP() {
        if (this.$route.name === 'CartMarketProduct') {
          this.$navigate({ name: 'MarketplaceCart' })
        } else {
          this.$navigate(this.$store.get('marketplace/pathWithFilters'))
        }
      },
      openProfileSettings() {
        this.$navigate({ name: 'AccountProfileSettings' })
      },
      refreshHeadroom() {
        if (
          this.mobileNavOpen ||
          this.avatarMenuOpen ||
          this.marketAddedToCart ||
          this.isPDPView ||
          this.scrollLocked
        ) {
          this.headroom.pin()
          this.headroom.freeze()
        }
      },
    },
  }
</script>

<template>
  <div
    v-if="!isUnsubscribeAction && !isReactivateAction"
    class="z-70 h-full bg-dawn text-gray-darkest font-medium text-base overflow-x-hidden"
    :style="headerHeightClass"
  >
    <header v-ref="headerRef" class="z-50 fixed inset-x-0 top-0">
      <div v-ref="headerContentRef" class="z-10 bg-dawn border-b-2 border-black">
        <div
          v-if="promoBannerContent && showPromoBarMessage"
          id="promoBarMessage"
          class="bg-primary text-dawn py-6px md:py-14px px-24px text-center text-13px md:text-18px"
        >
          <PrismicRichText
            :field="promoBannerContent.promo_message"
            :html-serializer="htmlSerializer"
          />
        </div>
        <BaseContainer max-width="2xl">
          <div :class="`h-17 flex ${logoIsCentered ? 'justify-center' : 'justify-between'}`">
            <div
              :class="`flex ${
                !screen.lg && isInWaitlistSubscribeFlow ? 'flex-col justify-center' : ''
              } items-center`"
            >
              <!-- Back to PLP view arrow -->
              <div v-if="isPDPView && !screen.md" class="-ml-2 mr-2 2xs:mr-4">
                <BaseIconClickable
                  label="Back to products"
                  color="black"
                  data-cy="back-to-products"
                  @click="closePDP"
                >
                  <IconArrowLeft />
                </BaseIconClickable>
              </div>
              <!-- Alltrue logo -->
              <PortalTarget name="headerLogo" transition="BaseTransitionFade" slim>
                <component
                  :is="logoIsClickable ? 'BaseLink' : 'div'"
                  :to="logoIsClickable ? { name: 'Home' } : undefined"
                  class="-mx-2 flex justify-center items-center flex-shrink-0 px-2"
                >
                  <LogoAlltrue class="w-22 h-5" />
                </component>
              </PortalTarget>
              <!-- Mobile waitlist header copy -->
              <div
                v-if="!screen.lg && isInWaitlistSubscribeFlow"
                class="font-semibold text-3xs mt-7px"
              >
                <span v-html="waitlistHeaderCopy" />
              </div>
            </div>
            <!-- Desktop nav -->
            <!-- Desktop waitlist header copy -->
            <div
              v-if="screen.lg && isInWaitlistSubscribeFlow"
              class="flex items-center justify-center pr-22 mx-auto font-semibold"
            >
              <span v-html="waitlistHeaderCopy" />
            </div>

            <div v-if="$route.name === 'SubscribePlan' && screen.lg" class="flex items-center">
              <a
                class="max-h-11 flex items-center border border-black p-2 rounded-sm"
                href="https://alltrue.com/gift"
              >
                <div class="bg-black h-7 flex items-center px-1" style="border-radius: 3px">
                  <BaseIcon class="text-dawn">
                    <LogoAlltrue class="w-8" />
                  </BaseIcon>
                </div>
                <div class="font-bold text-2xs ml-2 mr-6px leading-close">
                  Gifting? Learn more here
                </div>
                <BaseIcon :size="3" class="text-black">
                  <IconChevronRightThick />
                </BaseIcon>
              </a>
            </div>

            <div v-if="showMarketLiveInSubscribeFlow" class="flex items-center">
              <div
                class="max-h-11 flex items-center border border-summer p-2 rounded-sm"
                style="border-radius: 4px"
              >
                <div
                  class="uppercase bg-summer h-22px flex items-center px-2 font-bold text-4xs text-dawn"
                  style="border-radius: 3px"
                >
                  {{ marketStatusLabel }}
                </div>
                <div class="font-bold text-2xs ml-2 mr-6px leading-close uppercase">
                  {{ marketLabel }} &#8729; SHOP UNTIL
                  {{ formattedMarketplaceEndDate }}
                </div>
              </div>
            </div>

            <nav
              v-else-if="!isMinimal"
              :class="`${!desktopNavItemsAreCentered ? 'ml-auto' : ''} flex`"
            >
              <PortalTarget name="headerNav" transition="BaseTransitionFade" slim>
                <ul v-if="desktopNavItems.length > 0" class="flex">
                  <li
                    v-for="(item, itemIndex) in desktopNavItems"
                    :key="itemIndex"
                    :class="`${hasMobileNav ? 'hidden sm:flex' : 'flex'} ${
                      itemIndex > 0 ? 'ml-4' : ''
                    }`"
                  >
                    <BaseLink v-bind="item" class="flex px-2 mt-1">
                      <span
                        :class="`flex justify-center items-center ${
                          $route.path.includes(item.to) && !$route.path.includes('cart')
                            ? 'font-semibold'
                            : 'font-normal'
                        } transition duration-200`"
                      >
                        <span
                          :class="`flex justify-center items-center hover:border-black border-b ${
                            item.style === 'button'
                              ? 'px-5 py-3 bg-primary group-hover:bg-primary-bright group-active:bg-primary-dark text-white transition duration-200'
                              : ''
                          }`"
                        >
                          <span
                            v-if="item.label === marketLabel && marketplaceEnabled"
                            class="market-live"
                          >
                            {{ marketStatusLabel }}
                          </span>
                          {{ item.label }}
                        </span>
                      </span>
                    </BaseLink>
                  </li>
                </ul>
              </PortalTarget>

              <!-- Cart icon -->
              <div class="flex">
                <div v-if="showCart" class="flex">
                  <BaseLink :to="marketCart" class="nav-icon" data-cy="cart-link">
                    <div class="border-b hover:border-black">
                      <BaseIcon label="cart" :size="7">
                        <IconCart />
                        <div v-if="marketCartQuantity > 0" class="cart-qty" data-cy="cart-quantity">
                          <span class="mt-1px">{{ marketCartQuantity }}</span>
                        </div>
                      </BaseIcon>
                    </div>
                  </BaseLink>
                </div>

                <!-- Avatar icon (link on mobile, click for dropdown on desktop) -->
                <component
                  :is="screen.sm ? 'button' : 'BaseLink'"
                  v-if="showAvatar"
                  :type="screen.sm ? 'button' : undefined"
                  :to="screen.sm ? undefined : { name: 'AccountProfileSettings' }"
                  class="flex justify-center items-center pr-2 md:pr-0 pl-2 cursor-pointer"
                  v-on="avatarIconMenuListeners"
                >
                  <div
                    class="bg-dawn-lt3 rounded-1/2 md:mr-1"
                    :style="{ width: $pxToRem(30), height: $pxToRem(30) }"
                  >
                    <div class="absolute w-full">
                      <BaseImage
                        :src="avatarUrl"
                        alt="Avatar"
                        rounded
                        background-transparent
                        responsive="xs"
                        sizes="128px"
                      />
                    </div>
                  </div>
                  <BaseIcon
                    v-if="screen.sm"
                    :label="`${avatarMenuOpen ? 'Close' : 'Open'} account menu`"
                    :size="4"
                  >
                    <IconChevronDown />
                  </BaseIcon>
                </component>

                <BaseLink
                  v-if="screen.md"
                  ref="homeButtonRef"
                  :to="{ name: 'Home' }"
                  class="flex px-4"
                >
                  <span class="flex justify-center items-center">
                    <BaseIcon label="Home" :size="4">
                      <IconHome />
                    </BaseIcon>
                  </span>
                </BaseLink>

                <!-- Hamburger/X button on mobile -->
                <div v-if="hasMobileNav" class="pl-2 flex sm:hidden">
                  <button type="button" class="cart" @click="mobileNavOpen = !mobileNavOpen">
                    <BaseIcon
                      :label="mobileNavOpen ? 'Close menu' : 'Open menu'"
                      :size="6"
                      class="flex justify-center items-center"
                    >
                      <IconHamburger v-if="!mobileNavOpen" class="w-6 h-6" />
                      <IconXThick v-else class="w-5 h-5" />
                    </BaseIcon>
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </BaseContainer>
      </div>
    </header>

    <!-- Mobile nav items -->
    <transition name="mobile-nav">
      <nav
        v-if="mobileNavOpen"
        class="mt-17 h-full fixed overflow-y-auto inset-x-0 pb-24 sm:hidden border-b border-gray-active bg-dawn text-lg font-normal"
      >
        <ul class="flex flex-col">
          <li v-for="(item, itemIndex) in mobileNavItems" :key="itemIndex">
            <BaseLink
              v-if="item.label !== 'Log Out'"
              :to="item.to ? item.to : undefined"
              :href="item.href ? item.href : undefined"
              class="flex items-center px-6 py-4 hover:bg-black hover:bg-opacity-10"
            >
              <div v-if="item.label !== 'Account details'" class="flex items-center">
                {{ item.label }}
                <span
                  v-if="item.label === marketLabel && marketplaceEnabled"
                  class="market-live ml-2"
                >
                  {{ marketStatusLabel }}
                </span>
              </div>
              <div v-else>
                <div class="font-heading font-normal text-xxl">
                  {{ fullName }}
                </div>
                <div class="text-xs font-normal"> Member since {{ formattedCreatedDate }} </div>
                <BaseLinkStyled class="text-sm" color="black"> View Account </BaseLinkStyled>
              </div>
            </BaseLink>
            <BaseLink
              v-else
              class="flex items-center px-6 py-4 hover:bg-black hover:bg-opacity-10"
              @click="$navigate({ name: 'AccountLogout' })"
            >
              {{ item.label }}
            </BaseLink>
            <BaseDivider v-if="itemIndex !== mobileNavItems.length - 1" class="ml-6" />
          </li>
        </ul>
      </nav>
    </transition>

    <!-- Added to cart notification - show only in market view -->
    <div class="fixed top-0 inset-x-0 mx-auto md:max-w-300">
      <transition name="mobile-nav">
        <!-- Added to cart notification -->
        <section
          v-if="showAddedToCart"
          class="absolute inset-x-0 top-0 mt-17 w-full md:ml-auto md:w-1/3 xl:-mr-5 bg-dawn text-xs font-medium"
        >
          <div
            class="w-full px-4 md:px-8 py-4 md:py-6 rounded-sm shadow-md focus:outline-none overflow-hidden"
          >
            <BaseIconClickable
              label="Close"
              class="absolute right-0 mr-3 top-0 mt-4 md:mt-3 z-10"
              @click="dismissAddedToCart"
            >
              <IconX />
            </BaseIconClickable>
            <div class="text-lg font-normal">Added to Cart!</div>
          </div>
        </section>

        <!-- Avatar dropdown items on desktop -->
        <section
          v-else-if="avatarMenuOpen && screen.sm"
          class="absolute inset-x-0 top-0 w-full mt-17 ml-auto xl:-mr-5 bg-dawn font-normal"
          style="width: 240px"
          v-on="
            screen.sm ? { mouseenter: keepAvatarMenuOpen, mouseleave: delayedCloseAvatarMenu } : {}
          "
        >
          <nav
            ref="avatarMenuRef"
            class="w-full px-12 pt-12 pb-8 rounded-sm shadow-md focus:outline-none overflow-hidden flex flex-col"
            tabindex="-1"
          >
            <ul role="listbox">
              <li
                v-for="(item, itemIndex) in desktopAvatarDropdownItems"
                :key="itemIndex"
                role="option"
              >
                <BaseLinkStyled
                  v-if="item.label !== 'Log Out'"
                  :to="item.to ? item.to : undefined"
                  :href="item.href ? item.href : undefined"
                  color="black"
                  inline
                  class="flex items-center mb-4"
                >
                  {{ item.label }}
                </BaseLinkStyled>
                <BaseLinkStyled
                  v-else
                  color="black"
                  inline
                  class="flex items-center mb-4"
                  @click="$navigate({ name: 'AccountLogout' })"
                  v-on="screen.sm ? { blur: handleAvatarMenuItemBlur } : {}"
                >
                  {{ item.label }}
                </BaseLinkStyled>
              </li>
            </ul>
          </nav>
        </section>
      </transition>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .headroom {
    @apply transform transition duration-200;

    &--pinned {
      @apply translate-y-0;
    }

    &--unpinned {
      @apply -translate-y-full;
    }
  }

  .nav-icon {
    @apply flex justify-center items-center px-3 pt-2px transition duration-200;
  }

  .market-live {
    @apply mr-2 px-6px pt-4px pb-1 bg-primary text-dawn-lt4 font-bold text-2xs leading-none uppercase tracking-wide;
  }

  .cart-qty {
    @apply absolute right-0 -mr-2 top-0 -mt-2px flex justify-center items-center min-w-4 h-4 px-1 rounded-full bg-summer text-dawn-lt4 text-4xs;
  }
  /* purgecss end ignore */

  .mobile-nav-overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }

  .mobile-nav {
    &-enter-active,
    &-leave-active {
      @apply transform transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply -translate-y-full;
    }
  }
</style>

<style lang="postcss">
  #promoBarMessage {
    font-weight: 400;

    strong {
      font-weight: 600;
    }

    a {
      text-decoration: underline;
    }
  }
</style>
