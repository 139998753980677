import api, { METHOD_GET, METHOD_POST } from '@/api'

export default api.module('features', {
  track: {
    method: METHOD_POST,
    path: 'track',
  },
  getFlags: {
    method: METHOD_GET,
    path: 'all',
  },
  observeFlag: {
    method: METHOD_GET,
    path: (key) => key,
  },
})
