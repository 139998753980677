/**
 * Exports a reactive object that includes a property for every Tailwind screen (e.g. `sm`) set to `true` or `false` depending on whether that screen is currently “active”.
 */

import Vue from 'vue'
import { screens } from '@/../tokens'

const mediaQueries = Object.fromEntries(
  Object.entries(screens).map(([screenKey, screenWidth]) => [
    screenKey,
    window.matchMedia(`(min-width: ${screenWidth})`),
  ])
)

const mediaQueryMatches = Object.fromEntries(
  Object.entries(mediaQueries).map(([screenKey, mediaQuery]) => [screenKey, mediaQuery.matches])
)

const reactiveMediaQueryMatches = Vue.observable(mediaQueryMatches)

Object.entries(mediaQueries).forEach(([screenKey, mediaQuery]) => {
  mediaQuery.addListener(() => {
    reactiveMediaQueryMatches[screenKey] = mediaQuery.matches
  })
})

export default () => reactiveMediaQueryMatches
export { mediaQueries }
