import createCartModule from '@/store/createCartModule'

export default (store) => {
  createCartModule(
    store,
    'essentialsCart',
    {
      storeType: 'essentials',
    },
    {
      actions: () => ({}),
    }
  )
}
