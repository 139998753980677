/* eslint-disable camelcase */
import loadjs from 'loadjs'
import env, { ENVIRONMENT_DEV, ENVIRONMENT_STAGING, ENVIRONMENT_PRODUCTION } from '@/helpers/env'

const dataLayer = (window.dataLayer = [])
dataLayer.push({
  event: 'gtm.js',
  'gtm.start': new Date().getTime(),
})

const logEvent = ({ eventType, eventProperties }) => {
  // We're going to use seasonal in analytics
  // TODO: remove once we've switched marketplace to seasonal elsewhere
  if (eventProperties && eventProperties.storeType && eventProperties.storeType === 'marketplace') {
    eventProperties.storeType = 'seasonal'
  }
  dataLayer.push({
    event: 'logEvent',
    eventType,
    eventProperties,
  })
}

// Format our lineItem to an item for analytics
const lineItemMap = (item) => ({
  name: item.product?.title,
  id: item.variant?.id,
  price: item.variant?.price,
  quantity: item?.quantity,
})

let email = null

let loadGTMPromise = null
export const loadGTM = async () => {
  if (loadGTMPromise === null) {
    const gtmUrls = {
      [ENVIRONMENT_DEV]:
        'https://www.googletagmanager.com/gtm.js?id=GTM-P3ZQQM&gtm_auth=6ApDbQ9euJhBRWi4u91Ngw&gtm_preview=env-46&gtm_cookies_win=x',
      [ENVIRONMENT_STAGING]:
        'https://www.googletagmanager.com/gtm.js?id=GTM-P3ZQQM&gtm_auth=qDTgd_oE75RROtuELiGLzA&gtm_preview=env-39&gtm_cookies_win=x',
      [ENVIRONMENT_PRODUCTION]:
        'https://www.googletagmanager.com/gtm.js?id=GTM-P3ZQQM&gtm_auth=vxQZZb9NIJ8jJGNTM20K7g&gtm_preview=env-5&gtm_cookies_win=x',
    }
    const gtmUrl = gtmUrls[env.get('ENVIRONMENT')]
    if (!gtmUrl || env.get('CI')) {
      return
    }
    loadGTMPromise = await loadjs(gtmUrl, {
      returnPromise: true,
    })
  }
  return loadGTMPromise
}

export const events = {
  setUser({ username }) {
    email = username
    dataLayer.push({
      event: 'setUser',
      email,
    })
  },

  // Generic event log
  log({ name, data }) {
    logEvent({
      eventType: name,
      eventProperties: data,
    })
  },

  // GTM trigger for the beginning of the subscription checkout funnel
  // This is relevant for FB and GA tracking
  startSubscriptionCheckoutFunnel({ email }) {
    dataLayer.push({
      event: 'Start_Checkout',
      email,
    })
  },

  // GTM trigger for selecting a subscription plan
  // This is relevant for FB and GA tracking
  selectedSubscriptionPlan({ email, planHandle }) {
    dataLayer.push({
      event: 'Add_To_Cart', // TODO: rename
      plan_name: planHandle,
      email,
    })
  },

  // TODO: integrate with GA
  addToCart({ storeType, product, variant, quantity }) {
    logEvent({
      eventType: 'product added to cart',
      eventProperties: {
        storeType,
        item: lineItemMap({ product, variant, quantity }),
      },
    })
  },

  // Tracks purchases
  // GA ecommerce docs:
  // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
  subscriptionOrder({ email, value, shipping, tax, order_id, order_quantity, source, items }) {
    const plan = items.find((item) => ['annually', 'quarterly'].includes(item.name))?.name

    // Purchase event
    // triggers:
    // - Amplitude revenue event
    // - Gogle ads conversion
    // - social media purchase events
    dataLayer.push({
      event: 'Purchase',
      plan_name: plan, // this is for the pinterest integration, which we should change
      email,
      value,
      order_id,
      order_quantity,
      source,
      items,
    })

    // Google Universal Analytics
    // triggers Transaction event
    dataLayer.push({
      event: 'GA_Transaction',
      // transactionAffiliation: '', // TODO: use to differentiate between markets
      transactionId: order_id,
      transactionTotal: value,
      transactionShipping: shipping,
      transactionTax: tax,
      transactionProducts: items,
    })
  },

  marketOrder({ storeType, total, lineItems }) {
    // triggers:
    // - Amplitude revenue event
    dataLayer.push({
      event: 'MarketOrder',
      value: total,
      items: lineItems.map(lineItemMap),
      source: storeType === 'marketplace' ? 'seasonal' : storeType,
    })
  },

  // TODO: add GA events
  viewedProduct({ product, variant, storeType }) {
    logEvent({
      eventType: 'viewed product detail',
      eventProperties: {
        storeType,
        item: lineItemMap({ product, variant, quantity: 1 }),
      },
    })
  },
}
