import createCartModule from '@/store/createCartModule'
import storeApi from '@/api/store'
import keyBy from '@/helpers/keyBy'

export default (store) => {
  createCartModule(
    store,
    'upsell',
    {
      storeType: 'upsell',
      upsellProducts: [],
    },
    {
      getters: ({ get }) => ({
        productsById() {
          return keyBy(get('upsellProducts'), ({ id }) => id)
        },
        firstTenProducts() {
          return get('upsellProducts')
            .filter(({ variants }) => variants[0].hasUnlimitedStock || variants[0].stock > 0)
            .slice(0, 10)
        },
        remainingAmountForFreeShipping() {
          const minTotalForFreeShipping = store.get('account/minTotalForFreeShipping')
          if (!minTotalForFreeShipping) {
            return undefined
          }

          if (get('cartPreview')?.shipping === 0) {
            return 0
          }

          return Math.max(
            0,
            Math.min(minTotalForFreeShipping, minTotalForFreeShipping - get('subtotal'))
          )
        },
        remainingForFreeShippingCalculated() {
          return get('remainingAmountForFreeShipping') !== undefined
        },
        hasFreeShipping() {
          return get('remainingAmountForFreeShipping') === 0
        },
      }),

      actions: ({ get, set }) => ({
        async fetchUpsellProducts() {
          const response = await storeApi.products('upsell')
          const products = response.data.filter(
            (product) =>
              product.defaultVariant.stock > 0 || product.defaultVariant.hasUnlimitedStock
          )
          set('upsellProducts', products)
        },
      }),
    }
  )
}
