import clone from 'clone-deep'
import dayjs from 'dayjs'
import createModule from '@/store/createModule'
import configApi from '@/api/config'
import timeElapsed from '@/helpers/timeElapsed'
import keyBy from '@/helpers/keyBy'
import screen from '@/helpers/screen'

export default (store) => {
  createModule(
    store,
    'config',
    {
      spaVersion: null,
      recurlyPublicKey: null,
      marketplaceEnabled: null,
      marketplaceEndDate: null,
      openBoxes: [],
      boxArchive: [],
      countries: [],
      trendingQuestions: [],
      warehouseUpdates: [],
    },
    {
      getters: ({ get }) => ({
        countries(state) {
          // Sort countries and states by name
          return [...state.countries]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((country) => ({
              ...country,
              states: [...country.states].sort((a, b) => a.name.localeCompare(b.name)),
            }))
        },
        currentBox() {
          return get('openBoxes').find((openBox) => openBox.isCurrentBox)
        },
        // only core boxes of each season, no intro/welcome/new boxes
        coreBoxes() {
          return get('openBoxes').filter(
            (box) =>
              !box.title.includes('Intro') &&
              !box.title.includes('Welcome') &&
              !box.title.includes('new') &&
              !box?.label?.includes('Intro') &&
              !box?.label?.includes('Welcome') &&
              !box?.label?.includes('new')
          )
        },
        // current season's core box
        currentSeasonCoreBox() {
          return get('coreBoxes').find(
            (box) => box.season === get('currentSeason') && box.year === get('currentYear')
          )
        },
        currentBoxIsCoreBox() {
          return get('currentSeasonCoreBox')?.id === get('currentBox')?.id
        },
        isBoxCoreBox() {
          return (box) => get('coreBoxes').includes(box)
        },
        boxesById() {
          return keyBy(get('openBoxes'), ({ id }) => id)
        },
        isCurrentBoxWaitlist() {
          const currentBox = get('currentBox')
          return currentBox?.title?.toLowerCase()?.includes('waitlist')
        },
        variantsById() {
          const variants = get('openBoxes').flatMap((box) =>
            box.products.flatMap((product) => product.variants)
          )

          return keyBy(variants, ({ id }) => id)
        },
        variantsByBoxId() {
          return (boxId) => {
            return boxId
              ? get('boxesById')[boxId].products.flatMap((product) => product.variants)
              : undefined
          }
        },
        currentSeason() {
          return get('currentBox')?.season
        },
        currentYear() {
          return get('currentBox')?.year
        },
        getFormattedCurrentBoxStartDate() {
          const choiceAnnualStartDate = get('currentBox')?.choiceAnnualStartDate
          if (choiceAnnualStartDate) {
            const formatted = choiceAnnualStartDate?.replace(/-/g, '/')
            const date = new Date(formatted)
            return `${date.getUTCMonth() + 1}/${date.getUTCDate()}`
          } else {
            return undefined
          }
        },
        marketplaceSecondsLeft() {
          const now = dayjs(timeElapsed.currentTime) // utc
          const end = dayjs(get('marketplaceEndDate')) // utc
          return end.diff(now, 'seconds')
        },
        // screen is below screen.lg or larger than screen.xl (used in cases such as displaying/hiding the home widget banner images)
        screenIsNotBetweenLgAndXl() {
          return !screen().lg || screen().xl
        },
        popUpMarketEnabled() {
          return store.get('feature/flags')['pop-up-market'] ?? false
        },
      }),

      mutations: ({ defaultState, markAsLoaded }) => ({
        config(state, config) {
          const keys = [
            'recurlyPublicKey',
            'marketplaceEnabled',
            'marketplaceEndDate',
            'openBoxes',
            'countries',
            'trendingQuestions',
            'warehouseUpdates',
          ]
          for (const key of keys) {
            state[key] = config ? config[key] : clone(defaultState[key])
          }
          markAsLoaded(state)
        },
        boxArchive(state, boxArchive) {
          state.boxArchive = boxArchive.map((box) => {
            return {
              ...box,
              variants: box.products.flatMap((product) => product.variants),
            }
          })

          markAsLoaded(state)
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        async load() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const config = await configApi.getConfig()
            set('config', config)
          } finally {
            set('stopLoading')
          }
        },
        async loadBoxArchive() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const boxArchive = await configApi.getBoxArchive()
            set('boxArchive', boxArchive)
          } finally {
            set('stopLoading')
          }
        },
        async loadOpenBoxes() {
          if (get('loading')) {
            return waitForValue(() => get('loading'), false)
          }
          set('startLoading')
          try {
            const config = await configApi.getConfig()
            set('openBoxes', config.openBoxes)
          } finally {
            set('stopLoading')
          } 
        },
      }),
    }
  )
}
