import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import debounce from '@/helpers/debounce'

Vue.use(VueRouter)

export const DIRECTION_AUTO = 'auto'
export const DIRECTION_NONE = 'none'
export const DIRECTION_BACK = 'back'
export const DIRECTION_FORWARD = 'forward'

export const TRANSITION_LEAVE_DURATION = 250
export const TRANSITION_ENTER_DURATION = 400

const baseTitle = document.title
let poppedState = false
let fromStateTime = 0

const routes = [
  {
    path: '/',
    redirect: '/subscribe',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/login',
    name: 'AccountLogin',
    component: () => import('@/views/AccountLogin'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'AccountLogout',
    component: () => import('@/views/AccountLogout'),
    meta: {
      title: 'Logout',
    },
  },
  {
    path: '/reset-password',
    name: 'AccountResetPassword',
    component: () => import('@/views/AccountResetPassword'),
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/set-password',
    name: 'AccountSetPassword',
    component: () => import('@/views/AccountSetPassword'),
    meta: {
      title: 'Set Password',
    },
  },
  {
    path: '/refer',
    name: 'Refer',
    component: () => import('@/views/Refer'),
    meta: {
      title: 'Refer a Friend',
    },
  },
  {
    path: '/page',
    component: {
      // "Pass-through" component, see https://github.com/vuejs/vue-router/issues/2105
      render: (createElement) => createElement('router-view'),
    },
    children: [
      {
        path: 'privacy',
        name: 'PagePrivacy',
        component: () => import('@/views/PagePrivacy'),
        meta: {
          title: 'Privacy Policy',
        },
      },
      {
        path: 'tou',
        name: 'PageTermsOfUse',
        component: () => import('@/views/PageTermsOfUse'),
        meta: {
          title: 'Terms of Use',
        },
      },
      {
        path: 'copyright',
        name: 'PageCopyrightDisputePolicy',
        component: () => import('@/views/PageCopyrightDisputePolicy'),
        meta: {
          title: 'Copyright Dispute Policy',
        },
      },
    ],
  },
  {
    path: '/alltrue-plus-upgrade/:subscriptionId',
    component: {
      // "Pass-through" component, see https://github.com/vuejs/vue-router/issues/2105
      render: (createElement) => createElement('router-view'),
    },
    children: [
      {
        path: '',
        name: 'AccountAlltruePlusUpgrade',
        component: () => import('@/views/AlltruePlusUpgrade'),
        props: ({ params }) => ({
          subscriptionId: Number.parseInt(params.subscriptionId),
        }),
        meta: {
          title: 'Upgrade to Alltrue+',
        },
      },
      {
        path: 't-shirt',
        name: 'AccountSubscribeTshirt',
        component: () => import('@/views/SubscribeTshirt'),
        meta: {
          title: 'Members Only T-shirt',
        },
      },
    ],
  },
  {
    path: '/account',
    component: () => import('@/views/AccountProfile'),
    children: [
      {
        path: '',
        redirect: '/account/settings',
      },
      {
        path: 'settings/:section?',
        name: 'AccountProfileSettings',
        component: () => import('@/views/AccountProfileSettings'),
        meta: {
          title: 'Settings',
        },
        children: [
          {
            path: 'edit-info',
            name: 'AccountProfileSettingsEditInfo',
            component: () => import('@/views/AccountProfileSettingsEditInfo'),
            meta: {
              title: 'Edit Account Info',
            },
          },
          {
            path: 'change-password',
            name: 'AccountProfileSettingsChangePassword',
            component: () => import('@/views/AccountProfileSettingsChangePassword'),
            meta: {
              title: 'Change Password',
            },
          },
          {
            path: 'edit-address/:addressId',
            name: 'AccountProfileSettingsEditAddress',
            component: () => import('@/views/AccountProfileSettingsEditAddress'),
            meta: {
              title: 'Edit Shipping Address',
            },
          },
          {
            path: 'add-address',
            name: 'AccountProfileSettingsAddAddress',
            component: () => import('@/views/AccountProfileSettingsAddAddress'),
            meta: {
              title: 'New Shipping Address',
            },
          },
          {
            path: 'remove-address/:addressId',
            name: 'AccountProfileSettingsRemoveAddress',
            component: () => import('@/views/AccountProfileSettingsRemoveAddress'),
            meta: {
              title: 'Remove Shipping Address',
            },
          },
          {
            path: 'change-payment-method',
            name: 'AccountProfileSettingsPaymentMethod',
            component: () => import('@/views/AccountProfileSettingsPaymentMethod'),
            meta: {
              title: 'Change Payment Method',
            },
          },
          {
            path: 'change-payment-method/credit-card',
            name: 'AccountProfileSettingsCreditCard',
            component: () => import('@/views/AccountProfileSettingsCreditCard'),
            meta: {
              title: 'Card Details',
            },
          },
        ],
      },
      {
        path: 'subscriptions/:section?',
        name: 'AccountProfileSubscriptions',
        component: () => import('@/views/AccountProfileSubscriptions'),
        meta: {
          title: 'Subscriptions',
        },
        children: [
          {
            path: 'change-address/:subscriptionId',
            name: 'AccountProfileChangeSubscriptionAddress',
            component: () => import('@/views/AccountProfileChangeSubscriptionAddress'),
            meta: {
              title: 'Change Membership Address',
            },
          },
          {
            path: 'add-on/:type/:subscriptionId',
            name: 'AccountAddOn',
            component: () => import('@/views/AccountAddOn'),
            props: ({ params }) => ({
              subscriptionId: Number.parseInt(params.subscriptionId),
              type: params.type,
            }),
            meta: {
              title: 'Account Add On',
            },
          },
          {
            path: 'edit/:selectedSubscriptionId/:clickedAction',
            name: 'AccountSubscriptionEdit',
            component: () => import('@/views/AccountSubscriptionEdit'),
            props: ({ params }) => ({
              clickedAction: params.clickedAction,
              selectedSubscriptionId: Number.parseInt(params.selectedSubscriptionId),
            }),
            meta: {
              title: 'Edit Subscription',
            },
          },
          {
            path: 'my-past-boxes/:variantId',
            name: 'AccountMyPastBoxesDetails',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              variantId: Number.parseInt(params.variantId) || undefined,
              type: 'choice-plus',
              isProductSliderDisabled: true,
            }),
            meta: {
              title: 'Product Details',
            },
          },
        ],
      },
      {
        path: 'orders',
        name: 'AccountProfileOrders',
        component: () => import('@/views/AccountProfileOrders'),
        meta: {
          title: 'Orders',
        },
        children: [
          {
            path: 'all',
            name: 'AccountProfileOrdersAll',
            component: () => import('@/views/AccountProfileOrdersAll'),
            meta: {
              title: 'Order History',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/achievements',
    component: {
      // "Pass-through" component, see https://github.com/vuejs/vue-router/issues/2105
      render: (createElement) => createElement('router-view'),
    },
    children: [
      {
        path: '',
        name: 'AccountAchievements',
        component: () => import('@/views/AccountAchievements'),
        meta: {
          title: 'Achievements',
        },
      },
      {
        path: ':achievement',
        name: 'AccountAchievement',
        component: () => import('@/views/AccountAchievement'),
        props: ({ params }) => ({
          achievement: params.achievement,
        }),
        meta: {
          title: 'Achivement',
        },
      },
    ],
  },
  {
    path: '/:subscribe(subscribe)?/customize',
    component: () => import('@/views/Customize'),
    children: [
      {
        path: '',
        name: 'CustomizeStart',
        component: () => import('@/views/CustomizeLookInside'),
        meta: {
          title: 'Box Customization',
        },
        children: [
          {
            path: 'details/:boxId/:variantId',
            name: 'CustomizeDetails',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              variantId: Number.parseInt(params.variantId) || undefined,
              boxId: Number.parseInt(params.boxId) || undefined,
              type: 'choice-plus',
            }),
            meta: {
              title: 'Product Details',
            },
          },
        ],
      },
      {
        path: '/subscription/:subscriptionId/order/:orderId',
        name: 'CustomizeOrderSummary',
        component: () => import('@/views/CustomizeSummary'),
        meta: {
          title: 'Order Summary',
        },
        children: [
          {
            path: 'product/:variantId',
            name: 'CustomizeOrderSummaryDetails',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              variantId: Number.parseInt(params.variantId) || undefined,
              boxId: Number.parseInt(params.boxId) || undefined,
              type: 'choice-plus',
            }),
            meta: {
              title: 'Product Details',
            },
          },
        ],
      },
      {
        path: ':subscriptionId/',
        component: {
          // "Pass-through" component, see https://github.com/vuejs/vue-router/issues/2105
          render: (createElement) => createElement('router-view'),
        },
        children: [
          {
            // This is not a real view, it is just a redirect inside a navigation guard because it needed to be async (we use some server data to determine where to redirect to) and the `redirect` route option is synchronous
            path: '',
            name: 'CustomizeSubscription',
            component: () => import('@/views/CustomizeSubscription'),
          },
          {
            path: ':choice(\\d+)',
            name: 'CustomizeChoice',
            component: () => import('@/views/CustomizeChoice'),
            meta: {
              title: (to) => `Choice #${to.params.choice}`,
            },
            children: [
              {
                path: ':variantId',
                name: 'CustomizeChoiceDetails',
                component: () => import('@/views/PDP'),
                props: ({ params }) => ({
                  variantId: Number.parseInt(params.variantId) || undefined,
                  type: 'choice-plus',
                }),
                meta: {
                  title: 'Product Details',
                },
              },
            ],
          },
          {
            path: 'complete',
            name: 'CustomizeComplete',
            component: () => import('@/views/CustomizeComplete'),
            meta: {
              title: 'Customization Complete',
            },
          },
          {
            path: 'upgrade',
            name: 'CustomizeUpgrade',
            component: () => import('@/views/CustomizeUpgrade'),
            meta: {
              title: 'Upgrade To Annual',
            },
            children: [
              {
                path: ':variantId',
                name: 'CustomizeUpgradeDetails',
                component: () => import('@/views/PDP'),
                props: ({ params }) => ({
                  variantId: Number.parseInt(params.variantId) || undefined,
                  type: 'choice-plus',
                }),
                meta: {
                  title: 'Product Details',
                },
              },
            ],
          },
          {
            path: 'bonuses',
            name: 'CustomizeBonuses',
            component: () => import('@/views/SubscribeBonuses'),
            meta: {
              title: 'Add Bonus',
            },
            children: [
              {
                path: ':type',
                name: 'CustomizeBonusConfirmation',
                component: () => import('@/views/AccountAddOn'),
                props: ({ params }) => ({
                  subscriptionId: Number.parseInt(params.subscriptionId),
                  type: params.type,
                }),
                meta: {
                  title: 'Add Bonus',
                },
              },
            ],
          },
          {
            path: 'review',
            name: 'CustomizeSummary',
            component: () => import('@/views/CustomizeSummary'),
            meta: {
              title: 'My Choices',
            },
            children: [
              {
                path: ':variantId',
                name: 'CustomizeSummaryDetails',
                component: () => import('@/views/PDP'),
                props: ({ params }) => ({
                  variantId: Number.parseInt(params.variantId) || undefined,
                  type: 'choice-plus',
                }),
                meta: {
                  title: 'Product Details',
                },
              },
            ],
          },
          {
            path: 'choice-plus-thank-you',
            name: 'CustomizeChoicePlusThankYou',
            component: () => import('@/views/CustomizeChoicePlusThankYou'),
            meta: {
              title: 'Choice Plus Thank You',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/subscribe',
    component: () => import('@/views/Subscribe'),
    children: [
      {
        path: '',
        name: 'SubscribePlan',
        component: () => import('@/views/SubscribeSelectPlan'),
        meta: {
          title: 'Choose Membership',
        },
      },
      {
        path: ':plan(quarterly|annually)',
        redirect: (to) => {
          store.set('subscribe/selectedPlanHandle', to.params.plan)
          return '/subscribe/customization/1'
        },
      },
      {
        path: 'sign-up',
        name: 'SubscribeSignup',
        component: () => import('@/views/SubscribeSignup'),
        meta: {
          title: 'Sign Up',
        },
      },
      {
        path: 'welcome',
        name: 'SubscribeWelcome',
        component: () => import('@/views/SubscribeWelcome'),
        meta: {
          title: 'Welcome',
        },
      },
      {
        path: 'market',
        name: 'SubscribeMarket',
        component: () => import('@/views/SubscribeMarket'),
        meta: {
          title: 'Market',
        },
        children: [
          {
            path: 'products/:productId',
            name: 'SubscribeMarketProduct',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              productId: Number.parseInt(params.productId) || undefined,
              type: params.type,
            }),
            meta: {
              title: (to) => to.params.productTitle,
            },
          },
        ],
      },
      {
        path: 'market/checkout',
        name: 'SubscribeMarketCheckout',
        component: () => import('@/views/SubscribeMarketCheckout'),
        meta: {
          title: 'Checkout',
        },
        children: [
          {
            path: 'products/:productId',
            name: 'SubscribeMarketCheckoutProduct',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              productId: Number.parseInt(params.productId) || undefined,
              type: params.type,
            }),
            meta: {
              title: (to) => to.params.productTitle,
            },
          },
        ],
      },
      {
        // must go last, so that static pages match first
        path: ':subscriptionId',
        component: {
          // "Pass-through" component, see https://github.com/vuejs/vue-router/issues/2105
          render: (createElement) => createElement('router-view'),
        },
        children: [
          {
            path: 'interstitial',
            name: 'SubscribeInterstitial',
            component: () => import('@/views/SubscribeInterstitial'),
            props: ({ params }) => ({
              type: params.type,
            }),
            meta: {
              title: 'Subscribe',
            },
          },
          {
            path: 'alltrue-plus-upgrade',
            name: 'AlltruePlusUpgrade',
            component: () => import('@/views/AlltruePlusUpgrade'),
            meta: {
              title: 'Upgrade to Alltrue+',
            },
          },
          {
            path: 'insiders',
            name: 'SubscribeInsiders',
            component: () => import('@/views/SubscribeInsiders'),
            meta: {
              title: 'Insiders Group',
            },
          },
          {
            path: 't-shirt',
            name: 'SubscribeTshirt',
            component: () => import('@/views/SubscribeTshirt'),
            meta: {
              title: 'Members Only T-shirt',
            },
          },
          {
            path: 'bonuses',
            name: 'SubscribeBonuses',
            component: () => import('@/views/SubscribeBonuses'),
            meta: {
              title: 'Add Bonus',
            },
            children: [
              {
                path: ':type',
                name: 'SubscribeBonusConfirmation',
                component: () => import('@/views/AccountAddOn'),
                props: ({ params }) => ({
                  subscriptionId: Number.parseInt(params.subscriptionId),
                  type: params.type,
                }),
                meta: {
                  title: 'Add Bonus',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  // these have to go above the rest of /market routes
  {
    path: '/market/cart', // same path, but not a child
    name: 'MarketplaceCart',
    component: () => import('@/views/MarketplaceCart'),
    meta: {
      title: 'Marketplace Cart',
    },
    children: [
      {
        path: 'products/:productId/:productSlug?',
        name: 'CartMarketProduct',
        component: () => import('@/views/MarketProduct'),
        props: ({ params }) => ({
          productId: Number.parseInt(params.productId) || undefined,
          productSlug: params.productSlug,
          productTitle: params.productTitle,
        }),
        meta: {
          title: (to) => to.params.productTitle,
        },
      },
    ],
  },
  {
    path: '/single-market/:productSlug',
    name: 'SingleSkuMarket',
    component: () => import('@/views/SingleSkuMarket'),
    props: ({ params }) => ({
      productSlug: params.productSlug,
      productTitle: params.productTitle,
    }),
    meta: {
      title: (to) => to.params.productTitle,
    },
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/Market'),
    meta: {
      title: 'Add-On Market',
    },
    children: [
      {
        path: 'products',
        redirect: '/market',
      },
      {
        path: 'products/:productId/:productSlug?',
        name: 'MarketProduct',
        component: () => import('@/views/MarketProduct'),
        props: ({ params }) => ({
          productId: Number.parseInt(params.productId) || undefined,
          productSlug: params.productSlug,
          productTitle: params.productTitle,
        }),
        meta: {
          title: (to) => to.params.productTitle,
        },
      },
      {
        path: ':filters',
        name: 'MarketFilters',
      },
    ],
  },
  {
    path: '/checkout/:storeType',
    component: () => import('@/views/Checkout'),
    props: ({ params }) => ({
      storeType: params.storeType,
    }),
    children: [
      {
        path: '',
        redirect: { name: 'CheckoutShipping' },
      },
      {
        path: 'shipping',
        name: 'CheckoutShipping',
        component: () => import('@/views/CheckoutShipping'),
        props: ({ params }) => ({
          storeType: params.storeType,
        }),
        meta: {
          title: 'Shipping Address',
        },
        children: [
          {
            path: 'edit/:addressId',
            name: 'CheckoutShippingEdit',
            component: () => import('@/views/CheckoutShippingEdit'),
            meta: {
              title: 'Edit Address',
            },
          },
          {
            path: 'add',
            name: 'CheckoutShippingAdd',
            component: () => import('@/views/CheckoutShippingAdd'),
            meta: {
              title: 'Add New Address',
            },
          },
        ],
      },
      {
        path: 'billing',
        name: 'CheckoutBilling',
        component: () => import('@/views/CheckoutBilling'),
        props: ({ params }) => ({
          storeType: params.storeType,
        }),
        meta: {
          title: 'Payment Info',
        },
        children: [
          {
            path: 'payment-method',
            name: 'CheckoutBillingPaymentMethod',
            component: () => import('@/views/CheckoutBillingPaymentMethod'),
            meta: {
              title: 'Payment Method',
            },
          },
          {
            path: 'credit-card',
            name: 'CheckoutBillingCreditCard',
            component: () => import('@/views/CheckoutBillingCreditCard'),
            meta: {
              title: 'Card Details',
            },
          },
        ],
      },
      {
        path: 'summary',
        name: 'CheckoutSummary',
        component: () => import('@/views/CheckoutSummary'),
        props: ({ params }) => ({
          storeType: params.storeType,
        }),
        meta: {
          title: 'Order Summary',
        },
      },
    ],
  },
  {
    // it is a standalone page, not a child of a checkout view
    path: '/checkout/:storeType/confirmation',
    name: 'CheckoutConfirmation',
    component: () => import('@/views/CheckoutConfirmation'),
    props: ({ params }) => ({
      storeType: params.storeType,
    }),
    meta: {
      title: 'Order Confirmation',
    },
  },
  {
    path: '/essentials/cart',
    name: 'EssentialsCart',
    component: () => import('@/views/EssentialsCart'),
    meta: {
      title: 'Essentials Cart',
    },
    children: [
      {
        path: 'products/:productId/:productSlug?',
        name: 'EssentialsCartProduct',
        component: () => import('@/views/PDP'),
        props: ({ params }) => ({
          productId: Number.parseInt(params.productId) || undefined,
          type: params.type,
        }),
        meta: {
          title: (to) => to.params.productTitle,
        },
      },
    ],
  },
  {
    path: '/essentials',
    component: () => import('@/views/EssentialsShop'),
    children: [
      {
        path: '',
        name: 'EssentialsHome',
        component: () => import('@/views/EssentialsHome'),
        meta: {
          title: 'Essentials Shop',
        },
        children: [
          {
            path: 'products',
            redirect: { name: 'EssentialsHome' },
          },
          {
            path: 'products/:productId/:productSlug?',
            name: 'EssentialsProductHome',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              productId: Number.parseInt(params.productId) || undefined,
              type: params.type,
            }),
            meta: {
              title: (to) => to.params.productTitle,
            },
          },
        ],
      },
      {
        path: 'banned-materials',
        name: 'EssentialsBannedMaterials',
        component: () => import('@/views/EssentialsBannedMaterials'),
        meta: {
          title: 'Banned Materials',
        },
      },
      {
        path: 'search/:query/:sort?',
        name: 'EssentialsSearch',
        component: () => import('@/views/EssentialsSearch'),
        props: ({ params }) => ({
          query: params.query,
          sort: params.sort,
        }),
        meta: {
          title: 'Essentials Search',
        },
      },
      {
        path: ':categorySlugL1/:categorySlugL2?',
        name: 'EssentialsPLP',
        component: () => import('@/views/EssentialsPLP'),
        meta: {
          title: 'Essentials Shop',
        },
        children: [
          {
            path: 'products',
            redirect: '/essentials/:categorySlugL1/:categorySlugL2?',
          },
          {
            path: 'products/:productId/:productSlug?',
            name: 'EssentialsProductPLP',
            component: () => import('@/views/PDP'),
            props: ({ params }) => ({
              productId: Number.parseInt(params.productId) || undefined,
              type: params.type,
            }),
            meta: {
              title: (to) => to.params.productTitle,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/box-archive',
    name: 'BoxArchive',
    component: () => import('@/views/BoxArchive'),
    meta: {
      title: 'Box Archive',
    },
    children: [
      {
        path: ':variantId',
        name: 'BoxArchiveDetails',
        component: () => import('@/views/PDP'),
        props: ({ params }) => ({
          variantId: Number.parseInt(params.variantId) || undefined,
          boxId: Number.parseInt(params.boxId) || undefined,
          type: 'choice-plus',
        }),
        meta: {
          title: 'Product Details',
        },
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound'),
    meta: {
      title: 'Page Not Found',
    },
  },
]

const accountRoutes = [
  'AccountProfileSettings',
  'AccountProfileSettingsEditInfo',
  'AccountProfileSettingsEditAddress',
  'AccountProfileSettingsAddAddress',
  'AccountProfileSettingsRemoveAddress',
  'AccountProfileSettingsPaymentMethod',
  'AccountProfileSettingsCreditCard',
  'AccountProfileSubscriptions',
  'AccountProfileOrders',
  'AccountProfileOrdersAll',
  'AccountProfileChangeSubscriptionAddress',
]

const customizeRoutes = [
  'Home',
  'CustomizeStart',
  'CustomizeSubscription',
  'CustomizeChoice',
  'CustomizeChoiceDetails',
  'CustomizeDetails',
  'CustomizeComplete',
  'CustomizeSummary',
  'CustomizeSummaryDetails',
  'CustomizeConfirmation',
]

const subscribeRoutes = [
  'SubscribePlan',
  'SubscribeSignup',
  'SubscribeInterstitial',
  'SubscribeInsiders',
  'SubscribeBonuses',
  'SubscribeWelcome',
  'SubscribeMarket',
  'SubscribeMarketCheckout',
]

const marketplaceRoutes = [
  'Market',
  'MarketFilters',
  'MarketProduct',
  'Cart',
  'CheckoutShipping',
  ['CheckoutShippingEdit', 'CheckoutShippingAdd'],
  'CheckoutBilling',
  'CheckoutBillingPaymentMethod',
  'CheckoutBillingCreditCard',
  'CheckoutSummary',
  'CheckoutConfirmation',
]

const essentialsRoutes = [
  'EssentialsHome',
  'EssentialsBannedMaterials',
  ['EssentialsProductHome'],
  'EssentialsPLP',
  ['EssentialsProductPLP'],
]

const flows = [accountRoutes, customizeRoutes, subscribeRoutes, marketplaceRoutes, essentialsRoutes]

const getNavigationDirection = (to, from) => {
  // If the back or forward button was pressed, determine which one of the two it was
  let historyDirection = DIRECTION_NONE
  if (poppedState) {
    const toStateTime = Number(window.history.state?.key) || 0
    historyDirection = toStateTime < fromStateTime ? DIRECTION_BACK : DIRECTION_FORWARD
  }

  // If navigating between different entries of the same route,
  // compare the step number if there is one,
  // or fall back to the direction detected from the history (if any)
  if (to.name === from.name) {
    if (to.params.step && from.params.step) {
      if (to.params.step < from.params.step) {
        return DIRECTION_BACK
      }
      if (to.params.step > from.params.step) {
        return DIRECTION_FORWARD
      }
    }
    return historyDirection
  }

  // Find a flow that contains both the `to` and the `from` routes
  const flowRoutes = flows.find((flowRoutes) => {
    const routeNames = flowRoutes.flat()
    return routeNames.includes(to.name) && routeNames.includes(from.name)
  })

  // If we couldn't find one, fall back to the history direction
  if (!flowRoutes) {
    return historyDirection
  }

  // Figure out which route comes first in the flow
  for (const routeNameOrGroup of flowRoutes) {
    if (Array.isArray(routeNameOrGroup)) {
      let toFound = false
      let fromFound = false
      for (const routeName of routeNameOrGroup) {
        if (routeName === to.name) {
          toFound = true
        }
        if (routeName === from.name) {
          fromFound = true
        }
      }
      if (toFound && fromFound) {
        // The two routes are in the same group, so fall back to the history direction
        return historyDirection
      }
      if (toFound && !fromFound) {
        return DIRECTION_BACK
      }
      if (!toFound && fromFound) {
        return DIRECTION_FORWARD
      }
      continue
    }
    if (routeNameOrGroup === to.name) {
      return DIRECTION_BACK
    }
    if (routeNameOrGroup === from.name) {
      return DIRECTION_FORWARD
    }
  }

  // This should never happen, but just in case
  return DIRECTION_NONE
}

// Set the navigation direction automatically if the back or forward button was pressed
window.addEventListener('popstate', () => {
  poppedState = true
  store.set('general/navigationDirection', DIRECTION_AUTO)
})

// See https://github.com/quasarframework/quasar/issues/1466#issuecomment-414066098
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}

const router = new VueRouter({
  mode: 'history',
  base: '/app/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    const detailsRoutes = [
      'CustomizeDetails',
      'CustomizeChoiceDetails',
      'CustomizeUpgradeDetails',
      'CustomizeSummaryDetails',
      'CustomizeOrderSummaryDetails',
      'EssentialsProductHome',
      'EssentialsProductPLP',
      'EssentialsCartProduct',
      'BoxArchiveDetails',
      'AccountMyPastBoxesDetails',
      'CartMarketProduct',
      'SubscribeMarketProduct',
      'SubscribeMarketCheckoutProduct',
    ]
    if (
      ((to.name ?? '').startsWith('Market') && (from.name ?? '').startsWith('Market')) ||
      detailsRoutes.includes(to.name ?? '') ||
      detailsRoutes.includes(from.name ?? '')
    ) {
      return false
    }
    const position = savedPosition ?? {
      x: 0,
      y: 0,
    }
    return position
  },
})

const onNavigationComplete = debounce(() => {
  store.set('general/navigating', false)
  store.set('general/navigationRoute', null)
  store.set('general/navigationDirection', DIRECTION_AUTO)
}, TRANSITION_LEAVE_DURATION + TRANSITION_ENTER_DURATION)

router.beforeEach(async (to, from, next) => {
  onNavigationComplete.clear()
  store.set('general/navigationLoading', true)
  store.set('general/navigating', true)
  store.set('general/navigationRoute', {
    path: to.path,
    name: to.name,
    params: to.params,
  })
  if ((store.get('general/navigationDirection') ?? DIRECTION_AUTO) === DIRECTION_AUTO) {
    store.set('general/navigationDirection', getNavigationDirection(to, from))
  }

  const nearestMatchedRouteWithTitle = to.matched.filter((route) => route.meta?.title).slice(-1)[0]
  const title = (() => {
    if (!nearestMatchedRouteWithTitle) {
      return undefined
    }
    const title = nearestMatchedRouteWithTitle.meta.title
    if (typeof title === 'function') {
      return title(to)
    }
    return title
  })()
  document.title = `${title ? `${title} - ` : ''}${baseTitle}`

  if (store.get('general/preventNavigation')) {
    next(false)
    store.set('general/preventNavigation', false)
  } else {
    next()
  }
})

router.afterEach(() => {
  store.set('general/navigationLoading', false)
  onNavigationComplete()
  poppedState = false
  fromStateTime = Number(window.history.state?.key) || 0
})

router.onError(() => {
  store.set('general/navigationLoading', false)
  store.set('general/navigating', false)
  store.set('general/navigationRoute', null)
  store.set('general/navigationDirection', DIRECTION_AUTO)
  poppedState = false
})

export default router

export const navigate = async (route, direction = DIRECTION_AUTO) => {
  try {
    store.set('general/navigationDirection', direction)
    await router.push(route)
  } catch (error) {
    store.set('general/navigationLoading', false)
    // Don’t throw an error if the navigation was redirected, aborted, cancelled, or duplicated
    if (VueRouter.isNavigationFailure(error)) {
      router.app.$root.$emit('navigationFailure', {
        failure: error,
      })
      return
    }
    return Promise.reject(error)
  }
}
