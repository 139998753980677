/**
 * Returns a promise that resolves when the watched expression computes to a certain value, or to a value that passes a certain test.
 */

export default async (
  watchFunction,
  watchedExpression,
  targetValueOrTest = (value) => (value ?? null) !== null
) => {
  const testValue = (value) => {
    if (typeof targetValueOrTest === 'function') {
      return Boolean(targetValueOrTest(value))
    }
    return value === targetValueOrTest
  }
  const initialValue = watchedExpression()
  if (testValue(initialValue)) {
    return Promise.resolve()
  }
  return new Promise((resolve) => {
    const unwatch = watchFunction(watchedExpression, (value) => {
      if (testValue(value)) {
        resolve()
        unwatch()
      }
    })
  })
}
