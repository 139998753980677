import createPersistedState from 'vuex-persistedstate'
import clone from 'clone-deep'

/**
 * `store` - The Vuex store
 * `storage` - `window.sessionStorage`, `window.localStorage`, etc.
 * `modules` - Array of module names to persist in the given storage
 * `ignoredState` - State properties not to persist for each module, in the format `{ moduleName: [...stateProperties] }`
 */
export default (store, storage, modules, ignoredState = null) => {
  createPersistedState({
    storage: storage,

    reducer: (state) => {
      const reducedState = modules.reduce((reducedState, module) => {
        reducedState[module] = clone(state[module])
        return reducedState
      }, {})

      // Prevent some state properties from being persisted
      for (const module of modules) {
        delete reducedState[module].lastLoaded
        delete reducedState[module].loadRequests
        delete reducedState[module].loadRequestsProcessed
        delete reducedState[module].markedAsStale

        for (const ignoredStateProperty of ignoredState?.[module] ?? []) {
          delete reducedState[module][ignoredStateProperty]
        }
      }

      return reducedState
    },

    filter: (mutation) => {
      // Prevent some mutations from persisting the state
      return (
        mutation.type.match(
          /\/(lastLoaded|loadRequests|loadRequestsProcessed|startLoading|stopLoading|markedAsStale|markAsStale)$/
        ) === null &&
        [
          'route/ROUTE_CHANGED',
          'general/navigating',
          'general/navigationRoute',
          'general/navigationDirection',
          'general/navigationLoading',
        ].includes(mutation.type) === false
      )
    },
  })(store)
}
