import clone from 'clone-deep'
import dayjs from 'dayjs'
import createModule from '@/store/createModule'
import { ApiError } from '@/api'
import subscriptionsApi from '@/api/subscriptions'
import accountApi from '@/api/account'
import { PLAN_PRICE } from '@/helpers/constants'
import keyBy from '@/helpers/keyBy'
import { events } from '@/helpers/gtm'
import timeElapsed from '@/helpers/timeElapsed'

export const SHIPPING_ADDRESS_NEW = 'new'

export default (store) => {
  createModule(
    store,
    'subscribe',
    {
      plans: [
        {
          label: 'Quarterly',
          handle: 'quarterly',
          quarterlyPrice: PLAN_PRICE.quarterly,
          quarterCount: 1,
        },
        {
          label: 'Annual',
          handle: 'annually',
          quarterlyPrice: PLAN_PRICE.annually / 4,
          quarterCount: 4,
        },
        {
          label: 'Alltrue+',
          handle: 'alltruePlus',
          quarterlyPrice: PLAN_PRICE.alltruePlus / 4,
          quarterCount: 4,
        },
      ],
      selectedPlanHandle: null,
      customizeSelectedOptionIds: [],
      selectedShippingAddressId: null,
      newShippingAddress: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        countryId: null,
        stateId: null,
      },
      paymentMethods: [
        {
          label: 'Credit / Debit',
          handle: 'credit-card',
        },
        {
          label: 'PayPal',
          handle: 'paypal',
        },
      ],
      selectedPaymentMethodHandle: null,
      useExistingBillingInfoIfAny: true,
      recurlyToken: null,
      ecoUpgrade: false,
      magazine: false,
      subscriptionPreview: null,
      refreshSubscriptionPreviewRequests: 0,
      refreshSubscriptionPreviewRequestsProcessed: 0,
      subscriptionCreated: false,
      addOnSubmitting: false,
      addOnSuccess: false,
      waitlistEndTime: null,
    },
    {
      getters: ({ get }) => ({
        waitlistSecondsLeft() {
          const now = dayjs(timeElapsed.currentTime) // utc
          const end = dayjs(get('waitlistEndTime')) // utc
          return end.diff(now, 'seconds')
        },
        plansByHandle() {
          return keyBy(get('plans'), ({ handle }) => handle)
        },
        selectedPlan() {
          return get(`plansByHandle@${get('selectedPlanHandle')}`)
        },
        selectedNewShippingAddress() {
          return get('selectedShippingAddressId') === SHIPPING_ADDRESS_NEW
        },
        shippingAddress() {
          if (get('selectedNewShippingAddress')) {
            return get('newShippingAddress')
          }
          return store.get(`account/addressesById@${get('selectedShippingAddressId')}`)
        },
        hasShippingAddress() {
          return Boolean(get('shippingAddress')?.zipCode)
        },
        paymentMethodsByHandle() {
          return keyBy(get('paymentMethods'), ({ handle }) => handle)
        },
        selectedPaymentMethod() {
          const selectedPaymentMethodHandle = get('useExistingBillingInfo')
            ? store.get('account/billingInfo')?.type
            : get('selectedPaymentMethodHandle')
          return get(`paymentMethodsByHandle@${selectedPaymentMethodHandle}`)
        },
        hasPaymentMethod() {
          return Boolean(get('selectedPaymentMethod'))
        },
        hasExistingBillingInfo() {
          const billingInfoType = store.get('account/billingInfo')?.type
          return billingInfoType === 'credit-card' || billingInfoType === 'paypal'
        },
        useExistingBillingInfo() {
          if (!get('useExistingBillingInfoIfAny')) {
            return false
          }
          return get('hasExistingBillingInfo')
        },
        refreshSubscriptionPreviewRequestsActive() {
          return (
            get('refreshSubscriptionPreviewRequests') -
            get('refreshSubscriptionPreviewRequestsProcessed')
          )
        },
        refreshingSubscriptionPreview() {
          return get('refreshSubscriptionPreviewRequestsActive') > 0
        },
        addOns() {
          const addOns = []

          if (get('ecoUpgrade')) {
            addOns.push('eco-upgrade')
          }

          if (get('magazine')) {
            addOns.push('magazine')
          }

          return addOns
        },
        alltruePlusSeasonalUpgradeCost() {
          return (PLAN_PRICE.alltruePlus - PLAN_PRICE.annually) / 4
        },
      }),

      mutations: ({ defaultState }) => ({
        newShippingAddress(state, newShippingAddress) {
          state.newShippingAddress =
            newShippingAddress === null
              ? clone(defaultState.newShippingAddress)
              : newShippingAddress
        },
        startRefreshingSubscriptionPreview(state) {
          state.refreshSubscriptionPreviewRequests++
        },
        stopRefreshingSubscriptionPreview(state) {
          state.refreshSubscriptionPreviewRequestsProcessed++
        },
      }),

      actions: ({ get, set, waitForValue }) => ({
        setWaitlistTime(_context, { time }) {
          set('waitlistEndTime', time)
        },
        async joinList(_context, { listId, email }) {
          try {
            await accountApi.joinList({ listId, email })
          } catch (error) {
            return Promise.reject(error)
          }
        },
        async addEcoUpgrade(_context, { subscriptionId }) {
          set('addOnSubmitting', true)
          try {
            const response = await subscriptionsApi.addEcoUpgrade({ subscriptionId })
            const mapped = store
              .get('account/subscriptions')
              .map((subscription) => (subscription.id === response.id ? response : subscription))
            store.set('account/subscriptions', mapped)
            set('addOnSuccess', true)
          } catch (error) {
            return Promise.reject(error)
          } finally {
            set('addOnSubmitting', false)
          }
        },
        async addMagazine(_context, { subscriptionId }) {
          set('addOnSubmitting', true)
          try {
            const response = await subscriptionsApi.addMagazine({ subscriptionId })
            const mapped = store
              .get('account/subscriptions')
              .map((subscription) => (subscription.id === response.id ? response : subscription))
            store.set('account/subscriptions', mapped)
            set('addOnSuccess', true)
          } catch (error) {
            return Promise.reject(error)
          } finally {
            set('addOnSubmitting', false)
          }
        },
        async reactivateSubscription(_context, { subscriptionId }) {
          try {
            const response = await subscriptionsApi.reactivateSubscription({ subscriptionId })
            const mapped = store
              .get('account/subscriptions')
              .map((subscription) => (subscription.id === response.id ? response : subscription))
            store.set('account/subscriptions', mapped)
          } catch (error) {
            return Promise.reject(error)
          }
        },
        async upgradeSubscription(_context, { subscriptionId }) {
          try {
            const response = await subscriptionsApi.upgradeQuarterlyToYearly({ subscriptionId })
            const updatedSubscriptions = [response, ...store.get('account/subscriptions')]
            store.set('account/subscriptions', updatedSubscriptions)
            await store.dispatch('account/fetchProfile')
            return response
          } catch (error) {
            return Promise.reject(error)
          }
        },
        async applyOffer(_context, { subscriptionId, subscriptionType }) {
          try {
            const response = await subscriptionsApi.applyOffer({ subscriptionId })
            // TODO: quarter members count number of coupons left
            await store.dispatch('account/fetchProfile')
            events.log({
              name: 'accepted cancel offer',
              data: {
                plan: subscriptionType,
              },
            })
            return response
          } catch (error) {
            return Promise.reject(error)
          }
        },
        async unsubscribe(_context, { subscriptionId, subscriptionType }) {
          try {
            const response = await subscriptionsApi.unsubscribe({ subscriptionId })
            const mapped = store
              .get('account/subscriptions')
              .map((subscription) => (subscription.id === response.id ? response : subscription))
            store.set('account/subscriptions', mapped)
            events.log({
              name: 'successfully unsubscribed',
              data: {
                plan: subscriptionType,
              },
            })
          } catch (error) {
            return Promise.reject(error)
          }
        },
        resetSubscriptionPreview() {
          set('subscriptionPreview', null)
        },
        async previewSubscription(
          { dispatch },
          { shippingAddress = null, couponCode = null, giftCard = null } = {}
        ) {
          const finalCouponCode = couponCode ?? store.get('couponAndGiftCard/submittedCouponCode')
          const finalGiftCard = giftCard ?? store.get('couponAndGiftCard/submittedGiftCard')
          const data = {
            plan: get('selectedPlanHandle'),
            couponCode: finalCouponCode,
            giftCardCode: finalGiftCard,
            addons: get('addOns'),
          }
          if (shippingAddress) {
            data.shippingAddress = shippingAddress
          } else if (get('selectedNewShippingAddress')) {
            data.shippingAddress = get('shippingAddress')
          } else {
            data.shippingAddressId = get('selectedShippingAddressId')
          }
          if (get('selectedPlanHandle') === 'alltruePlus') {
            data.plan = 'annually'

            // recurly does not like duplicate addons
            if (!data.addons.includes('alltrue-plus')) {
              data.addons = [...data.addons, 'alltrue-plus']
            }
          }
          try {
            const subscriptionPreview = await subscriptionsApi.previewSubscription(data)
            set('subscriptionPreview', subscriptionPreview)
            store.set('couponAndGiftCard/markCouponCodeAsValid', finalCouponCode)
            store.set('couponAndGiftCard/markGiftCardAsValid', finalGiftCard)
          } catch (error) {
            const invalidCouponCode = error instanceof ApiError && error.data.errors?.couponCode
            const invalidGiftCard =
              error instanceof ApiError &&
              error.data.errors?.giftCardCode === 'Gift card not found.'
            const redeemedGiftCard =
              error.data.errors?.giftCardCode === 'This gift card has already been redeemed.'
            // If the error wasn't one that we expect, prevent checking out by setting subscriptionPreview to null
            if (!invalidCouponCode && !invalidGiftCard && !redeemedGiftCard) {
              await dispatch('resetSubscriptionPreview')
            }
            return Promise.reject(error)
          }
        },
        async refreshSubscriptionPreview({ dispatch }) {
          if (get('refreshSubscriptionPreviewRequestsActive') > 5) {
            return Promise.reject(new Error('Too many requests.'))
          }
          const requestIndex = get('refreshSubscriptionPreviewRequests')
          set('startRefreshingSubscriptionPreview')
          await waitForValue(() => get('refreshSubscriptionPreviewRequestsProcessed'), requestIndex)
          try {
            await dispatch('previewSubscription')
          } finally {
            set('stopRefreshingSubscriptionPreview')
          }
        },
        async createSubscription() {
          const data = {
            plan: get('selectedPlanHandle'),
            couponCode: store.get('couponAndGiftCard/validSubmittedCouponCode'),
            giftCardCode: store.get('couponAndGiftCard/validSubmittedGiftCard'),
            addons: get('addOns'),
          }
          if (get('selectedNewShippingAddress')) {
            data.shippingAddress = get('shippingAddress')
          } else {
            data.shippingAddressId = get('selectedShippingAddressId')
          }
          if (!get('useExistingBillingInfo')) {
            data.newBillingInfo = {
              token: get('recurlyToken'),
            }
          }
          /* Condensed signup requires account details as part of request body **/
          if (store.get('account/loggedIn')) {
            data.username = store.get('account/username')
          } else {
            data.username = store.get('account/rememberedCredentials')?.username
            data.password = store.get('account/rememberedCredentials')?.password
          }

          if (get('selectedPlanHandle') === 'alltruePlus') {
            data.plan = 'annually'

            // recurly does not like duplicate addons
            if (!data.addons.includes('alltrue-plus')) {
              data.addons = [...data.addons, 'alltrue-plus']
            }
          }

          const response = await subscriptionsApi.createSubscription(data)

          // Log events
          events.setUser({ username: data.username })
          events.log({
            name: 'purchased subscription',
            data: {
              plan: get('selectedPlanHandle'),
            },
          })
          events.subscriptionOrder({
            email: data.username,
            value: response.total, // the response only returns the subtotal, TODO: fix
            order_id: response.reference,
            order_quantity: 1,
            items: [
              {
                name: get('selectedPlanHandle'),
                category: 'Subscriptions',
                price: response.total,
                quantity: 1,
              },
            ],
          })

          set('subscriptionCreated', true)
          store.set('couponAndGiftCard/submittedCouponCode', null)
          store.set('couponAndGiftCard/submittedGiftCard', null)
          // Refetch the user's account info in case it changed (notably the addresses)
          store.set('account/markAsStale')
          store.set('customize/markAsStale')
          await store.dispatch('account/ensureFresh')
          return response
        },
      }),
    }
  )
}
