<script>
  import { get } from 'vuex-pathify'
  import { datadogRum } from '@datadog/browser-rum'
  import { events } from '@/helpers/gtm'
  import AppHeader from '@/components/app/AppHeader'
  import { AREA_SUBSCRIBE, AREA_CUSTOMIZE } from '@/store/general'

  /**
   * The SPA’s root component.
   */
  export default {
    components: {
      AppHeader,
    },
    data() {
      return {
        navigationLoadingVisible: false,
        navigationLoadingTimeout: null,
      }
    },
    computed: {
      ...get('general', ['navigationLoading', 'scrollLocked', 'area']),
      bgColor() {
        // started out as bg-dawn for specific views. can be cleaned up once bg-dawn is used on all views
        if (
          this.$route?.name?.includes('SubscribeTshirt') ||
          this.$route?.name?.includes('AlltruePlusUpgrade') ||
          this.$route?.name === 'AccountAddOn' ||
          (this.area === AREA_SUBSCRIBE && !this.$route.name.includes('SubscribeMarket')) ||
          this.area === AREA_CUSTOMIZE
        ) {
          return 'bg-dawn'
        }
        return 'bg-dawn-lt3'
      },
    },
    watch: {
      navigationLoading: {
        immediate: true,
        handler() {
          // Show the spinner if the navigation has been loading for at least 50ms
          clearTimeout(this.navigationLoadingTimeout)
          if (this.navigationLoading) {
            this.navigationLoadingTimeout = setTimeout(() => {
              this.navigationLoadingVisible = true
            }, 50)
          } else {
            this.navigationLoadingVisible = false
          }
        },
      },
      scrollLocked: {
        immediate: true,
        handler() {
          document.getElementsByTagName('body')[0].style.overflow = this.scrollLocked
            ? 'hidden'
            : 'auto'
        },
      },
    },
    created() {
      // load these stores on page load and keep them fresh
      const stores = ['config', 'feature', 'account', 'products']

      for (const store of stores) {
        this.$store.watch(
          () => this.$store.get(`${store}/stale`),
          () => this.$store.dispatch(`${store}/ensureFresh`),
          { immediate: true }
        )
      }

      // Send the user ID to Datadog
      this.$store.watch(
        () => this.$store.get('account/id'),
        (id) => {
          if (id) {
            datadogRum.addRumGlobalContext('usr', { id: id.toString() })
          }
        },
        { immediate: true }
      )

      // Send a GTM event as soon as we have a user's username/email
      this.$store.watch(
        () => this.$store.get('account/username'),
        (username) => {
          if (username) {
            events.setUser({ username })
          }
        },
        { immediate: true }
      )

      // Clear any user data when the logged in user changes
      this.$store.watch(
        () => this.$store.get('account/username'),
        (_newUsername, oldUsername) => {
          // Do not clear data if there was no previous logged in user
          if (oldUsername !== null) {
            // TODO: loop through all carts and reset them all
            this.$store.set('marketplaceCart/reset')
            this.$store.set('subscribe/reset')
          }
        }
      )
    },
  }
</script>

<template>
  <div class="flex flex-col min-h-screen bg-dawn-lt3" @scroll="$event.target.scrollLeft = 0">
    <AppHeader />
    <main class="flex flex-col flex-grow">
      <BaseTransitionView appear>
        <RouterView :class="`${bgColor} min-h-screen`" />
      </BaseTransitionView>
      <div class="z-40 fixed inset-x-0 bottom-0">
        <PortalTarget name="fixedBottom" />
      </div>
      <PortalTarget name="modal" multiple />
      <PortalTarget name="productModal" transition="BaseTransitionView" />
      <PortalTarget name="searchOverlay" class="search-overlay" />
    </main>
    <transition name="spinner-overlay">
      <BaseSpinner
        v-if="navigationLoadingVisible"
        overlay="fixed"
        overlay-color="white"
        class="z-90"
      />
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
  .spinner-overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-200;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }

  .search-overlay {
    @apply hidden absolute left-0 right-0 bottom-0 z-40 pointer-events-none;

    top: 52px;

    @screen lg {
      @apply block;
    }
  }
</style>
