/* eslint-disable vue/one-component-per-file */
import Vue from 'vue'
import PortalVue from 'portal-vue'
import VueCookies from 'vue-cookies'
import {
  configure as configureVeeValidate,
  setInteractionMode as setVeeValidateInteractionMode,
  extend as extendVeeValidate,
} from 'vee-validate'
import {
  required as requiredRule,
  email as emailRule,
  numeric as numericRule,
  min as minRule,
  max as maxRule,
  length as lengthRule,
  regex as regexRule,
} from 'vee-validate/dist/rules'
import cardValidator from 'card-validator'
import VueLazyload from 'vue-lazyload'
import VueVirtualScroller from 'vue-virtual-scroller'
import InstantSearch from 'vue-instantsearch'
import { ObserveVisibility } from 'vue-observe-visibility'
import { navigate } from '@/router'
import waitForValue from '@/helpers/waitForValue'
import wait from '@/helpers/wait'
import formatPrice from '@/helpers/formatPrice'
import formatDate from '@/helpers/formatDate'
import formatAddress from '@/helpers/formatAddress'
import twToPx from '@/helpers/twToPx'
import pxToRem from '@/helpers/pxToRem'
import twToRem from '@/helpers/twToRem'

export default () => {
  /**
   * Configure Vue itself
   */

  // Prevent the production tip on Vue startup
  Vue.config.productionTip = false

  // Register plugins
  Vue.use(PortalVue)
  Vue.use(VueCookies)
  Vue.use(VueLazyload, { preLoad: 1.5 })
  Vue.use(VueVirtualScroller)
  Vue.use(InstantSearch)

  Vue.directive('observe-visibility', ObserveVisibility)

  /**
   * Define some properties and methods on the Vue prototype to make them available to all components
   */

  // Add a $skipNextTick function to prevent infinite update loops
  Vue.prototype.$skipNextTick = async function (callback) {
    if (this.$skippingNextTick) {
      return
    }
    this.$skippingNextTick = true
    callback()
    await this.$nextTick()
    this.$skippingNextTick = false
  }

  // Add a $waitForValue function that is more or less a promisified $watch()
  Vue.prototype.$waitForValue = async function (watchedExpression, targetValueOrTest) {
    return waitForValue(this.$watch.bind(this), watchedExpression, targetValueOrTest)
  }

  // Expose some helper functions
  Vue.prototype.$navigate = navigate
  Vue.prototype.$wait = wait
  Vue.prototype.$formatPrice = formatPrice
  Vue.prototype.$formatDate = formatDate
  Vue.prototype.$formatAddress = formatAddress
  Vue.prototype.$twToPx = twToPx
  Vue.prototype.$pxToRem = pxToRem
  Vue.prototype.$twToRem = twToRem

  // Expose window mostly for debugging purposes (e.g. `@click="$window.console.log()"`)
  Vue.prototype.$window = window

  /**
   * Configure VeeValidate
   */

  configureVeeValidate({
    useConstraintAttrs: false,
  })

  // Custom interaction mode similar to the built-in `eager` one, with the difference that it checks if the input is dirty before flagging it as invalid on initial touch
  setVeeValidateInteractionMode('eagerDirty', (args) => {
    const { errors, flags } = args
    if (errors.length) {
      return {
        on: ['input', 'change'],
      }
    }
    if (flags.dirty) {
      return {
        on: ['change', 'blur'],
      }
    }
    return {
      on: [],
    }
  })

  /**
   * Register validation rules
   */

  extendVeeValidate('required', {
    ...requiredRule,
    message: 'This field is required.',
  })

  extendVeeValidate('email', {
    ...emailRule,
    message: 'Please enter a valid email address.',
  })

  extendVeeValidate('emailRegex', {
    ...regexRule,
    message: 'Please enter a valid email address.',
  })

  extendVeeValidate('numeric', {
    ...numericRule,
    message: 'Please enter a valid number.',
  })

  extendVeeValidate('min', {
    ...minRule,
    message: 'Please enter at least {length} characters.',
  })

  extendVeeValidate('max', {
    ...maxRule,
    message: 'This field should not exceed {length} characters.',
  })

  extendVeeValidate('length', {
    ...lengthRule,
    message: 'This field should be {length} characters long.',
  })

  extendVeeValidate('regex', {
    ...regexRule,
    message: 'Please enter a value in the correct format.',
  })

  extendVeeValidate('passwordRegex', {
    ...regexRule,
    message:
      'Passwords must be 8 or more characters, with at least one letter, one number, and one special character (@$!%*#?&_)',
  })

  extendVeeValidate('creditCard', {
    validate(value) {
      return cardValidator.number(value).isValid
    },
    message: 'Please enter a valid credit card number.',
  })

  /**
   * Register custom directives
   */

  Vue.directive('ref', {
    inserted(el, binding, node) {
      const propertyName = binding.modifiers.dynamic ? binding.value : binding.expression
      node.context[propertyName] = node.componentInstance ?? node.elm
    },
  })

  Vue.directive('mounted', {
    inserted(el, binding, node) {
      binding.value(node)
    },
  })

  Vue.directive('updated', {
    update(el, binding, node) {
      binding.value(node)
    },
  })

  /**
   * Automatically import all base components and icons
   */

  // true - include subfolders, regex matches absolute paths that end on .vue or .svg
  const contexts = [
    require.context('@/components/base', true, /.+\.vue$/i, 'lazy'),
    require.context('@/components/utility', true, /.+\.vue$/i, 'lazy'),
    require.context('@/images/icons', true, /.+\.svg$/i, 'lazy'),
  ]

  contexts.forEach((context) => {
    context.keys().forEach((componentFilePath) => {
      // last part after slash split, then first part after dot split
      const componentName = componentFilePath.split('/').pop().split('.').shift()
      Vue.component(componentName, () => context(componentFilePath))
    })
  })
}
