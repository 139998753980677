import Prismic from '@prismicio/client'
import createModule from '@/store/createModule'

export default (store) => {
  createModule(
    store,
    'prismic',
    {
      navigationData: null,
      seasonalMarketData: null,
      subscribeFlowData: null,
      shippingTimelines: null,
      orderSummaryData: null,
      customizeLandingPageData: null,
      customizeReviewPageData: null,
      globalElementsData: null,
      homeBannerData: null,
      promoBannerData: null,
    },
    {
      getters: ({ get }) => ({
        getNavigationItemText() {
          return (primaryType) =>
            get('navigationData')?.find((data) => data.primary.type === primaryType)?.primary
              .navigation_item[0].text
        },
        getSubscribePlanCards() {
          return get('subscribeFlowData')?.filter((data) => data.slice_type === 'plan_card')
        },
        getSubscribePlanCardByType() {
          return (type) =>
            get('getSubscribePlanCards')?.find((data) => data.primary.type === type)?.primary
        },
        getShippingTimelineData() {
          return (type) =>
            get('shippingTimelines')?.find((data) => data.primary.type === type)?.primary
        },
        getOrderSummaryData() {
          return (type) =>
            get('orderSummaryData')?.find((data) => data.primary.type === type)?.primary.copy
        },
      }),

      mutations: () => ({
        setNavigationData(state, navigationData) {
          state.navigationData = navigationData
        },
        setSeasonalMarketData(state, seasonalMarketData) {
          state.seasonalMarketData = seasonalMarketData
        },
        setSubscribeFlowData(state, subscribeFlowData) {
          state.subscribeFlowData = subscribeFlowData
        },
        setShippingTimelinesData(state, shippingTimelines) {
          state.shippingTimelines = shippingTimelines
        },
        setOrderSummaryData(state, orderSummaryData) {
          state.orderSummaryData = orderSummaryData
        },
        setCustomizeLandingPageData(state, customizeLandingPageData) {
          state.customizeLandingPageData = customizeLandingPageData
        },
        setCustomizeReviewPageData(state, customizeReviewPageData) {
          state.customizeReviewPageData = customizeReviewPageData
        },
        setGlobalElementsData(state, globalElementsData) {
          state.globalElementsData = globalElementsData
        },
        setHomeBannerData(state, homeBannerData) {
          state.homeBannerData = homeBannerData
        },
        setPromoBannerData(state, promoBannerData) {
          state.promoBannerData = promoBannerData
        },
      }),

      actions: ({ set }) => ({
        async loadNavigationData() {
          const response = await Prismic.client.getByUID('navigation', 'navigation-header')
          set('setNavigationData', response.data.body)
        },
        async loadSeasonalMarketData() {
          const response = await Prismic.client.getByUID('page', 'seasonal-market')
          set('setSeasonalMarketData', response.data.body[0].primary)
        },
        async loadSubscribeFlowData() {
          const response = await Prismic.client.getByUID('subscribe_flow', 'subscribe_flow')
          set('setSubscribeFlowData', response.data.body)
        },
        async loadShippingTimelines() {
          const response = await Prismic.client.getByUID(
            'shipping_timelines_modal',
            'shipping-timelines'
          )
          set('setShippingTimelinesData', response.data.body)
        },
        async loadOrderSummaryData() {
          const response = await Prismic.client.getByUID('order_summary', 'order_summary')
          set('setOrderSummaryData', response.data.body)
        },
        async loadCustomizeLandingPageData() {
          const response = await Prismic.client.getByUID('page', 'customize_landing_page')
          set('setCustomizeLandingPageData', response.data.body[0].primary)
        },
        async loadCustomizeReviewPageData() {
          const response = await Prismic.client.getByUID('page', 'customize-review-page')
          const mappedData = response.data.body.map((body) => body.primary)
          set('setCustomizeReviewPageData', mappedData)
        },
        async loadGlobalElementsData() {
          const response = await Prismic.client.getByUID('global_elements', 'global-elements')
          set('setGlobalElementsData', response.data)
        },
        async loadHomeBannerData() {
          const response = await Prismic.client.getByUID('home_banner', 'home_banner')
          set('setHomeBannerData', response.data)
        },
        async loadPromoBannerData() {
          const response = await Prismic.client.getByUID('promo_header_banner', 'promo_banner')
          set('setPromoBannerData', response.data)
        },
      }),
    }
  )
}
