import createModule from '@/store/createModule'

export default (store) => {
  createModule(
    store,
    'achievements',
    {
      achievementCategories: {
        // category => achievement slug (lowercase + dashes) => { icon, label, criteria, description, images }
        upgrades: {
          'eco-champion': {
            icon: 'IconTree',
            label: 'Eco Champion',
            criteria:
              'Earn this achievement when you add an eco-friendly upgrade to your membership!',
            description:
              'For only $1 each season your box shipment will effectively be made carbon neutral. We’ve partnered with Grow Ahead, a 501(c)(3) non-profit organization that supports small-scale farmers using agroforestry to combat climate change and support local communities. Your $1 will be passed on as a direct donation to Grow Ahead to distribute, manage, monitor, and evaluate tree planting in farm communities worldwide. Help us build a greener, brighter future together and chip in to fight climate change!',
            images: [
              'https://shop.alltrue.com/cdn-cgi/image/width%3D128%2Cquality%3D85%2Cformat%3Dauto%2Cheight%3D128%2Cfit%3Dcover/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fcausebox%2Fuploads%2Fbala-bala-bangles-1-lb-deep-blue-01.jpg',
              'https://shop.alltrue.com/cdn-cgi/image/width%3D128%2Cquality%3D85%2Cformat%3Dauto%2Cheight%3D128%2Cfit%3Dcover/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fcausebox%2Fuploads%2Fbala-bala-bangles-1-lb-deep-blue-02.jpg',
              'https://shop.alltrue.com/cdn-cgi/image/width%3D128%2Cquality%3D85%2Cformat%3Dauto%2Cheight%3D128%2Cfit%3Dcover/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fcausebox%2Fuploads%2Fbala-bala-bangles-1-lb-deep-blue-03.jpg',
              'https://shop.alltrue.com/cdn-cgi/image/width%3D128%2Cquality%3D85%2Cformat%3Dauto%2Cheight%3D128%2Cfit%3Dcover/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fcausebox%2Fuploads%2Fbala-bala-bangles-1-lb-deep-blue-04.jpg',
              'https://shop.alltrue.com/cdn-cgi/image/width%3D128%2Cquality%3D85%2Cformat%3Dauto%2Cheight%3D128%2Cfit%3Dcover/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fcausebox%2Fuploads%2Fbala-bala-bangles-1-lb-deep-blue-05.jpg',
            ],
          },
          'premium-content': {
            icon: 'IconBook',
            label: 'Premium Magazine',
            criteria:
              'Earn this achievement when you add our premium magazine & workbook, All Good Things, to your membership!',
            description:
              'For $8 each season, you will receive the premium edition of All Good Things magazine! With twice the amount of content — you’ll get access to more DIY projects, inspiring stories, photojournalism, and actionable ways to make a difference. This is the magazine for intentional living! Made to look stunning on your coffee table, this magazine is printed on luxe uncoated paper with heavy duty embossed cover.',
            images: [],
          },
          'community-member': {
            icon: 'IconFlag',
            label: 'Community Member',
            criteria: '',
            description: '',
            images: [],
          },
          'vip-member': {
            icon: 'IconTree',
            label: 'VIP Member',
            criteria: '',
            description: '',
            images: [],
          },
        },
        anniversaries: {
          '1-year-anniversary': {
            icon: 'IconTree',
            label: '1 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
          '2-year-anniversary': {
            icon: 'IconTree',
            label: '2 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
          '3-year-anniversary': {
            icon: 'IconTree',
            label: '3 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
          '4-year-anniversary': {
            icon: 'IconTree',
            label: '4 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
          '5-year-anniversary': {
            icon: 'IconTree',
            label: '5 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
          '6-year-anniversary': {
            icon: 'IconTree',
            label: '6 Year Anniversary',
            criteria: '',
            description: '',
            images: [],
          },
        },
        'coming-soon': {
          'art-collector': {
            icon: 'IconTree',
            label: 'Art Collector',
            criteria: '',
            description: '',
            images: [],
          },
          'gift-giver': {
            icon: 'IconTree',
            label: 'Gift Giver',
            criteria: '',
            description: '',
            images: [],
          },
          'community-builder': {
            icon: 'IconTree',
            label: 'Community Builder',
            criteria: '',
            description: '',
            images: [],
          },
          'market-maven': {
            icon: 'IconTree',
            label: 'Market Maven',
            criteria: '',
            description: '',
            images: [],
          },
          'essentials-explorer': {
            icon: 'IconTree',
            label: 'Essentials Explorer',
            criteria: '',
            description: '',
            images: [],
          },
          'social-member': {
            icon: 'IconTree',
            label: 'Social Member',
            criteria: '',
            description: '',
            images: [],
          },
        },
      },
    },
    {
      getters: ({ get }) => ({
        getAchievements() {
          return (category = null) => {
            const categories = get('achievementCategories')
            const flags = store.get('account/achievements') ?? []
            const achievements = {}

            // make a flat hash of achievements from a single or all categories
            if (category) {
              for (const achievement in categories[category]) {
                achievements[achievement] = {
                  ...categories[category][achievement],
                  slug: achievement, // each achievement should know its own slug
                  icon:
                    categories[category][achievement].icon +
                    (flags.includes(achievement) ? 'On' : 'Off'), // append each icon with an appropriate state
                }
              }
            } else {
              for (const category in categories) {
                for (const achievement in categories[category]) {
                  achievements[achievement] = {
                    ...categories[category][achievement],
                    slug: achievement, // each achievement should know its own slug
                    icon:
                      categories[category][achievement].icon +
                      (flags.includes(achievement) ? 'On' : 'Off'), // append each icon with an appropriate state
                  }
                }
              }
            }

            return achievements
          }
        },
        getAchievement() {
          return (slug) => {
            return get('getAchievements')[slug] // get all, then pick one
          }
        },
        getAchievementsForCategory() {
          return (category) => {
            return get('getAchievements', category) // get all from a single category
          }
        },
      }),
    }
  )
}
