import api, { METHOD_GET } from '@/api'

export default api.module('config', {
  getConfig: {
    method: METHOD_GET,
    path: '',
  },
  getBoxArchive: {
    method: METHOD_GET,
    path: 'box-archive',
  },
})
