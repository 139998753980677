import api, { METHOD_GET, METHOD_POST, METHOD_DELETE } from '@/api'

export default api.module('store', {
  // type = marketplace | upsell
  init: {
    method: METHOD_GET,
    path: (type) => `${type}/init`,
  },
  products: {
    method: METHOD_GET,
    path: (type) => `${type}/products`,
  },
  inventory: {
    method: METHOD_GET,
    path: (type) => `${type}/inventory`,
  },
  placeOrder: {
    method: METHOD_POST,
    path: (type) => `${type}/place-order`,
  },
  reviewOrder: {
    method: METHOD_POST,
    path: (type) => `${type}/review-order`,
  },
  addFavorite: {
    method: METHOD_POST,
    path: (productId) => `favorites/${productId}`,
  },
  removeFavorite: {
    method: METHOD_DELETE,
    path: (productId) => `favorites/${productId}`,
  },
})
