import createModule from '@/store/createModule'
import { ApiError } from '@/api'

export default (store) => {
  createModule(
    store,
    'couponAndGiftCard',
    {
      submittedCouponCode: null,
      validCouponCodes: [],
      invalidCouponCodes: [],
      submittedGiftCard: null,
      validGiftCards: [],
      invalidGiftCards: [],
      redeemedGiftCards: [],
      submittedCouponCodeIsBeingValidated: false,
    },
    {
      getters: ({ get }) => ({
        submittedCouponCodeIsValid() {
          const couponCode = get('submittedCouponCode')
          return couponCode ? get('validCouponCodes').includes(couponCode) : false
        },
        submittedCouponCodeIsInvalid() {
          const couponCode = get('submittedCouponCode')
          return couponCode ? get('invalidCouponCodes').includes(couponCode) : false
        },
        submittedCouponCodeWasValidated() {
          return get('submittedCouponCodeIsValid') || get('submittedCouponCodeIsInvalid')
        },
        submittedGiftCardIsValid() {
          const giftCard = get('submittedGiftCard')
          return giftCard ? get('validGiftCards').includes(giftCard) : false
        },
        submittedGiftCardIsInvalid() {
          const giftCard = get('submittedGiftCard')
          return giftCard ? get('invalidGiftCards').includes(giftCard) : false
        },
        submittedGiftCardWasRedeemed() {
          const giftCard = get('submittedGiftCard')
          return giftCard ? get('redeemedGiftCards').includes(giftCard) : false
        },
        submittedGiftCardWasValidated() {
          return (
            get('submittedGiftCardIsValid') ||
            get('submittedGiftCardIsInvalid') ||
            get('submittedGiftCardWasRedeemed')
          )
        },
        submittedGiftCardIsBeingValidated() {
          return Boolean(get('submittedGiftCard') && !get('submittedGiftCardWasValidated'))
        },
        validSubmittedCouponCode() {
          return get('submittedCouponCodeIsValid') ? get('submittedCouponCode') : null
        },
        validSubmittedGiftCard() {
          return get('submittedGiftCardIsValid') ? get('submittedGiftCard') : null
        },
      }),

      mutations: ({ defaultState }) => ({
        markCouponCodeAsValid(state, couponCode) {
          state.validCouponCodes.push(couponCode)
        },
        markCouponCodeAsInvalid(state, couponCode) {
          state.invalidCouponCodes.push(couponCode)
        },
        markGiftCardAsValid(state, giftCard) {
          state.validGiftCards.push(giftCard)
        },
        markGiftCardAsInvalid(state, giftCard) {
          state.invalidGiftCards.push(giftCard)
        },
        markGiftCardAsDuplicate(state, giftCard) {
          state.redeemedGiftCards.push(giftCard)
        },
      }),

      actions: ({ get, set }) => ({
        async submitCouponCode({ dispatch }, { couponCode, area }) {
          const previousCouponCodeWasPotentiallyValid = Boolean(
            get('submittedCouponCode') &&
              (get('submittedCouponCodeIsValid') || !get('submittedCouponCodeWasValidated'))
          )
          set('submittedCouponCode', couponCode || null)
          if (!couponCode) {
            if (previousCouponCodeWasPotentiallyValid) {
              if (area === 'subscribe') {
                return store.dispatch('subscribe/refreshSubscriptionPreview')
              } else {
                return store.dispatch('marketplaceCart/reviewOrder')
              }
            }
            return
          }
          let justValidated = false
          if (!get('submittedCouponCodeWasValidated')) {
            await dispatch('validateCouponCode', {
              area,
            })
            justValidated = true
          }
          const couponCodeIsValid = get('submittedCouponCodeIsValid')
          if (
            (couponCodeIsValid && !justValidated) ||
            (!couponCodeIsValid && previousCouponCodeWasPotentiallyValid)
          ) {
            if (area === 'subscribe') {
              return store.dispatch('subscribe/refreshSubscriptionPreview')
            } else {
              return store.dispatch('marketplaceCart/reviewOrder')
            }
          }
        },
        async validateCouponCode({ dispatch }, { area }) {
          const couponCode = get('submittedCouponCode')
          try {
            set('submittedCouponCodeIsBeingValidated', true)
            if (area === 'subscribe') {
              await store.dispatch('subscribe/previewSubscription', {
                couponCode,
              })
            } else {
              await store.dispatch('marketplaceCart/reviewOrder', {
                type: 'marketplace',
                couponCode,
              })
            }
            return true
          } catch (error) {
            if (error instanceof ApiError && error.data.errors?.couponCode) {
              set('markCouponCodeAsInvalid', couponCode)
              return false
            }
            return Promise.reject(error)
          } finally {
            set('submittedCouponCodeIsBeingValidated', false)
          }
        },
        async submitGiftCard({ dispatch }, { giftCard, area }) {
          const previousGiftCardWasPotentiallyValid = Boolean(
            get('submittedGiftCard') &&
              (get('submittedGiftCardIsValid') || !get('submittedGiftCardWasValidated'))
          )
          set('submittedGiftCard', giftCard || null)
          if (!giftCard) {
            if (previousGiftCardWasPotentiallyValid) {
              if (area === 'subscribe') {
                return store.dispatch('subscribe/refreshSubscriptionPreview')
              } else {
                return store.dispatch('marketplaceCart/reviewOrder')
              }
            }
            return
          }
          let justValidated = false
          if (!get('submittedGiftCardWasValidated')) {
            await dispatch('validateGiftCard', {
              area,
            })
            justValidated = true
          }
          const giftCardIsValid = get('submittedGiftCardIsValid')
          if (
            (giftCardIsValid && !justValidated) ||
            (!giftCardIsValid && previousGiftCardWasPotentiallyValid)
          ) {
            if (area === 'subscribe') {
              return store.dispatch('subscribe/refreshSubscriptionPreview')
            } else {
              return store.dispatch('marketplaceCart/reviewOrder')
            }
          }
        },
        async validateGiftCard({ dispatch }, { area }) {
          const giftCard = get('submittedGiftCard')
          try {
            if (area === 'subscribe') {
              await store.dispatch('subscribe/previewSubscription', {
                giftCard,
              })
            } else {
              await store.dispatch('marketplaceCart/reviewOrder', {
                type: 'marketplace',
                giftCard,
              })
            }
            return true
          } catch (error) {
            if (
              error instanceof ApiError &&
              error.data.errors?.giftCardCode === 'Gift card not found.'
            ) {
              set('markGiftCardAsInvalid', giftCard)
              return false
            } else if (
              error instanceof ApiError &&
              error.data.errors?.giftCardCode === 'This gift card has already been redeemed.'
            ) {
              set('markGiftCardAsDuplicate', giftCard)
              return false
            }
            return Promise.reject(error)
          }
        },
      }),
    }
  )
}
