import Vue from 'vue'
import Vuex from 'vuex'
import merge from 'deepmerge'
import createPersistedState from '@/store/createPersistedState'
import env from '@/helpers/env'
import pathify from '@/store/pathify'
import registerAccountModule from '@/store/account'
import registerAchievementsModule from '@/store/achievements'
import registerMarketplaceCartModule from '@/store/marketplaceCart'
import registerChoicePlusCartModule from '@/store/choicePlusCart'
import registerEssentialsCartModule from '@/store/essentialsCart'
import registerConfigModule from '@/store/config'
import registerCouponAndGiftCardModule from '@/store/couponAndGiftCard'
import registerCustomizeModule from '@/store/customize'
import registerFeatureModule from '@/store/feature'
import registerGeneralModule from '@/store/general'
import registerMarketplaceModule from '@/store/marketplace'
import registerProductsModule from '@/store/products'
import registerSubscribeModule from '@/store/subscribe'
import registerEssentialsModule from '@/store/essentials'
import registerUpsellModule from '@/store/upsell'
import registerPrismicModule from '@/store/prismic'

// Check whether there exists persisted state prior to setting up vuex and using
// the vuex-persistedstate plugin
const hasPersistedSessionState = Boolean(window.sessionStorage.getItem('vuex'))
const hasPersistedLocalState = Boolean(window.localStorage.getItem('vuex'))

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: env.isDev(),

  mutations: {
    all(state, newState) {
      this.replaceState(
        merge(state, newState, {
          // Overwrite arrays instead of concatenating them
          arrayMerge: (_destinationArray, sourceArray) => sourceArray,
        })
      )
    },
  },
})

pathify.plugin(store)

registerAccountModule(store)
registerAchievementsModule(store)
registerMarketplaceCartModule(store)
registerChoicePlusCartModule(store)
registerEssentialsCartModule(store)
registerConfigModule(store)
registerCouponAndGiftCardModule(store)
registerCustomizeModule(store)
registerFeatureModule(store)
registerGeneralModule(store)
registerMarketplaceModule(store)
registerProductsModule(store)
registerSubscribeModule(store)
registerEssentialsModule(store)
registerUpsellModule(store)
registerPrismicModule(store)

// preserve vuex state between page loads
// localStorage does not expire
createPersistedState(
  store,
  window.localStorage,
  ['config', 'feature', 'account', 'marketplaceCart', 'essentialsCart'],
  {
    marketplaceCart: ['cartPreview', 'calculatingLineItems', 'placingOrder'],
    essentialsCart: ['cartPreview', 'calculatingLineItems', 'placingOrder'],
    account: ['rememberedCredentials'],
  }
)

// sessionStorage is cleared when session ends
createPersistedState(
  store,
  window.sessionStorage,
  ['customize', 'choicePlusCart', 'subscribe', 'couponAndGiftCard', 'upsell'],
  {
    customize: ['mockRoute'],
    subscribe: [
      'subscriptionPreview',
      'refreshSubscriptionPreviewRequests',
      'refreshSubscriptionPreviewRequestsProcessed',
      'ecoUpgrade',
    ],
    upsell: ['cartPreview', 'placingOrder', 'calculatingLineItems'],
    couponAndGiftCard: ['validCouponCodes', 'invalidCouponCodes'],
  }
)

// Only reset the persisted state in environments other than the test
// environment.
if (!env.get('CI')) {
  // If the SPA version doesn't match the version in localStorage, reset
  // the state to prevent any mismatches between data and logic.
  const persistedSpaVersion = store.get('config/spaVersion')
  const currentSpaVersion = env.get('SPA_VERSION')
  if (persistedSpaVersion !== currentSpaVersion) {
    if (hasPersistedSessionState || hasPersistedLocalState) {
      store.set('config/reset')
      store.set('feature/reset')
      store.set('account/reset')
      store.set('products/reset')
      store.set('subscribe/reset')
      store.set('couponAndGiftCard/reset')
      store.set('marketplaceCart/reset')
    }

    // Update the SPA version on the client to the latest build.
    store.set('config/spaVersion', currentSpaVersion)
  }
}

// Sync localStorage changes made from another tab, if any
window.addEventListener('storage', (event) => {
  // Only listen to changes to the `vuex` key
  if (event.key !== 'vuex' || event.storageArea !== window.localStorage) {
    return
  }
  // Work around Safari bug where the `storage` event is fired in the same tab
  // https://bugs.webkit.org/show_bug.cgi?id=210512
  if (document.hasFocus()) {
    return
  }
  store.set('all', JSON.parse(window.localStorage.getItem('vuex')))
})

export default store
