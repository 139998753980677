/**
 * Formats an address for display, either on multiple lines or on one line (if `inline` is `true`). The returned value may contain HTML.
 */

export default (address, inline = false) => {
  let addressString = ''
  const addPart = (part, addBreak = false) => {
    if (!part) {
      return
    }
    addressString +=
      (addressString !== '' ? (addBreak ? (inline ? ', ' : '<br>') : ' ') : '') + part
  }
  addPart(address.firstName)
  addPart(address.lastName)
  addPart(address.address1, true)
  addPart(address.address2, true)
  addPart(address.city + ', ' + address.stateAbbreviation + '  ' + address.zipCode, true)
  addPart(address.country, true)
  return addressString
}
