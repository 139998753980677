export const ENVIRONMENT_DEV = 'dev' // TODO: dev is not real, local is, but we should not be using either
export const ENVIRONMENT_STAGING = 'staging'
export const ENVIRONMENT_PRODUCTION = 'production'

const getCurrentEnvironment = () => {
  if (process.env.NODE_ENV !== 'production') {
    return ENVIRONMENT_DEV
  }
  if (window.location.hostname === 'staging.alltrue.com') {
    return ENVIRONMENT_STAGING
  }
  return ENVIRONMENT_PRODUCTION
}

const env = {
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT ?? getCurrentEnvironment(),
  CI: process.env.VUE_APP_CI === 'true',
  API_URL: process.env.VUE_APP_API_URL ?? '/api',
  NODE_ENV: process.env.NODE_ENV,
  BASE_URL: process.env.BASE_URL,
  SPA_VERSION: process.env.VUE_APP_SPA_VERSION_HASH,
  ALGOLIA_APP_ID: process.env.VUE_APP_ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY: process.env.VUE_APP_ALGOLIA_SEARCH_KEY,
  ALGOLIA_INDEX: process.env.VUE_APP_ALGOLIA_INDEX,
  PRISMIC_ACCESS_TOKEN: process.env.VUE_APP_PRISMIC_ACCESS_TOKEN,
}

export default {
  get(key) {
    return env[key]
  },
  isDev() {
    // TODO: deprecate this
    return this.get('ENVIRONMENT') === ENVIRONMENT_DEV
  },
  isProduction() {
    return this.get('ENVIRONMENT') === ENVIRONMENT_PRODUCTION
  },
  isStaging() {
    return this.get('ENVIRONMENT') === ENVIRONMENT_STAGING
  },
}
