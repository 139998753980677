import api, { METHOD_GET, METHOD_POST, METHOD_PUT } from '@/api'

export default api.module('subscriptions', {
  previewSubscription: {
    method: METHOD_POST,
    path: 'preview',
  },
  createSubscription: {
    method: METHOD_POST,
    path: '',
  },
  createNewSubscription: {
    method: METHOD_POST,
    path: 'subscribe',
  },
  unsubscribe: {
    method: METHOD_POST,
    path: 'unsubscribe',
  },
  applyOffer: {
    method: METHOD_POST,
    path: 'apply-offer',
  },
  reactivateSubscription: {
    method: METHOD_POST,
    path: 'reactivate',
  },
  // TODO: alltrue+
  upgradeQuarterlyToYearly: {
    method: METHOD_POST,
    path: 'upgrade',
  },
  getSubscriptionCustomization: {
    method: METHOD_GET,
    path: ({ subscriptionId }) => `${subscriptionId}/customization`,
  },
  updateSubscriptionCustomization: {
    method: METHOD_PUT,
    path: ({ subscriptionId }) => `${subscriptionId}/customization`,
  },
  fetchSubscriptions: {
    method: METHOD_GET,
    path: 'all',
  },
  updateAddress: {
    method: METHOD_PUT,
    path: 'update',
  },
  addEcoUpgrade: {
    method: METHOD_PUT,
    path: ({ subscriptionId }) => `${subscriptionId}/addons/eco-upgrade`,
  },
  addMagazine: {
    method: METHOD_PUT,
    path: ({ subscriptionId }) => `${subscriptionId}/addons/magazine`,
  },
  getPurchasedChoicePlusVariants: {
    method: METHOD_GET,
    path: ({ subscriptionReference }) => `${subscriptionReference}/purchased-choice-plus-variants`,
  },
})
