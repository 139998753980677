import createCartModule from '@/store/createCartModule'
import keyBy from '@/helpers/keyBy'

export default (store) => {
  createCartModule(
    store,
    'choicePlusCart',
    {
      storeType: 'choicePlus',
      maxQuantity: 5,
    },
    {
      getters: ({ get }) => ({
        productsById() {
          return keyBy(store.get('customize/selectedBox')?.products, ({ id }) => id)
        },
        lineItems(state) {
          const variantsById = store.get('config/variantsById') // box products, not marketplace

          // address it differently to avoid infinite recursion
          return state.lineItems.map((lineItem) => {
            return { ...lineItem, variant: variantsById[lineItem.variantId] }
          })
        },
        apiPayload() {
          let shippingAddressId = store.get('customize/selectedSubscription')?.address.id
          if (!shippingAddressId) {
            shippingAddressId = store.get('account/defaultAddressId')
          }

          return {
            lineItems: get('finalLineItems').map(({ variantId, quantity }) => ({
              variantId,
              quantity,
            })),
            shippingAddressId: shippingAddressId,
          }
        },
        retailTotal() {
          return get('finalLineItems').reduce(
            (subtotal, { variant, quantity }) => subtotal + variant.msrp * quantity,
            0
          )
        },
      }),
      actions: ({ get, set }) => ({
        afterSuccessfulReviewOrder({ dispatch }) {
          store.dispatch('customize/refreshBoxCarts')
          dispatch('adjustQtys')
        },
      }),
    }
  )
}
